import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAlertsFilter,
    changeFilters,
    selectAlertsFilter,
    selectFilters,
} from 'src/state/captures/capturesSlice';
import {
    FormControl,
    TextField,
} from '@mui/material';
import { ALERTSPAGE, SCREENSHOTMETAPAGE } from 'src/models/alertsType.enum';
import { useTranslation } from 'react-i18next';
import debounceSearch from 'src/utils/common';
import { DEBOUNCE_DELAY_TIME } from 'src/utils/environment';
export function DataTypeSearchFilter(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const alertsfilter = useSelector(selectAlertsFilter);
    const filters = useSelector(selectFilters);

    const searchValue = window.location.pathname === ALERTSPAGE ? alertsfilter?.searchValue : filters?.searchValue;
    const [inputValue, setInputValue] = useState(searchValue);

    const debouncedOnChangeFilter = useCallback(
        debounceSearch((value: string) =>
            {
            if (window.location.pathname === ALERTSPAGE)
            {
                dispatch(changeAlertsFilter({ searchValue: value }));
            }
            else
            {
                dispatch(changeFilters({ searchValue: value }));
            }
        }, DEBOUNCE_DELAY_TIME),
        [dispatch]
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setInputValue(value);
        debouncedOnChangeFilter(value);
    };

    useEffect(()=> {
        setInputValue(searchValue);
    }, [searchValue])

    return (
        <FormControl fullWidth>
            <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                disabled={window.location.pathname === SCREENSHOTMETAPAGE}
                autoComplete="off"
                value={inputValue}
                onChange={handleChange}
            />
        </FormControl>
    );
}
