import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getPlatformVersion,
    selectAdminFilters,
    getUserAgents,
    selectPlatformVersions,
    getAgencyCompareOptions,
    selectCompareVersion,
    selectPlatformVersionsStatus,
    resetPlatformVersion,
} from 'src/state/administration/administrationSlice';
import {
    getAgencyFilterOptions,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import {
    AgencyPlatformVersion,
} from 'src/models/administration/agencyVersionData.model';
import { Box, Button, CircularProgress } from '@mui/material';
import { RequestStatus } from 'src/models/requestStatus.enum';

export function AgencyVersionUpdateForm(props: Readonly<AgencyPlatformVersion>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const platformVersions = useSelector(selectPlatformVersions);
    const compareVersion = useSelector(selectCompareVersion);
    const versionStatus = useSelector(selectPlatformVersionsStatus)
    // eslint-disable-next-line
    const [currentChecked, setCurrentChecked] = useState<any>([]);
    // eslint-disable-next-line
    const filters = useSelector(selectAdminFilters);

    useEffect(() =>
    {
        if (!compareVersion)
        {
            dispatch(getPlatformVersion({
                pageNumber: 1,
                entriesPerPage: 10,
            }));
        }

        if (!compareVersion)
        {
            dispatch(getAgencyFilterOptions());
        }

        dispatch(getAgencyCompareOptions({
            platformId: props.id,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    function onPageChange(pageNumber: number): void
    {
        dispatch(getUserAgents({ pageNumber, entriesPerPage, ...filters }));
    }

    useEffect(() =>
        {
            if (!compareVersion?.currentVersions) return;
        
            const selectedCheckboxes = platformVersions?.contents.filter((item) =>
                compareVersion?.currentVersions?.some(entry => entry.platform === item.platform)
            ) ?? [];
        
            const updatedCurrentChecked = compareVersion.currentVersions.map((entry) =>
            {
                if (selectedCheckboxes.some(checkbox => checkbox.platform === entry.platform))
                {
                    return { ...entry, isChecked: !entry.isChecked };
                }
                return entry;
            });
        
            setCurrentChecked(updatedCurrentChecked);
        
        }, [compareVersion, platformVersions]);


    const getCurrentPlatform = (data: AgencyPlatformVersion): React.ReactNode =>
    {
        const filterData = platformVersions?.contents.filter(
            (item: { platform?: string }) => item.platform === data.platform
        );
        if (!filterData || filterData.length === 0)
        {
            return (
                <div style={{ marginLeft: '10px' }}>
                    {data.platform}
                </div>
            );
        }
        const displayText = filterData.map((platform) => (
            <div key={platform.id}>
                <p>
                    {platform.platform}
                </p>
            </div>
        ));
        return (
            <div>
                {displayText}
            </div>
        );
    };
    const getCurrentVersion = (data: AgencyPlatformVersion): React.ReactNode =>
    {

        const filterData = currentChecked?.filter(
            (item: { platform: string }) => item.platform === data.platform
        );
        if (!filterData || filterData.length === 0)
        {
            return (
                <div style={{ marginLeft: '10px' }}>
                    -
                </div>
            );
        }
        const displayText =
        filterData &&
        `${filterData[0]?.version} ${
            filterData[0]?.downgrade ? '(Downgrade)' : ''
        }`;
        return (
            <div>
                {displayText}
            </div>
        );
    };


    const columns = [
        {
            label: t('common.nouns.platform'),
            size: 1,
            value: (data: AgencyPlatformVersion) => getCurrentPlatform(data),
        },
        {
            label: t('data-type.table.version'),
            size: 1,
            value: (data: AgencyPlatformVersion) => getCurrentVersion(data),
        },
    ];
    return (
        <>
        {versionStatus === RequestStatus.InProgress ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                <CircularProgress />
            </Box>
        ) : (
            <DataTable<AgencyPlatformVersion>
                columns={columns}
                pagination={false}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...(platformVersions as unknown as AgencyPlatformVersion[])}
            />
        )}
            <Box
                component="form"
            >
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button
                        sx={{
                            mr: 1,
                            backgroundColor: 'transparent',
                            color: theme => theme.palette.common.goldYellow,
                            '&.Mui-disabled': {
                                backgroundColor: 'inherit',
                                color: theme => theme.palette.common.goldYellow,
                            },
                        }}
                        onClick={() =>
                        {
                            dispatch(resetPlatformVersion())
                            props.closeModal(false);
                        }}
                    >
                        {t('form.cancel')}
                    </Button>
                </Box>
            </Box>
        </>
    );
}
