import { configInstance } from '../instance';
import { getToken } from './configuration';
import {
  BlockedConfiguration,
  BlockedConfigurationParams,
  BlockedListPaginatedParams,
  ConfigScheduleParams,
  transformBlockedConfigurationParams
} from 'src/models/administration/blockConfigurationData.model';
import {
  readAPIBlockedConfigurationResponse,
  readAPIConfigScheduleResponse,
  transformBlockedListPaginatedParams
} from '../data-transfer-objects/administration/blockConfiguration';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import { PaginatedContent } from 'src/models/paginatedResult.model';

export async function getConfigurationSchedule(params: ConfigScheduleParams): Promise<BlockedConfiguration> {
  const headers = getToken();
  const endpoint = `monitoring-configuration/block-configuration/hours?type=${params.type}`;
  const response = await configInstance.get(endpoint, { headers });
  return readAPIConfigScheduleResponse(response.data);
}

export async function getBlockedConfigurations(params: BlockedListPaginatedParams):
  Promise<PaginatedContent<BlockedConfiguration>> {
  const headers = getToken();
  const endpoint = `monitoring-configuration/block-configuration/list`;
  const response = await configInstance.get(endpoint, {
    params: transformBlockedListPaginatedParams(params), headers
  });
  return readAPIPaginatedResponse(response.data, readAPIBlockedConfigurationResponse);
}

export async function manageBlockConfiguration(
  params: BlockedConfigurationParams
): Promise<BlockedConfiguration[]> {
  const headers = getToken();
  const response = await configInstance.post(
    `monitoring-configuration/block-configuration/${params.actionId ?? null}`,
    params.configurations.map((configuration) =>
      transformBlockedConfigurationParams(configuration)),
    { headers }
  );
  return response.data;
}
