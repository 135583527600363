import React, { useEffect, useState } from "react";
import {
    Alert,
    AlertProps,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Snackbar,
    SnackbarProps,
    TextField,
    Typography,
} from "@mui/material";
import { Add, Cancel } from "@mui/icons-material";
import {
    daysOfWeek,
    DefaultTimings,
    WeekDays
} from "src/models/blockManagement.enum";
import { chipStyle, dropdownStyle } from "src/templates/block-apps-management/BlockAppsManagement";
import { BlockedConfiguration, DaySchedule } from "src/models/administration/blockConfigurationData.model";
import { useTranslation } from "react-i18next";

interface Timings {
    start: string;
    end: string
}

interface DayConfiguration {
    alwaysBlocked: boolean;
    disabled: boolean;
    timings: Timings[];
}

export const block24HrsTiming = {
    start: DefaultTimings.start,
    end: DefaultTimings.end
}
export const blockDisabledTiming = {
    start: DefaultTimings.start,
    end: DefaultTimings.start
}

export interface DayTimeSchedulerProps {
    blockedItems: BlockedConfiguration[];
    onClose?: () => void;
    onSave: (scheduler: DaySchedule[]) => void;
    defaultTimings: DaySchedule[];
    dayToEdit?: DaySchedule;
}

export function DayTimeScheduler({
    defaultTimings,
    dayToEdit,
    blockedItems,
    onClose,
    onSave
}: Readonly<DayTimeSchedulerProps>): React.ReactElement {

    const { t } = useTranslation();
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [dayConfigs, setDayConfigs] = useState<Record<string, DayConfiguration>>({});
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();

    const checkIfBlocked = (interval: Timings[] = []): boolean =>
        interval.some(data => data.start === DefaultTimings.start && data.end === DefaultTimings.end);

    const checkIsDisabled = (interval: Timings[] = []): boolean =>
        interval.some(data => data.start === DefaultTimings.start && data.end === DefaultTimings.start);

    const formatTimings = (interval: Timings[] = []): Timings[] =>
        interval.map(({ start, end }) => ({ start, end }));


    useEffect(() => {
        if (dayToEdit) {
            const { day, interval } = dayToEdit;
            setSelectedDays([day]);
            setDayConfigs((prevConfigs) => {
                const updatedConfigs = {
                    ...prevConfigs,
                    [day]: {
                        alwaysBlocked: checkIfBlocked(interval),
                        disabled: checkIsDisabled(interval),
                        timings: formatTimings(interval),
                    }
                };

                defaultTimings.forEach(({ day: defaultDay, interval }) => {
                    if (defaultDay !== day) {
                        updatedConfigs[defaultDay] = {
                            alwaysBlocked: checkIfBlocked(interval),
                            disabled: checkIsDisabled(interval),
                            timings: formatTimings(interval),
                        };
                    }
                });

                return updatedConfigs;
            });
        } else {
            const allDays = defaultTimings.map(data => data.day)
            setSelectedDays(allDays);
            const firstDayIntervals = defaultTimings[0].interval;
            setDayConfigs((prevConfigs) =>
                allDays.reduce((acc, day) => {
                    return {
                        ...acc,
                        [day]: {
                            alwaysBlocked: checkIfBlocked(firstDayIntervals),
                            disabled: checkIsDisabled(firstDayIntervals),
                            timings: formatTimings(firstDayIntervals),
                        },
                    };
                }, prevConfigs)
            );
        }
    }, []);

    const updateDayConfig = (day: string): void => {
        setDayConfigs((prevConfigs) => {
            const updatedConfigs = { ...prevConfigs };
            const defaultTiming = defaultTimings.find(data => data.day === day)?.interval || [{ start: "", end: "" }];

            updatedConfigs[day] = {
                alwaysBlocked: checkIfBlocked(defaultTiming),
                disabled: checkIsDisabled(defaultTiming),
                timings: formatTimings(defaultTiming),
            };

            return updatedConfigs;
        });
    };

    const toggleDaySelection = (day: string): void => {
        setSelectedDays((prevSelectedDays) => {
            if (prevSelectedDays.includes(day)) {
                updateDayConfig(day);
                return prevSelectedDays.filter((selectedDay) => selectedDay !== day);
            } else {
                const firstSelectedDay = prevSelectedDays[0];
                setDayConfigs((prevConfigs) => {
                    const updatedConfigs = { ...prevConfigs };

                    if (firstSelectedDay && prevConfigs[firstSelectedDay]) {
                        updatedConfigs[day] = {
                            alwaysBlocked: prevConfigs[firstSelectedDay].alwaysBlocked,
                            disabled: prevConfigs[firstSelectedDay].disabled,
                            timings: [...prevConfigs[firstSelectedDay].timings],
                        };
                    }
                    return updatedConfigs;
                });
                return [...prevSelectedDays, day];
            }
        });
    };


    const handleToggle24Hours = (isChecked: boolean): void => {
        const updatedConfigs = { ...dayConfigs };
        selectedDays.forEach((day) => {
            updatedConfigs[day].alwaysBlocked = isChecked;
            if (isChecked) {
                updatedConfigs[day].disabled = false;
                updatedConfigs[day].timings = [block24HrsTiming];
            }
        });
        setDayConfigs(updatedConfigs);
    };

    const handleToggleDisabled = (isChecked: boolean): void => {
        const updatedConfigs = { ...dayConfigs };
        selectedDays.forEach((day) => {
            updatedConfigs[day].disabled = isChecked;
            if (isChecked) {
                updatedConfigs[day].alwaysBlocked = false;
                updatedConfigs[day].timings = [blockDisabledTiming];
            }
        });
        setDayConfigs(updatedConfigs);
    };

    const updateTimings = (
        timings: Timings[], 
        timingIndex: number, 
        type: "start" | "end", 
        value: string
    ): Timings[] | null => {
        if (!timings[timingIndex]) return null;
    
        const updatedTiming = { ...timings[timingIndex], [type]: value };
    
        const isOverlap = timings.some((timing, index) => 
            index !== timingIndex &&
            updatedTiming.start < timing.end &&
            updatedTiming.end > timing.start
        );
    
        return isOverlap ? null : timings.map((time, index) => (index === timingIndex ? updatedTiming : time));
    };
    
    const handleTimingChange = (timingIndex: number, type: "start" | "end", value: string): void => {
        setDayConfigs(prevConfigs => {
            const updatedConfigs = { ...prevConfigs };
    
            selectedDays.forEach(selectedDay => {
                const dayConfig = updatedConfigs[selectedDay];
                if (!dayConfig) return;
    
                const updatedTimings = updateTimings(dayConfig.timings, timingIndex, type, value);
                if (!updatedTimings) {
                    setNotificationState({
                        open: true,
                        severity: 'error',
                        message: 'The new interval overlaps with an existing one !!'
                    });
                    return;
                }
    
                updatedConfigs[selectedDay] = { ...dayConfig, timings: updatedTimings };
            });
    
            return updatedConfigs;
        });
    };
    
    function onCloseNotification(): void {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    const handleAddTiming = (): void => {
        const updatedConfigs = { ...dayConfigs };
        selectedDays.forEach((day) => {
            updatedConfigs[day].timings.push({ start: "", end: "" });
        });
        setDayConfigs(updatedConfigs);
    };

    const handleRemoveTiming = (timingIndex: number): void => {
        setDayConfigs(prevConfigs => {
            const updatedConfigs = { ...prevConfigs };

            selectedDays.forEach(selectedDay => {
                if (updatedConfigs[selectedDay]) {
                    const updatedTimings = [...updatedConfigs[selectedDay].timings];
                    if (timingIndex >= 0 && timingIndex < updatedTimings.length) {
                        updatedTimings.splice(timingIndex, 1);
                    }
                    updatedConfigs[selectedDay] = {
                        ...updatedConfigs[selectedDay],
                        timings: updatedTimings
                    };
                }
            });

            return updatedConfigs;
        });
    };

    const savedDayConfigs = (dayConfigs: Record<WeekDays, DayConfiguration>): DaySchedule[] => {
        return (Object.values(WeekDays) as WeekDays[])
            .filter(day => dayConfigs[day])
            .map(day => ({
                day,
                interval: dayConfigs[day].timings,
                alwaysBlocked: dayConfigs[day].alwaysBlocked ?? false,
                disabled: dayConfigs[day].disabled ?? true
            }));
    };

    const handleSave = (): void => {
        const organizedData = savedDayConfigs(dayConfigs);
        onSave(organizedData);
    };

    return (
        <Box>
            <Box
                sx={{
                    paddingBottom: 2,
                    maxWidth: '90%',
                    margin: "auto"
                }}>
                <Typography variant="subtitle1" fontWeight="bold">
                    {t('settings.configuration.application-blocked')}
                </Typography>

                <Autocomplete
                    sx={dropdownStyle}
                    multiple
                    disablePortal
                    id="configurationSelected"
                    value={blockedItems}
                    disabled={false}
                    options={[]}
                    open={false}
                    getOptionLabel={({ programApps }: BlockedConfiguration) => programApps ?? ''}
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props}>
                                {option?.programApps}
                            </li>
                        );
                    }}
                    ChipProps={{ sx: chipStyle }}
                />
            </Box>
            <Box
                sx={{
                    marginTop: 2,
                    padding: 4,
                    maxWidth: 700,
                    margin: "auto",
                    border: "1px solid #ddd",
                    borderRadius: 2,
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 600 }}>
                    {t('settings.configuration.select-days-and-configure-hours')}
                </Typography>

                <Grid container spacing={2} justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                    {daysOfWeek.map((day) => (
                        <Grid item key={day.value}>
                            <Box
                                onClick={() => toggleDaySelection(day.value)}
                                sx={{
                                    width: 50,
                                    height: 50,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%",
                                    backgroundColor: selectedDays.includes(day.value) ? "#B3CBE0" : "#f0f0f0",
                                    color: selectedDays.includes(day.value) ? "#1d5195" : "#000",
                                    cursor: "pointer",
                                    border: selectedDays.includes(day.value)
                                        ? "2px solid rgb(143, 178, 212)"
                                        : "2px solid #ccc",
                                    fontSize: 14,
                                    fontWeight: 500,
                                    transition: "all 0.3s ease",
                                    "&:hover": {
                                        backgroundColor: selectedDays.includes(day.value) ? "#a4bfde" : "#e0e0e0",
                                    },
                                }}
                            >
                                {day.label}
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                {/* Controls */}
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        padding: 2,
                        maxWidth: 500,
                        margin: "auto",
                        boxShadow: 3,
                        borderRadius: 2,
                        backgroundColor: "#f9f9f9",
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ width: "60%", marginBottom: 2 }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedDays.length === 0 ? false : selectedDays.every((day) => dayConfigs[day].alwaysBlocked)}
                                    onChange={(e) => handleToggle24Hours(e.target.checked)}
                                />
                            }
                            label="Block 24 Hours"
                            sx={{ "& .MuiTypography-root": { fontWeight: "bold" } }}
                            disabled={selectedDays.length === 0}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedDays.length === 0 ? false : selectedDays.every((day) => dayConfigs[day].disabled)}
                                    onChange={(e) => handleToggleDisabled(e.target.checked)}
                                />
                            }
                            label="Disable"
                            sx={{ "& .MuiTypography-root": { fontWeight: "bold" } }}
                            disabled={selectedDays.length === 0}
                        />
                    </Box>

                    {/* Time Slots */}
                    {!selectedDays.every((day) => dayConfigs[day].alwaysBlocked || dayConfigs[day].disabled) && (
                        <Box sx={{ width: "100%" }}>
                            {dayConfigs[selectedDays[0]]?.timings?.map((timing, timingIndex) => (
                                <Grid
                                    key={`${timing.start}+${timing.end}`}
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ mb: 1, ml: 3 }}
                                >
                                    <Grid item xs={5}>
                                        <TextField
                                            type="time"
                                            fullWidth
                                            size="small"
                                            value={timing.start}
                                            onChange={(e) =>
                                                handleTimingChange(
                                                    timingIndex,
                                                    "start",
                                                    e.target.value
                                                )
                                            }
                                            sx={{ backgroundColor: "white", borderRadius: 1 }}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            type="time"
                                            fullWidth
                                            size="small"
                                            value={timing.end}
                                            onChange={(e) =>
                                                handleTimingChange(
                                                    timingIndex,
                                                    "end",
                                                    e.target.value
                                                )
                                            }
                                            sx={{ backgroundColor: "white", borderRadius: 1 }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            color="default"
                                            size="small"
                                            onClick={() => handleRemoveTiming(timingIndex)}
                                        >
                                            <Cancel />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Button
                                startIcon={<Add />}
                                variant="outlined"
                                size="small"
                                onClick={handleAddTiming}
                                sx={{ marginTop: 2 }}
                            >
                                {t('settings.configuration.add-hours')}
                            </Button>
                        </Box>
                    )}
                </Box>

                {/* Action Buttons */}
                <Box sx={{ textAlign: "right", marginTop: 3 }}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onClose}
                            >
                                {t('common.actions.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={
                                    selectedDays.length === 0 ||
                                    selectedDays.every(day =>
                                        dayConfigs[day]?.timings.length === 0 &&
                                        !dayConfigs[day]?.alwaysBlocked &&
                                        !dayConfigs[day]?.disabled
                                    )}
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                            >
                                {t('common.actions.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Snackbar
                    autoHideDuration={3000}
                    open={notificationState?.open}
                    onClose={onCloseNotification}
                >
                    <Alert severity={notificationState?.severity}>
                        {notificationState?.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>

    );
}
