import {
    Alert,
    AlertColor,
    AlertProps,
    Box,
    Button,
    Chip,
    IconButton,
    Snackbar,
    SnackbarProps,
    Stack,
    TextField,
    Theme
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/data-table/DataTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { BlockActions, BlockAppsManagement } from '../block-apps-management/BlockAppsManagement';
import {
    deleteBlockConfiguration,
    getBlockAppsList,
    getProgramsListOptions,
    resetBlockConfigurationStatuses,
    selectApplicationsListOptionsData,
    selectBlockAppsListData,
    selectBlockAppsListStatus,
    selectCreateBlockConfigurationStatus,
    selectDeleteBlockConfigurationStatus,
    selectEditBlockConfigurationStatus
} from 'src/state/administrationMeta/administrationMetaSlice';
import { ApplicationsList } from 'src/models/administration/resourceData.models';
import { BlockedConfiguration, ConfigurationLevel, BlockType } from 'src/models/administration/blockConfigurationData.model';
import { Modal } from 'src/components/modal/Modal';
import { ConfirmationSelection } from '../confirmation-selection/ConfirmationSelection';
import debounceSearch from 'src/utils/common';
import { DEBOUNCE_DELAY_TIME } from 'src/utils/environment';
import { DefaultTimings } from 'src/models/blockManagement.enum';

export interface ProgramSelectorProps {
    context: ConfigurationLevel;
    configuredId: string;
}

export function ProgramSelector({
    context,
    configuredId,
}: Readonly<ProgramSelectorProps>): React.ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const programsListOptionsData = useSelector(selectApplicationsListOptionsData);
    const [programListOptions, setProgramListOptions] = useState<ApplicationsList[]>([]); // new
    const [isBlockAppsToAdd, setIsBlockAppsToAdd] = useState<boolean>(false);
    const [blockAppsToEdit, setBlockAppsToEdit] = useState<BlockedConfiguration>();
    const [blockAppsToDelete, setBlockAppsToDelete] = useState<BlockedConfiguration>();
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();
    const [loadListData, setLoadListData] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const blockAppsListData = useSelector(selectBlockAppsListData);
    const blockAppsListDataStatus = useSelector(selectBlockAppsListStatus);
    const blockAppCreateStatus = useSelector(selectCreateBlockConfigurationStatus);
    const blockAppEditStatus = useSelector(selectEditBlockConfigurationStatus);
    const blockAppDeleteStatus = useSelector(selectDeleteBlockConfigurationStatus);

    function onPageChange(pageNumber: number): void {
        dispatch(getBlockAppsList({
            blockType: BlockType.APPS,
            pageNumber,
            entriesPerPage,
            searchValue,
            configuredId,
            configurationLevel: context,
        }));
    }

    const dispatchBlockAppsList = (): void => {
        dispatch(getBlockAppsList({
            blockType: BlockType.APPS,
            pageNumber: 1,
            entriesPerPage,
            searchValue,
            configuredId,
            configurationLevel: context,
        }));
    }

    useEffect(() => {
        dispatchBlockAppsList();
    }, [dispatch, entriesPerPage, searchValue]);

    useEffect(() => {
        if (loadListData) dispatchBlockAppsList();
    }, [loadListData]);


    const onSaveModal = (): void => {
        setBlockAppsToEdit(undefined);
        setIsBlockAppsToAdd(false);
        setLoadListData(true);
        setTimeout(() => {
            setLoadListData(false);
        }, 1000);
    }

    function onCancel(): void {
        setBlockAppsToEdit(undefined);
        setIsBlockAppsToAdd(false);
    }

    useEffect(() => {
        if (Array.isArray(programsListOptionsData)) {
            setProgramListOptions(programsListOptionsData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programsListOptionsData]);

    useEffect(() => {
        setProgramListOptions([]);
        dispatch(getProgramsListOptions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onChangeSearchValue(value: string): void {
        setSearchValue(value);
    }
    const debouncedValue = debounceSearch(onChangeSearchValue, DEBOUNCE_DELAY_TIME);
    
    const onAddApplication = (): void => {
        setIsBlockAppsToAdd(true);
    }

    function onCloseNotification(): void {
        dispatch(resetBlockConfigurationStatuses());
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    useEffect(() => {
        let message = '';
        let severity: AlertColor = 'success';

        if (blockAppCreateStatus === RequestStatus.Success) {
            message = t('settings.configuration.block-application-created-successfully');
        }
        else if (blockAppEditStatus === RequestStatus.Success) {
            message = t('settings.configuration.block-application-edited-successfully');
        }
        else if (blockAppDeleteStatus === RequestStatus.Success) {
            message = t('settings.configuration.block-application-deleted-successfully');
            onSaveModal();
        }
        else if (
            blockAppCreateStatus === RequestStatus.Failure ||
            blockAppEditStatus === RequestStatus.Failure ||
            blockAppDeleteStatus === RequestStatus.Failure
        ) {
            message = t('common.messages.something-wrong');
            severity = 'error';
        }
        if (message) {
            setNotificationState({ open: true, severity, message });
        }
    }, [blockAppCreateStatus, blockAppEditStatus, blockAppDeleteStatus, setNotificationState, t, dispatch]);

    const onPerformAction = (data: BlockedConfiguration, action: BlockActions): void => {
        if (action === BlockActions.Edit) {
            setBlockAppsToEdit(data);
        } else {
            setBlockAppsToDelete(data);
        }
    }
    const getActionColumn = (data: BlockedConfiguration): React.ReactNode => (
        <>
            <IconButton
                aria-label="edit"
                onClick={() => onPerformAction(data, BlockActions.Edit)}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                aria-label="delete"
                onClick={() => onPerformAction(data, BlockActions.Delete)}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );

    const getBlockedTimings = (data: BlockedConfiguration): React.ReactElement | string => {
        const schedule = data?.schedule;
    
        if (!schedule?.length) {
            return '-';
        }
    
        const allIntervals = schedule.flatMap(day => day.interval);
        if (allIntervals.every(interval => interval.start === DefaultTimings.start && interval.end === DefaultTimings.end)) {
            return <Chip label={'Always'} color="success" size="small" />
        }
        if (allIntervals.every(interval => interval.start === DefaultTimings.start && interval.end === DefaultTimings.start)) {
            return <Chip label={'Disabled'} color="error" size="small" />
        }
        return `${allIntervals[0]?.start || '-'} - ${allIntervals[0]?.end || '-'} +`;
    };
    
    const columns = [
        {
            label: t('settings.configuration.application-blocked'),
            size: 5,
            align: 'center' as const,
            value: (data: BlockedConfiguration) => data.programApps,
        },
        {
            label: t('settings.configuration.block-timings'),
            size: 5,
            align: 'center' as const,
            value: (data: BlockedConfiguration) => getBlockedTimings(data),
        },
        {
            label: t('settings.configuration.last-updated-at'),
            size: 5,
            align: 'center' as const,
            value: (data: BlockedConfiguration) => data.lastUpdatedAt ?
                new Date(data.lastUpdatedAt).toLocaleString() : '-',
        },
        {
            label: t('data-type.table.action'),
            size: 2,
            align: 'center' as const,
            format: (data: BlockedConfiguration) => getActionColumn(data),
        },
    ];

    const handleDeleteConfirmation = (value: boolean): void => {
        if (value === true && blockAppsToDelete) {
            const deletedBlockApps = {
                ...blockAppsToDelete,
                applicationId: blockAppsToDelete.id,
                isDeleted: true,
                schedule: [],
                context,
                configuredId,
                blockType: BlockType.APPS,
            };
            dispatch(deleteBlockConfiguration({
                actionId: deletedBlockApps?.applicationId,
                configurations: [
                    deletedBlockApps,
                    ...blockAppsListData?.contents?.filter(app =>
                      app.programApps !== deletedBlockApps.programApps
                    ) ?? []
                  ]
            }));
        }
        setBlockAppsToDelete(undefined);
    };

    return (
        <>
            <Stack direction="column" sx={{ textAlign: 'left' }}>
                <Stack
                    direction="row"
                    justifyContent="end"
                    alignItems="center"
                    padding="0 0 1em 0"
                >
                    {/* Search Field */}
                    <Box sx={{ flex: 1, maxWidth: '20%' }}>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Search"
                            autoComplete="off"
                            onChange={(event) => debouncedValue(event.target.value)}
                        />
                    </Box>

                    <Button
                        variant="contained"
                        sx={{
                            ml: 2,
                            display: 'flex',
                            alignItems: 'center',
                            lineHeight: 'none',
                            '&:hover, &:active': {
                                color: (theme: Theme) =>
                                    theme.palette.common.goldYellow,
                            },
                        }}
                        onClick={onAddApplication}
                    >
                        {t('settings.resource.add-applications').toUpperCase()}
                    </Button>
                </Stack>
            </Stack>

            <Box>
                <DataTable<BlockedConfiguration>
                    isLoading={blockAppsListDataStatus === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={onPageChange}
                    alignHeader={'center'}
                    entriesPerPage={entriesPerPage}
                    onChangeEntriesPerPage={setEntriesPerPage}
                    {...blockAppsListData}
                />
            </Box>

            <Modal
                title={t('Block Apps Management')}
                open={!!blockAppsToEdit || isBlockAppsToAdd}
                maxWidth="md"
                fullWidth
                onClose={onCancel}
                showCloseButton={true}
            >
                <BlockAppsManagement
                    action={isBlockAppsToAdd ? BlockActions.Add : BlockActions.Edit}
                    editBlockApps={blockAppsToEdit ?? {}}
                    existingApps={blockAppsListData?.contents ?? []}
                    programOptions={programListOptions}
                    onSave={onSaveModal}
                    onCancel={onCancel}
                    context={context}
                    configuredId={configuredId}
                />
            </Modal>

            <Modal
                title={t('settings.configuration.delete-block-application')}
                open={!!blockAppsToDelete}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <ConfirmationSelection
                        text={t("settings.configuration.block-application-delete-confirmation", {
                            application: blockAppsToDelete?.programApps?.toUpperCase()
                        })}
                        onSubmit={handleDeleteConfirmation}
                    />
                </Box>
            </Modal>

            <Snackbar
                autoHideDuration={2500}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert severity={notificationState?.severity}>
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    )
}
