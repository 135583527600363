/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    selectNotificationConfiguration,
    selectNotificationConfigurationNotifications,
    selectNotificationConfigurationNotificationsStatus,
    getNotificationConfigurationNotifications,
} from 'src/state/administration/administrationSlice';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Notification } from 'src/models/administration/notificationData.model';
import { dataTypeStringKey } from 'src/models/dataType.enum';

export function NotificationDetails(): React.ReactElement
{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const notificationConfiguration = useSelector(selectNotificationConfiguration);
    const items = useSelector(selectNotificationConfigurationNotifications);
    const status = useSelector(selectNotificationConfigurationNotificationsStatus);

    useEffect(() =>
    {
        if (notificationConfiguration)
        {
            dispatch(
                getNotificationConfigurationNotifications({
                    pageNumber: 1,
                    entriesPerPage,
                    notificationConfigurationId: notificationConfiguration.id,
                })
            );
        }
    }, [dispatch, entriesPerPage, notificationConfiguration]);

    function onPageChange(pageNumber: number): void
    {
        dispatch(
            getNotificationConfigurationNotifications({
                pageNumber,
                entriesPerPage,
                notificationConfigurationId: notificationConfiguration?.id,
            })
        );
    }

    const columns: ColumnData<Notification>[] = [
        {
            label: t('notifications.notifications-recorded'),
            size: 8,
            value: (data: Notification) => data.dataId,
        },
        {
            label: t('notifications.data-type'),
            size: 8,
            value: (data: Notification) => t(dataTypeStringKey(data.dataType)),
        },
        {
            label: t('data-type.table.date'),
            size: 4,
            value: (data: Notification) => 
                new Date(data.clientCreatedAt).toLocaleString(),
        },
    ];

    return (
        <DataTable<Notification>
            isLoading={status === RequestStatus.InProgress}
            columns={columns}
            onPageChange={onPageChange}
            entriesPerPage={entriesPerPage}
            onChangeEntriesPerPage={setEntriesPerPage}
            {...items}
        />
    );
}
