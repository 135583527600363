import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { handleEmptyFunction, noop } from 'src/utils/common';
import { Modal } from 'src/components/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationPanel from '../confirmation-panel/ConfirmationPanel';
import {
    CacheData, DeviceCacheDetails,
} from 'src/models/administration/deviceCacheDetailsData.model';
import { DataTable } from 'src/components/data-table/DataTable';
import {
    deleteDeviceCacheData, resetDeleteDeviceCacheStatus, selectDeleteDeviceCacheStatus,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Service } from 'src/models/configurationService.enum';

export interface DeviceEditionModalProps {
    deviceCacheData: DeviceCacheDetails;
    open?: boolean;
    onClose?: () => void;
}

interface CacheTableContent {
    contents: CacheData[];
    currentPage?: number;
    numberOfEntries?: number;
}

export function DeviceCacheModal({
    deviceCacheData,
    open = false,
    onClose = noop,
}: Readonly<DeviceEditionModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cacheDetails } = deviceCacheData;
    const deleteStatus = useSelector(selectDeleteDeviceCacheStatus);
    const [cacheCountData, setCacheCountData] = useState<CacheTableContent | null>(null);
    const [openAlertModal, setOpenAlertModal] = useState<boolean>(false);
    const [isSelectionAll, setIsSelectionAll] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<string[]>([]);

    function DeleteSubmit(): void
    {
        if (deviceCacheData && selectedService.length)
        {
            setOpenAlertModal(false);
            const updatedServices = selectedService.flatMap(service => service.split('/'));
            dispatch(deleteDeviceCacheData(
                { services: updatedServices, deviceId: deviceCacheData.deviceId }
            ));
        }
    }
    function DeleteModal(): void
    {
        setOpenAlertModal(false);
    }

    useEffect(() =>
    {
        if( deleteStatus !== RequestStatus.InProgress &&
            deleteStatus !== RequestStatus.NotStarted
        )
        {
            onClose();
            dispatch(resetDeleteDeviceCacheStatus());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus]);

    useEffect(() =>
    {
        if (cacheDetails)
        {
            const websites = cacheDetails.find(item => item.name === Service.WEBSITES);
            const searches = cacheDetails.find(item => item.name === Service.SEARCHES);

            const combinedWebsitesSearches = (() =>
            {
                return {
                    name: Service.WEBSITES_SEARCHES,
                    unSyncedCount:
                        Number(websites?.unSyncedCount ?? 0)+Number(searches?.unSyncedCount ?? 0),
                    lastSyncedAt: new Date(
                        Math.max(
                            websites?.lastSyncedAt ? new Date(websites.lastSyncedAt).getTime() : 0,
                            searches?.lastSyncedAt ? new Date(searches.lastSyncedAt).getTime() : 0
                        )
                    ),
                    deleteFrom: websites?.deleteFrom || searches?.deleteFrom,
                };
            })();

            const filteredServices = cacheDetails.filter(
                item => item.name !== Service.WEBSITES && item.name !== Service.SEARCHES
            );

            const updatedCacheDetails = combinedWebsitesSearches
                ? [...filteredServices, combinedWebsitesSearches]
                : filteredServices;

            const sortedCacheDetails = Array.from(updatedCacheDetails).sort((itemOne, itemTwo) =>
            {
                const countDiff = Number(itemTwo.unSyncedCount) - Number(itemOne.unSyncedCount);
                return countDiff !== 0 ? countDiff : 0;
            });

            const cacheTableContent = {
                contents: sortedCacheDetails.map((item: CacheData) =>
                {
                    if (item.name === Service.WEBSITES_SEARCHES)
                    {
                        return {
                            ...item,
                            unSyncedCount:
                                `${websites?.unSyncedCount ?? 0}/${searches?.unSyncedCount ?? 0}`,
                            isChecked: item?.isChecked || false,
                        };
                    }
                    return {
                        ...item,
                        isChecked: item?.isChecked || false,
                    };
                }),
                currentPage: 1,
                numberOfEntries: sortedCacheDetails?.length ?? 0,
            };
            setCacheCountData(cacheTableContent);
        }
    }, [cacheDetails]);

    const handleCheckboxChange = (data: CacheData): void =>
    {
        if (!cacheCountData) return;
        const updatedContent = cacheCountData.contents.map((item: CacheData) =>
        {
            if (item.name === data.name)
            {
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        setCacheCountData((prev: CacheTableContent | null) =>
        {
            if (!prev) return prev;
            return {
                ...prev,
                contents: updatedContent,
            };
        });
        setSelectedService((prevSelected) =>
        {
            if (data.isChecked)
            {
                return prevSelected.filter((item) => item !== data.name);
            }
            else
            {
                return [...prevSelected, data.name];
            }
        });
        const isAllSelected = updatedContent.every(item => item.isChecked);
        setIsSelectionAll(isAllSelected);
    };

    const handleSelectAllChange = (value: boolean): void =>
    {
        setIsSelectionAll(value);
        const updatedAllContent = cacheCountData?.contents.map((item: CacheData) =>
        {
            return { ...item, isChecked: value };
        }) ?? [];
        setCacheCountData((prev: CacheTableContent | null) => ({
            ...prev,
            contents: updatedAllContent,
        }));
        setSelectedService(value
            ? cacheCountData?.contents.map((item: CacheData) => item.name) ?? []
            : []);
    };

    const getCheckboxColumn = (data: CacheData): React.ReactNode => (
        <Checkbox
            style={{ marginLeft: 'auto' }}
            onChange={() => handleCheckboxChange(data)}
            checked={data?.isChecked ?? false}
        />
    );

    const columns = [
        {
            label: t('data-type.table.datatype-services'),
            size: 5,
            value: (data: CacheData) => data.name,
        },
        {
            label: t('data-type.table.unsynced-counts'),
            size: 5,
            value: (data: CacheData) => data.unSyncedCount,
        },
        {
            label: t('data-type.table.last-synced-at'),
            size: 5,
            value: (data: CacheData) => data.lastSyncedAt ?
                new Date(data.lastSyncedAt).toLocaleString() : '-',
        },
        {
            label: t('data-type.table.last-deletion-at'),
            size: 5,
            value: (data: CacheData) => data.deleteFrom ?
                new Date(data.deleteFrom).toLocaleString() : '-',
        },
        {
            label: (
                <div
                    style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                    }}
                >
                    <span>
                        {t('data-type.filters.show-all')}
                        <Checkbox
                            style={{ marginLeft: 'auto' }}
                            onChange={(event) => handleSelectAllChange(event.target.checked)}
                            checked={isSelectionAll}
                        />
                    </span>
                </div>
            ) as unknown as string,
            size: 4,
            value: (data: CacheData) => getCheckboxColumn(data),
        },
    ];

    return (
        <>
            <Modal
                open={open}
                title={` ${deviceCacheData.deviceAlias} ${t('data-type.table.unsynced-data')}`}
                onClose={onClose}
                showCloseButton={true}
                maxWidth="md"
                fullWidth
            >
                <>
                    <Box>
                        <DataTable<CacheData>
                            columns={columns}
                            onPageChange={handleEmptyFunction}
                            entriesPerPage={cacheDetails?.length ?? 0}
                            pagination={false}
                            onChangeEntriesPerPage={handleEmptyFunction}
                            {...cacheCountData}
                        />
                    </Box>
                    <Box mt={-1} mb={-1}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            padding={'1em 0'}
                            margin={'2em 0em'}
                        >
                            {
                                deleteStatus === RequestStatus.InProgress
                                    ? <CircularProgress />
                                    : (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            onClick={() =>
                                            {
                                                setOpenAlertModal(true);
                                            }}
                                            disabled={selectedService?.length === 0}
                                        >
                                            {t('common.actions.delete')}
                                        </Button>
                                    )
                            }

                        </Stack>
                    </Box>
                </>
            </Modal>
            <Modal
                title={t('data-type.table.delete-unsynced-data')}
                open={openAlertModal}
                maxWidth="md"
                fullWidth
                onClose={DeleteModal}
                showCloseButton={true}
            >
                <Box>
                    <ConfirmationPanel
                        data={deviceCacheData?.deviceId ?? ''}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Device'}
                        typeFormat={'ID'}
                    />
                </Box>
            </Modal>
        </>
    );
}
