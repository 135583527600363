import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Alert, Button, Theme, Grid, IconButton, Snackbar, SnackbarProps, Stack, TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
    getApplicationsList,
    selectApplicationsListData,
    selectApplicationsListStatus,
    selectCreateApplicationsStatus,
    resetApplicationStatuses,
    selectEditApplicationsStatus,
    selectDeleteApplicationsStatus,
    deleteApplications,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { useTranslation } from 'react-i18next';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { ApplicationsList } from 'src/models/administration/resourceData.models';
import { Actions } from 'src/models/roles.enum';
import { Modal } from 'src/components/modal/Modal';
import { ConfirmationSelection } from 'src/templates/confirmation-selection/ConfirmationSelection';
import { ApplicationsListModal } from './ApplicationsListModal';
import debounceSearch from 'src/utils/common';
import { DEBOUNCE_DELAY_TIME } from 'src/utils/environment';

export const addButtonStyle = {
    ml: 2,
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'none',
    '&:hover, &:active': {
        color: (theme: Theme) =>
            theme.palette.common.goldYellow,
    },
}

interface SearchFieldProps {
    placeholder?: string;
    width?: string;
    onChange: (value: string) => void;
}

export const SearchField = ({ onChange, placeholder = "Search", width = "17%" }: SearchFieldProps): React.ReactElement => {
    const debounced = debounceSearch(onChange, DEBOUNCE_DELAY_TIME);
    return (
        <Box sx={{ flex: 1, maxWidth: width }}>
            <TextField
                fullWidth
                size="small"
                placeholder={placeholder}
                autoComplete="off"
                onChange={(event) => debounced(event.target.value)}
            />
        </Box>
    );
};

export function ApplicationsListTable(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const applicationsListData = useSelector(selectApplicationsListData);
    const applicationsListStatus = useSelector(selectApplicationsListStatus);
    const [searchValue, setSearchValue] = useState<string>('');
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [isApplicationToAdd, setIsApplicationToAdd] = useState<boolean>(false);
    const [applicationToEdit, setApplicationToEdit] = useState<ApplicationsList>();
    const [applicationToDelete, setApplicationToDelete] = useState<ApplicationsList>();
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [loadListData, setLoadListData] = useState<boolean>(false);
    const appCreateStatus = useSelector(selectCreateApplicationsStatus);
    const appEditStatus = useSelector(selectEditApplicationsStatus);
    const appDeleteStatus = useSelector(selectDeleteApplicationsStatus);

    function onPageChange(pageNumber: number): void {
        dispatch(getApplicationsList({
            pageNumber,
            entriesPerPage,
            searchValue
        }));
    }
    const onSaveModal = (): void => {
        setApplicationToEdit(undefined);
        setApplicationToDelete(undefined);
        setIsApplicationToAdd(false);
        setLoadListData(true);
        setTimeout(() => {
            setLoadListData(false);
        }, 1000);
    }

    const onAddApplication = (): void => {
        setIsApplicationToAdd(true);
    }

    const dispatchApplicationsList = (): void => {
        dispatch(getApplicationsList({
            pageNumber: 1,
            entriesPerPage,
            searchValue,
        }));
    }

    useEffect(() => {
        dispatchApplicationsList();
    }, [dispatch, entriesPerPage, searchValue]);

    useEffect(() => {
        if (loadListData) dispatchApplicationsList();
    }, [loadListData]);

    useEffect(() => {
        let message = '';

        if (appCreateStatus === RequestStatus.Success) {
            message = t('settings.resource.application-created-successfully');
        }
        else if (appEditStatus === RequestStatus.Success) {
            message = t('settings.resource.application-edited-successfully');
        }
        else if (appDeleteStatus === RequestStatus.Success) {
            message = t('settings.resource.application-deleted-successfully');
            onSaveModal();
        }
        if (message) {
            setNotificationState({ open: true, message });
        }
    }, [appCreateStatus, appEditStatus, appDeleteStatus, setNotificationState, t, dispatch]);

    function onCloseNotification(): void {
        dispatch(resetApplicationStatuses());
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    function onChangeSearchValue(value: string): void {
        setSearchValue(value);
    }

    const onPerformAction = (data: ApplicationsList, action: Actions): void => {
        if (action === Actions.EDIT) {
            setApplicationToEdit(data);
        } else {
            setApplicationToDelete(data);
        }
    }

    const onCloseModal = (): void => {
        setApplicationToEdit(undefined);
        setIsApplicationToAdd(false);
    }

    const getActionColumn = (data: ApplicationsList): React.ReactNode => (
        <>
            <IconButton
                aria-label="edit"
                onClick={() => onPerformAction(data, Actions.EDIT)}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                aria-label="delete"
                onClick={() => onPerformAction(data, Actions.DELETE)}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );

    const columns: ColumnData<ApplicationsList>[] = [
        {
            label: t('settings.resource.application-name'),
            size: 2,
            align: 'center' as const,
            value: (data: ApplicationsList) => data.programName,
        },
        {
            label: t('common.nouns.platform'),
            size: 1,
            align: 'center' as const,
            value: (data: ApplicationsList) => data.platform?.join(', '),
        },
        {
            label: t('extension-form.action'),
            size: 2,
            align: 'center' as const,
            format: (data: ApplicationsList) => getActionColumn(data),
        },
    ];

    const handleDeleteConfirmation = (value: boolean): void => {
        if (value === true && applicationToDelete) {
            dispatch(deleteApplications(applicationToDelete));
        }
        setApplicationToDelete(undefined);
    };

    return (
        <>
            <Box>
                <Stack direction="column" sx={{ textAlign: 'left' }}>
                    <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        padding="1em 0"
                    >
                        {/* Search Field */}
                        <SearchField onChange={onChangeSearchValue}/>
                        <Button
                            variant="contained"
                            sx={addButtonStyle}
                            onClick={onAddApplication}
                        >
                            {t('settings.resource.add-applications').toUpperCase()}
                        </Button>
                    </Stack>
                </Stack>
                <Grid>
                    <DataTable<ApplicationsList>
                        isLoading={applicationsListStatus === RequestStatus.InProgress}
                        columns={columns}
                        alignHeader={'center'}
                        onPageChange={onPageChange}
                        entriesPerPage={entriesPerPage}
                        onChangeEntriesPerPage={setEntriesPerPage}
                        {...applicationsListData}
                    />
                </Grid>
            </Box>

            <Modal
                title={t('settings.resource.application-management')}
                open={!!applicationToEdit || isApplicationToAdd}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <ApplicationsListModal
                        action={isApplicationToAdd ? Actions.ADD : Actions.EDIT}
                        application={applicationToEdit}
                        onSave={onSaveModal}
                        onCancel={onCloseModal}
                    />
                </Box>
            </Modal>

            <Modal
                title={t('settings.resource.delete-application')}
                open={!!applicationToDelete}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <ConfirmationSelection
                        text={t("settings.resource.application-delete-confirmation", {
                            application: applicationToDelete?.programName?.toUpperCase()
                        })} 

                        onSubmit={handleDeleteConfirmation}
                    />
                </Box>
            </Modal>

            <Snackbar
                autoHideDuration={3000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}


