import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Autocomplete,
  Button, CircularProgress, Grid, IconButton, TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { DayTimeScheduler } from 'src/components/day-time-scheduler/DayTimeScheduler';
import { Modal } from 'src/components/modal/Modal';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { handleEmptyFunction } from 'src/utils/common';
import { ApplicationsList } from 'src/models/administration/resourceData.models';
import {
  createBlockConfiguration,
  editBlockConfiguration,
  getDefaultConfigurationSchedule,
  selectApplicationsListOptionsStatus,
  selectConfigSchedule,
  selectConfigScheduleStatus,
  selectCreateBlockConfigurationStatus,
  selectEditBlockConfigurationStatus
} from 'src/state/administrationMeta/administrationMetaSlice';
import {
  BlockedConfiguration, BlockType, ConfigurationLevel, DaySchedule
} from 'src/models/administration/blockConfigurationData.model';
import { BlockScheduleType, DefaultTimings } from 'src/models/blockManagement.enum';

export const dropdownStyle = {
  '& .MuiInputBase-root': {
    height: '50px',
    minHeight: '30px',
    padding: '0 8px',
  }
};

export const chipStyle = {
  backgroundColor: 'primary.main',
  color: 'primary.contrastText',
  '.MuiSvgIcon-root': {
    color: '#FFFFFF73',
  },
  '.MuiSvgIcon-root:hover': {
    color: '#FFFFFFAB',
  },
  margin: '0 0 5px 5px',
};

export enum BlockActions {
  Add = "add",
  Edit = "edit",
  Delete = "delete"
}

export interface BlockAppsManagementProps {
  existingApps: BlockedConfiguration[];
  editBlockApps: BlockedConfiguration;
  programOptions: ApplicationsList[];
  action: string;
  open?: boolean;
  onClose?: () => void;
  onSave: () => void;
  onCancel: () => void;
  context: ConfigurationLevel;
  configuredId: string;

}
export function BlockAppsManagement({
  existingApps,
  editBlockApps,
  programOptions,
  onSave,
  action,
  context,
  configuredId,
  onCancel,
}: Readonly<BlockAppsManagementProps>): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAdd = action === BlockActions.Add;
  const [appsInput, setAppsInput] = useState<BlockedConfiguration[]>([]);
  const [selectedApps, setSelectedApps] = useState<BlockedConfiguration[]>([]);
  const [openSchedulerModal, setOpenSchedulerModal] = useState(false);
  const [blockTimings, setBlockTimings] = useState<DaySchedule[]>();
  const [dayToEdit, setDayToEdit] = useState<DaySchedule>();
  const [isLoading, setIsLoading] = useState(false);
  const defaultConfigSchedule = useSelector(selectConfigSchedule);
  const defaultConfigScheduleStatus = useSelector(selectConfigScheduleStatus);
  const programsListOptionsStatus = useSelector(selectApplicationsListOptionsStatus);
  const blockAppCreateStatus = useSelector(selectCreateBlockConfigurationStatus);
  const blockAppEditStatus = useSelector(selectEditBlockConfigurationStatus);

  useEffect(() => {
    if (action === BlockActions.Edit && editBlockApps) {
      setSelectedApps([editBlockApps]);
      setBlockTimings(editBlockApps.schedule);
    }
  }, [editBlockApps])

  useEffect(() => {
    if(action === BlockActions.Add) {
      dispatch(getDefaultConfigurationSchedule({
        type: ConfigurationLevel.DEFAULT,
      }));
    }
  }, []);

  useEffect(() => {
    if (defaultConfigSchedule && action === BlockActions.Add) setBlockTimings(defaultConfigSchedule.schedule);
  }, [defaultConfigSchedule]);

  const handleOpenManageApps = (): void => {
    setOpenSchedulerModal(false);
  };

  const handleOpenScheduler = (): void => {
    setOpenSchedulerModal(true);
  };

  const handleOnCancelHandler = (): void => {
    onCancel();
  };
  const handleEditAll = (): void => {
    setDayToEdit(undefined);
    handleOpenScheduler();
  };

  const handleOnSaveScheduler = (schedulerValue: DaySchedule[]): void => {
    setOpenSchedulerModal(false);
    setBlockTimings(schedulerValue);
  };

  const toConfigurationBlockedApp = ({
    id,
    programName
  }: ApplicationsList): BlockedConfiguration => {
    const name = programName ?? 'No program installed';
    return {
      id,
      programApps: name,
    };
  }

  const onChangeInputHandler = (value: BlockedConfiguration[]): void => {
    setAppsInput(value);
  }
  const onChangeSelectedHandler = (value: BlockedConfiguration[]): void => {
    setSelectedApps(value);
  }

  const handleAddClick = (): void => {
    setSelectedApps([...selectedApps, ...appsInput]);
    setAppsInput([]);
  }

  const handleAddWithDelay = (): void => {
    setIsLoading(true);
    setTimeout(() => {
      handleAddClick();
      setIsLoading(false);
    }, 500);
  };

  const onSaveHandler = (): void => {
    const selectedAppsWithScheduler = selectedApps.map((appData) => ({
      ...appData,
      applicationId: appData.id,
      schedule: blockTimings,
      context,
      configuredId,
      blockType: BlockType.APPS,
      isDeleted: false,
    }));

    if (isAdd && selectedApps.length) {
      dispatch(createBlockConfiguration({
        configurations: [...selectedAppsWithScheduler, ...existingApps]
      }));
    } else if (action === BlockActions.Edit && editBlockApps) {
      dispatch(editBlockConfiguration({
        actionId: selectedAppsWithScheduler[0]?.applicationId,
        configurations: [
          ...selectedAppsWithScheduler,
          ...existingApps.filter(app =>
            !selectedAppsWithScheduler.some(selectedApp => selectedApp.programApps === app.programApps)
          )
        ]
      }));
    }
  }

  useEffect(() => {
    if (
      blockAppCreateStatus === RequestStatus.Success ||
      blockAppEditStatus === RequestStatus.Success ||
      blockAppCreateStatus === RequestStatus.Failure ||
      blockAppEditStatus === RequestStatus.Failure
    ) {
      onSave();
    }
  }, [blockAppCreateStatus, blockAppEditStatus])

  const getBlockedTimings = (data: DaySchedule): string => {

    if (data?.interval?.length) {
      if (data.interval.some(interval => interval.start === DefaultTimings.start && interval.end === DefaultTimings.end)) {
        return BlockScheduleType.blocked24hrs;
      }
      if (data.interval.some(interval => interval.start === DefaultTimings.start && interval.end === DefaultTimings.start)) {
        return BlockScheduleType.disabled;
      }
      return data.interval
        .map(interval => `${interval.start || '-'} - ${interval.end || '-'}`)
        .join(', ');
    }
    return '-';
  };

  const handleOnEdit = (data: DaySchedule): void => {
    setDayToEdit(data);
    handleOpenScheduler();
  }
  const getActionColumn = (data: DaySchedule): React.ReactNode => (
    <IconButton
      aria-label="edit"
      onClick={() => handleOnEdit(data)}
    >
      <EditIcon />
    </IconButton>
  );

  const columns: ColumnData<DaySchedule>[] = [
    {
      label: t('settings.configuration.days'),
      size: 2,
      align: 'center' as const,
      value: (data: DaySchedule) => data.day,
    },
    {
      label: t('settings.configuration.timings'),
      size: 2,
      align: 'center' as const,
      value: (data: DaySchedule) => getBlockedTimings(data),
    },
    {
      label: t('data-type.table.action'),
      size: 1,
      align: 'center' as const,
      format: (data: DaySchedule) => getActionColumn(data),
    },
  ];

  const blockScheduleContent = {
    contents: blockTimings,
    currentPage: 1,
    numberOfEntries: 0,
  };

  return (
    <>
      <Box>
        <Grid container spacing={3} direction="column">
          {isAdd && (
            <Grid item>
              <Typography variant="subtitle1" fontWeight="bold">
                {
                  isAdd ? t('settings.configuration.applications-to-block')
                    : t('settings.configuration.application-to-edit')
                }
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <Autocomplete
                    sx={dropdownStyle}
                    multiple
                    disablePortal
                    id="program"
                    value={appsInput}
                    options={
                      programOptions
                        ?.map(toConfigurationBlockedApp)
                        ?.filter((option) => {
                          const isInSelectedApps = selectedApps?.some((selectedOption) => selectedOption.programApps === option.programApps);
                          const isInInputApps = appsInput?.some((inputOption) => inputOption.programApps === option.programApps);
                          return !isInSelectedApps && !isInInputApps;
                        }) ?? []
                    }
                    onChange={(_, data) => onChangeInputHandler(data)}
                    loading={programsListOptionsStatus === RequestStatus.InProgress}
                    getOptionLabel={({ programApps }: BlockedConfiguration) => programApps ?? ''}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          {option?.programApps}
                        </li>
                      );
                    }}
                    ChipProps={{ sx: chipStyle }}
                  />

                </Grid>
                {isAdd && (
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isLoading || appsInput?.length === 0}
                      onClick={handleAddWithDelay}
                    >
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : "ADD"}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {/* Application Selected */}
          <Grid item>
            <Typography variant="subtitle1" fontWeight="bold">
              {t('settings.resource.applications-selected')}
            </Typography>

            <Autocomplete
              sx={dropdownStyle}
              multiple
              disablePortal
              id="programSelected"
              value={selectedApps}
              disabled={action === BlockActions.Edit}
              options={[]}
              open={false}
              onChange={(_, data) => onChangeSelectedHandler(data)}
              getOptionLabel={({ programApps }: BlockedConfiguration) => programApps ?? ''}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option?.programApps}
                  </li>
                );
              }}
              ChipProps={{ sx: chipStyle }}
            />
          </Grid>

          {/* Blocking Hours */}
          <Grid item>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t('settings.configuration.blocking-hours')}
              </Typography>
              <Button
                disabled={selectedApps.length === 0}
                variant="contained"
                color="primary"
                onClick={handleEditAll}
              >
                {t('settings.configuration.edit-all')} 
              </Button>
            </Box>

            <Box sx={{ marginTop: '10px' }}>
              <DataTable<DaySchedule>
                isLoading={defaultConfigScheduleStatus === RequestStatus.InProgress}
                columns={columns}
                alignHeader={'center'}
                onPageChange={handleEmptyFunction}
                entriesPerPage={10}
                pagination={false}
                onChangeEntriesPerPage={handleEmptyFunction}
                {...blockScheduleContent}
              />
            </Box>
          </Grid>

          {/* Action Buttons */}
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOnCancelHandler}
                >
                  {t('common.actions.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={isAdd && selectedApps.length === 0}
                  variant="contained"
                  color="primary"
                  onClick={onSaveHandler}
                >
                  {
                    (
                      blockAppCreateStatus === RequestStatus.InProgress ||
                      blockAppEditStatus === RequestStatus.InProgress
                    )
                      ? <CircularProgress size={24} color="inherit" /> : t('common.actions.save')
                  }
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Modal
        title={t('settings.configuration.select-blocking-hours')}
        open={openSchedulerModal}
        maxWidth="md"
        fullWidth
        onClose={handleOpenManageApps}
        showCloseButton={false} 
      >
        <Box>
          <DayTimeScheduler
            dayToEdit={dayToEdit}
            blockedItems={selectedApps}
            onClose={handleOpenManageApps}
            onSave={handleOnSaveScheduler}
            defaultTimings={blockTimings ?? []}
          />
        </Box>
      </Modal>
    </>
  );
}
