import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Autocomplete, Button, CircularProgress, Grid, TextField, Theme, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    createApplications,
    editApplications,
    selectApplicationsError,
    selectCreateApplicationsStatus,
    selectEditApplicationsStatus,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { useTranslation } from 'react-i18next';
import { ApplicationsList } from 'src/models/administration/resourceData.models';
import { Actions } from 'src/models/roles.enum';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
import { DropdownOption, DropdownSelector } from 'src/components/dropdown-selector/DropdownSelector';
import { Platform } from 'src/models/platforms.enum';
import { RequestStatus } from 'src/models/requestStatus.enum';

export const textBoxStyle = {
    '& .MuiInputBase-root': {
        height: '40px',
        minHeight: '30px',
        padding: '0 8px',
        fontSize: '16px'
    }
};

export const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

export const autoCompleteStyle = {
    '& .MuiInputBase-root': {
        height: '50px',
        minHeight: '30px',
        padding: '0 8px',
    }
};

interface SelectedFieldProps {
    id?: string;
    selectedConfig: string[];
    onRemoveConfig: (categories: string[]) => void;
}

export const SelectedField = ({
    id = "selected",
    selectedConfig,
    onRemoveConfig,
}: SelectedFieldProps): React.ReactElement => {
    return (
        <Grid item>
            <Autocomplete
                sx={autoCompleteStyle}
                multiple
                disablePortal
                id={id}
                value={selectedConfig}
                options={selectedConfig}
                open={false}
                onChange={(_, data) => onRemoveConfig(data as string[])}
                getOptionLabel={(option) => option}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                ChipProps={{ sx: chipStyle }}
            />
        </Grid>
    );
};

interface ApplicationsListModalProps {
    action: Actions;
    application?: ApplicationsList;
    onSave: () => void;
    onCancel?: () => void;
}

export function ApplicationsListModal({
    action,
    application,
    onSave,
    onCancel
}: Readonly<ApplicationsListModalProps>): React.ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAdd = action === Actions.ADD;
    const [isLoading, setIsLoading] = useState(false);
    const [appInput, setAppInput] = useState<ApplicationsList>();
    const [selectedName, setSelectedName] = useState<string[]>([]);
    const [selectedAppsToAdd, setSelectedAppsToAdd] = useState<ApplicationsList[]>([]);
    const [isDuplicateApplication, setIsDuplicateApplication] = useState<boolean>(false);    
    const [selectedPlatforms, setSelectedPlatforms] = useState<Platform[]>([]);
    const appCreateStatus = useSelector(selectCreateApplicationsStatus);
    const appEditStatus = useSelector(selectEditApplicationsStatus);

    const appError = useSelector(selectApplicationsError);

    useEffect(() => {
        if (action === Actions.EDIT && application) {
            setAppInput(application);
            setSelectedPlatforms(application?.platform ?? []);
        }
    }, [application])

    const platformsOption: DropdownOption[] = Object.values(Platform).map((value) => {
        return {
            name: value,
            value,
        };
    }).filter(Boolean) as DropdownOption[];

    const onChangePlatformSelect = (value: unknown): void => {
        setSelectedPlatforms(value as Platform[]);
        setAppInput(
            {
                ...appInput,
                platform: value as Platform[],
            }
        )
    }

    const handleAddClick = (): void => {
        const updatedApps = [...selectedAppsToAdd, appInput || {} as ApplicationsList];
        setSelectedAppsToAdd(updatedApps);
        const updatedProgramNames = updatedApps
            .map(app => app?.programName)
            .filter((name): name is string => name !== undefined);
        setSelectedName(updatedProgramNames);
        setAppInput({});
        setSelectedPlatforms([]);
    };
    const handleAddWithDelay = (): void => {
        setIsLoading(true);
        setTimeout(() => {
            handleAddClick();
            setIsLoading(false);
        }, 500);
    };

    const onChangeAddApplication = (value: string): void => {
        setAppInput(
            {
                ...appInput,
                programName: value,
            }
        )
        const isDuplicate = selectedAppsToAdd.some(
            (data) => data.programName === value
        );
        setIsDuplicateApplication(isDuplicate);
    };

    const handleRemoveApplication = (appNames: string[]): void => {

        const updatedApps = selectedAppsToAdd.filter((program) =>
            appNames.includes(program?.programName ?? "")
        );
        setSelectedAppsToAdd(updatedApps);
        setSelectedName(appNames);
    };

    const onSaveHandler = (): void => {
        if (isAdd && selectedAppsToAdd.length) {
            dispatch(createApplications({ applications: selectedAppsToAdd }));
        } else if (action === Actions.EDIT && appInput) {
            dispatch(editApplications(appInput));

        }
    };

    useEffect(() => {
        if (appCreateStatus === RequestStatus.Success ||
            appEditStatus === RequestStatus.Success) {
            onSave();
        }
    }, [appCreateStatus, appEditStatus])

    return (
        <Box>
            <Grid container sx={{ justifyContent: 'center' }} spacing={3} direction="column">
                <Grid item>
                    <Typography variant="subtitle1" fontWeight="bold">
                        {isAdd ? t('settings.resource.add-applications') : t('settings.resource.edit-application')}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <TextField
                                sx={textBoxStyle}
                                name="Application"
                                value={appInput?.programName ?? ''}
                                onChange={(event) => onChangeAddApplication(event.target.value as string)}
                                autoComplete="off"
                                error={handleError(appError, 'Application')}
                                helperText={handleErrorText(appError, 'Application')}
                                fullWidth
                            />
                        </Grid>
                        {isAdd && (
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddWithDelay}
                                    disabled={
                                        isLoading ||
                                        !(appInput?.programName && appInput?.platform?.length) ||
                                        isDuplicateApplication
                                    }
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : "ADD"}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item>
                    <Typography variant="subtitle1" fontWeight="bold">
                        {t('common.nouns.platform')}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <DropdownSelector
                                sx={{ height: '40px' }}
                                options={platformsOption}
                                multiple
                                fullWidth
                                disabled={false}
                                checkBoxSelection
                                value={selectedPlatforms}
                                onChange={onChangePlatformSelect}
                                inputProps={{
                                    sx: {
                                        backgroundColor: (theme: Theme) => theme.palette.common.white,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* Application Selected */}
                {isAdd && (
                    <Grid item>
                        <Typography variant="subtitle1" fontWeight="bold">
                            {t('settings.resource.applications-selected')}
                        </Typography>
                        <SelectedField
                            id="programName"
                            selectedConfig={selectedName}
                            onRemoveConfig={handleRemoveApplication}
                        />
                    </Grid>
                )}

                {/* Action Buttons */}
                <Grid item>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onCancel}
                            >
                                {t('common.actions.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={isAdd && selectedAppsToAdd.length === 0}
                                variant="contained"
                                color="primary"
                                onClick={onSaveHandler}
                            >
                                {
                                    (
                                        appCreateStatus === RequestStatus.InProgress ||
                                        appEditStatus === RequestStatus.InProgress
                                    )
                                        ? <CircularProgress size={24} color="inherit" /> : t('common.actions.save')
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}