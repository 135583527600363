import { Button, Menu, MenuItem, Theme, useTheme } from '@mui/material';
import React from 'react';
import { noop } from 'src/utils/common';

const menuListStyle = {
    borderWidth: '1px 0 0 0',
    borderStyle: 'solid',
    padding: 0,
    borderColor: (theme: Theme) => theme.palette.common.goldYellow,
    background: (theme: Theme) => theme.palette.common.darkGrey,
};

export interface MenuOptionItem {
    label?: string;
    onClick?: () => void;   
    visible?:boolean;
    path?: boolean;
    isSettings?: boolean;
}
export interface MenuOptionProps extends MenuOptionItem {
    options?: MenuOptionItem[];
}

export function MenuOption
({ label, onClick = noop, options = [], path, isSettings = false }: Readonly<MenuOptionProps>): JSX.Element
{
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void =>
    {
        onClick();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void =>
    {
        setAnchorEl(null);
    };
    const getColorValue = ( isSettings: boolean, path?: boolean): string => {
        if (path) return theme.palette.common.goldYellow;
        if (isSettings) return theme.palette.common.black;
        return theme.palette.common.white;
        };
    const colorValue = getColorValue(isSettings, path);
    return (
        <div>
            { (!options
            || options?.length === 0
            || options?.some(option => option.visible !== false)) && (
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        color: colorValue,
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        fontSize: '0.75rem',
                        '&:hover, &:active': {
                            color: (theme: Theme) =>
                                theme.palette.common.goldYellow,
                        },
                    }}
                >
                    {label}
                </Button>
            )}
            {
                options?.some(option => option.visible !== false) && (
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            sx: menuListStyle,
                        }}
                    >
                        {
                            options.map(({ visible = true, label, onClick = noop, path }) =>
                            {
                                return (
                                    visible && (
                                        <MenuItem
                                            key={label}
                                            onClick={onClick}
                                            sx={{
                                                width: '100%',
                                                maxWidth: 360,
                                                color: (theme: Theme) =>
                                                    path
                                                        ? theme.palette.common.goldYellow
                                                        : theme.palette.common.white,
                                                padding: '1rem',
                                                justifyContent: 'center',
                                                borderWidth: '0 1px 1px 1px',
                                                borderStyle: 'solid',
                                                borderColor: (theme:Theme)=>
                                                    theme.palette.common.goldYellow,
                                                '&:hover': {
                                                    color: (theme:Theme)=>
                                                        theme.palette.common.goldYellow,
                                                },
                                            }}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                );
                            })
                        }
                    </Menu>
                )
            }
        </div>
    );
}
