import { Agency } from './agencyData.model';
import { Client, ClientConfig } from './clientData.model';
import { Device } from './deviceData.model';
import { User } from 'src/models/userModels';

export enum ConfigurationOwner {
    Default = 'default',
    Agency = 'agency',
    Officer = 'officer',
    Client = 'client',
    Device = 'device',
}

export interface ConfigurationParams {
    ownerId: string;
    ownerType: ConfigurationOwner;
}

export interface ParentConfigurationParams {
    child: Agency | Client | Device | User | ClientConfig
    ownerType: ConfigurationOwner
}

export interface ServiceConfiguration {
    app: string;
    captureInterval: number;
    syncInterval: number;
    syncRate: number;
    enabled: boolean;
}

export interface ConfigurationBlockedApp {
    name: string;
    packageName?: string;
    path?: string;
    processName?: string;
    programName:string;
    agencyId?: string;
    deviceId?: string;
    clientId?: string;
    officerId?: string;
}

export interface ConfigurationDetail {
    services: ServiceConfiguration[];
    blockedApps: ConfigurationBlockedApp[];
    blockedUrls: string[];
    blockUninstall: boolean;
    blockAccessSetting: boolean;
    disableConfig: boolean;
    isLogCapture: boolean;
    permissionScreenAccess?:boolean;
    isUninstallNotification: boolean;
    ownerType?: ConfigurationOwner;
    id?: string;
}

export interface Configuration extends ConfigurationDetail {
    id: string
    ownerType: ConfigurationOwner
    ownerId: string
    agencyId?: string
    deviceId?: string
    clientId?: string
    officerId?: string
}
