import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    AlertProps,
    Box,
    Button,
    CircularProgress,
    Grid,
    Snackbar,
    SnackbarProps,
    Stack,
    Theme,
    Typography,
} from '@mui/material';
import { ProgramSelector } from '../program-selector/ProgramSelector';
import {
    ConfigurationBlockedApp,
    ConfigurationDetail,
    ServiceConfiguration,
} from 'src/models/administration/configurationData.model';
import { URLSelector } from '../url-selector/URLSelector';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { sortByKey } from 'src/utils/common';
import {
    getPartialConfiguration,
    resetSaveConfigurationStatus,
    selectParentConfiguration,
    selectPartialConfiguration,
    selectPartialConfigurationStatus,
    updateConfiguration,
    selectConfigurationErrorContext,
    resetConfigurationErrorContext,
} from 'src/state/administration/administrationSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    DeviceServiceConfigurator,
} from '../device-service-configurator/DeviceServiceConfigurator';
import { INITIALCONFIGURATION_ID } from 'src/utils/environment';
import { Role } from 'src/models/roles.enum';
import { userIsInRole } from 'src/utils/roleUtils';
import { DataTypeConfig } from 'src/models/dataType.enum';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { DeviceConfigurationSettings } from 'src/models/deviceConfigurationSettings.enum';
import { ConfigurationLevel } from 'src/models/administration/blockConfigurationData.model';

const rootStyle = {
    width: '100%',
    height: '1.75em',
    fontSize: '2rem',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '30px',
    color: (theme: Theme) => theme.palette.common.lightGrey,
};

export interface ServiceConfigurationItem extends ServiceConfiguration {
    id?: '62ba59ca3b52833cc0cad2dc'
    label: string
    override: boolean
}
const initialConfiguration: ConfigurationDetail = {
    services: [],
    blockedApps: [],
    blockedUrls: [],
    blockUninstall: true,
    blockAccessSetting: true,
    disableConfig: false,
    isLogCapture: false,
    isUninstallNotification: true,
    permissionScreenAccess: true,
};
export interface ServiceConfiguratorProps {
    configuration: ServiceConfigurationItem
    disableConfig: boolean
    onClose?: () => void
    onChange?: (services: ServiceConfiguration[], isValid: boolean) => void;
}
interface ServiceItemsMap {
    [key: string]: ServiceConfigurationItem
}
function serviceConfigurationToConfigurationItem(
    conf: ServiceConfiguration,
    override: boolean
): ServiceConfigurationItem {
    return {
        id: INITIALCONFIGURATION_ID,
        app: conf.app,
        label: conf.app,
        captureInterval: conf.captureInterval,
        syncInterval: conf.syncInterval,
        syncRate: conf.syncRate,
        enabled: conf.enabled,
        override,
    };
}
function configurationItemToServiceConfiguration({
    app,
    captureInterval,
    syncInterval,
    syncRate,
    enabled,
}: ServiceConfigurationItem): ServiceConfiguration {
    return {
        app,
        captureInterval,
        syncInterval,
        syncRate,
        enabled,
    };
}
function appConfigurerPropsMapToServiceConfiguration(map: ServiceItemsMap): ServiceConfiguration[] {
    return Object.values(map)
    .filter((configurationItem) => configurationItem.override)
    .reduce<ServiceConfiguration[]>((acc, configurationItem) => ([
            ...acc,
            configurationItemToServiceConfiguration(configurationItem),
        ]), []);
    }

    function getConfigurerProps(
    ownedConfiguration: ServiceConfiguration[],
    inheritedConfiguration: ServiceConfiguration[]
): ServiceItemsMap {
    const propsMap = inheritedConfiguration.reduce<ServiceItemsMap>(
        (acc, conf) => {
            return {
                ...acc,
                [conf.app]: serviceConfigurationToConfigurationItem(conf, false),
            };
        },
        {}
    );

    return ownedConfiguration.reduce<ServiceItemsMap>((acc, conf) => {
        return {
            ...acc,
            [conf.app]: serviceConfigurationToConfigurationItem(conf, true),
        };
    }, propsMap);
}
export const initialConfigurations: ServiceConfigurationItem = {
    label: '',
    override: false,
    app: '',
    captureInterval: 0,
    syncInterval: 0,
    syncRate: 0,
    enabled: false,
    id: INITIALCONFIGURATION_ID,
};

interface DeviceConfigurationProps {
    type: DeviceConfigurationSettings;
}

export function DeviceConfigurationManagement({ type }: DeviceConfigurationProps): React.ReactElement {
    const { t } = useTranslation();
    const disableConfig = false;    
    const [invalidConfs, setInvalidConfs] = useState<string[]>([]);
    const [notificationState, setNotificationState] =
        useState<SnackbarProps & AlertProps>();
    const dispatch = useDispatch();
    const partialConfiguration = useSelector(selectPartialConfiguration);
    const [configurationId, setConfigurationId] = useState<string>('');
    const parentConfiguration = useSelector(selectParentConfiguration);
    const [appConfiguration, setAppConfiguration] =
    useState<ConfigurationDetail>(initialConfiguration);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [changed, setChanged] = useState<boolean>(false);
    const [ownServices, setOwnServices] = useState<ServiceConfiguration[]>([]);
    const [fallbackServices, setFallbackServices] = useState<ServiceConfiguration[]>([]);
    const deviceConfigurationStatus = useSelector(selectPartialConfigurationStatus);
    const isConfigurationError = useSelector(selectConfigurationErrorContext);
    const filteredFallbackServices = fallbackServices
    .map((config) => {
        if (config.app === DataTypeConfig.SEARCHES) {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) => {
            return config.app !== DataTypeConfig.WEBSITES
            && config.app !== DataTypeConfig.SEARCHES;
        });
        const filteredOwedServices = ownServices
        .map((config) => {
            if (config.app === DataTypeConfig.SEARCHES) {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) => {
            return config.app !== DataTypeConfig.WEBSITES
                && config.app !== DataTypeConfig.SEARCHES;
        });
    const [appConfigurerItemsMap, setAppConfigurerItemsMap] = useState<ServiceItemsMap>(
        getConfigurerProps(filteredOwedServices, filteredFallbackServices)
    );
    
    useEffect(() => {
        if (
            partialConfiguration &&
            partialConfiguration.id === initialConfigurations.id
        ) {
            setConfigurationId(initialConfigurations.id);

        }
    }, [initialConfigurations, partialConfiguration]);

    useEffect(() => {
        if (
            partialConfiguration &&
            partialConfiguration.ownerId === undefined
        ) {
            setOwnServices(partialConfiguration?.services ?? []);
            setFallbackServices(partialConfiguration?.services ?? []);
            setAppConfiguration(partialConfiguration ?? partialConfiguration);
        }
        else {
            setOwnServices([]);
            setFallbackServices([]);
        }
    }, [partialConfiguration, parentConfiguration]);

    useEffect(() => {
        setAppConfigurerItemsMap(
            getConfigurerProps(filteredOwedServices, filteredFallbackServices)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownServices, fallbackServices]);

    useEffect(() => {
        if (
            partialConfiguration &&
            partialConfiguration.id === initialConfigurations.id
        ) {
            setConfigurationId(initialConfigurations.id);

        }
    }, [initialConfigurations, partialConfiguration]);

    useEffect(() => {
        if (initialConfigurations.id) {
            dispatch(getPartialConfiguration(initialConfigurations.id));
        }
    }, [initialConfigurations, parentConfiguration, dispatch]);

    const sortedConfigs = sortByKey(Object.values(appConfigurerItemsMap), 'app');
    const filteredConfigs = sortedConfigs
        .map((config) => {
            if (config.app === DataTypeConfig.SEARCHES) {
                return {
                    ...config,
                    app: DataTypeConfig.SEARCHES_WEBSITES,
                    label: DataTypeConfig.SEARCHES_WEBSITES,
                };
            }
            return config;
        })
        .filter((config) => {
            return config.app !== DataTypeConfig.WEBSITES
                && config.app !== DataTypeConfig.SEARCHES;
        });
    function onConfigurationChange(item: ServiceConfigurationItem): void {
        const { app, override, captureInterval, syncInterval } = item;
        let newAppItemsMap = { ...appConfigurerItemsMap };
        if (override) {
            newAppItemsMap = {
                ...newAppItemsMap,
                [app]: item,
            };
        }
        else {
            const fallbackFound = filteredFallbackServices.find((service) => service.app === app);
            if (fallbackFound) {
                newAppItemsMap = {
                    ...newAppItemsMap,
                    [app]: serviceConfigurationToConfigurationItem(fallbackFound, false),
                };
            }
        }
        setAppConfigurerItemsMap(newAppItemsMap);
        if ((captureInterval && syncInterval) || !override) {
            setInvalidConfs((configs) => configs.filter((config) => config !== app));
        }
        else {
            setInvalidConfs((configs) => [...configs, app]);
        }
        const services = appConfigurerPropsMapToServiceConfiguration(newAppItemsMap);
        const isValid = invalidConfs.length === 0;
        onServicesChange(services, isValid);
    }
    function onServicesChange(services: ServiceConfiguration[], isValid: boolean): void {
        setAppConfiguration((current) => ({
            ...current,
            services,
        }));
        setIsValid(isValid);

        setChanged(true);
    }

    function onProgramSelectionChange(programs: ConfigurationBlockedApp[]): void {
        setAppConfiguration((current) => ({
            ...current,
            blockedApps: programs,
        }));

        setChanged(true);
    }

    function onURLSelectionChange(urls: string[]): void {
        setAppConfiguration((current) => ({
            ...current,
            blockedUrls: urls,
        }));
        setChanged(true);
    }

    function onToggleChange(fieldname: keyof ConfigurationDetail):
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void {
        return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            const newConf = { ...appConfiguration, [fieldname]: checked };
            setAppConfiguration(newConf);
            setChanged(true);
        };
    }
    const onCloseNotification = (): void => {
        setNotificationState({ ...notificationState, open: false });
        dispatch(resetSaveConfigurationStatus());
        dispatch(resetConfigurationErrorContext());
    };
    useEffect(() => {
        if (isConfigurationError) {
            setNotificationState({
                open: true,
                message: t('common.messages.something-wrong'),
                severity: 'error',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfigurationError]);

    function onSaveHandler(): void {
        onSave(appConfiguration);
        setNotificationState({
            open: true,
            severity: 'success',
            message: 'Device configuration saved successfully',
        });
        setTimeout(onCloseNotification, 6000);
    }
    function onSave(configurationDetail: ConfigurationDetail): void {
        if (configurationId) {
            partialConfiguration && dispatch(
                updateConfiguration({
                    ...partialConfiguration,
                    ...configurationDetail,
                })
            );
        }
    }
    return (
        deviceConfigurationStatus === RequestStatus.InProgress ? (
            <CircularProgress sx={{ margin: '4rem calc((100% - 40px) / 2)' }} />
        ) : (
            <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <Box
                    sx={{ flexGrow: 1, padding: '1rem' }}>
                    <Grid container rowSpacing={5} direction="column">
                        {(type === DeviceConfigurationSettings.ConfigurationServices) && (
                            <Grid item md={12}>
                                <Grid container rowSpacing={1} columnSpacing={'5%'}>
                                    {filteredConfigs.map(
                                        (
                                            selectedConfiguration: ServiceConfigurationItem
                                        ) => (
                                            <React.Fragment
                                                key={selectedConfiguration.label}
                                            >
                                                <Stack
                                                    direction="row"
                                                    sx={rootStyle}
                                                >
                                                    <Grid
                                                        container
                                                        rowSpacing={1}
                                                        columnSpacing={'1%'}
                                                    >
                                                        <DeviceServiceConfigurator
                                                            selectedConfiguration={selectedConfiguration}
                                                            onChange={onConfigurationChange}
                                                            disableConfig={disableConfig}
                                                        />
                                                    </Grid>
                                                </Stack>
                                            </React.Fragment>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {(type === DeviceConfigurationSettings.BlockApps) && (
                            <Grid item md={12}>
                                <Grid container rowSpacing={5} columnSpacing={'5%'}>
                                    <Grid item xs={12} lg={12}>
                                            <ProgramSelector
                                                context={ConfigurationLevel.DEFAULT}
                                                configuredId={initialConfigurations.id ?? ''} 
                                            />
                                        </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {(type === DeviceConfigurationSettings.BlockUrls) && (
                            <Grid item md={12}>
                                <Grid container rowSpacing={5} columnSpacing={'5%'}>
                                    <Grid item xs={12} lg={12}>
                                        <URLSelector
                                            ownBlockedUrls={appConfiguration.blockedUrls}
                                            isConfigurationExists={true}
                                            onChange={onURLSelectionChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {(type === DeviceConfigurationSettings.DeviceAccessSettings) && (
                            <Grid item md={12}>
                                <Grid container rowSpacing={5} columnSpacing={'5%'}>
                                    <Grid item xs={12} lg={6}>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette.common.lightGrey,
                                                fontSize: '1.25rem',
                                            }}
                                        >
                                            {t('settings.block-uninstall')}
                                        </Typography>
                                        <ToggleSwitch
                                            sx={{
                                                m: 1,
                                            }}
                                            checked={appConfiguration.blockUninstall}
                                            onChange={onToggleChange('blockUninstall')}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette.common.lightGrey,
                                                fontSize: '1.25rem',
                                            }}
                                        >
                                            {t('settings.block-access-settings')}
                                        </Typography>
                                        <ToggleSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                                appConfiguration.blockAccessSetting
                                            }
                                            onChange={onToggleChange(
                                                'blockAccessSetting'
                                            )}
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                color: (theme) => theme.palette.common.lightGrey,
                                                fontSize: '1.25rem',
                                            }}
                                        >
                                            {t('devices.disabled-configuration')}
                                        </Typography>
                                        <ToggleSwitch
                                            sx={{ m: 1 }}
                                            disabled={false}
                                            checked={
                                                appConfiguration.disableConfig
                                            }
                                            onChange={onToggleChange(
                                                'disableConfig'
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}

                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette.common.lightGrey,
                                                fontSize: '1.25rem',
                                            }}
                                        >
                                            {t('settings.permission-screen')}
                                        </Typography>
                                        <ToggleSwitch
                                            sx={{ m: 1 }}
                                            checked={
                                                appConfiguration.permissionScreenAccess
                                            }
                                            onChange={onToggleChange(
                                                'permissionScreenAccess'
                                            )}
                                            disabled={false}
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            gap="1em"
                            sx={{ padding: '1em' }}
                        >
                            <>
                                {
                                    (type === DeviceConfigurationSettings.BlockApps)
                                        ? null
                                        : (
                                            <Button
                                                variant="contained"
                                                disabled={!(changed && isValid) ||
                                                    (
                                                        userIsInRole([Role.Officer, Role.OfficerSupervisor]))}
                                                onClick={onSaveHandler}
                                            >
                                                {t('common.actions.save')}
                                            </Button>
                                        )
                                }
                                <Snackbar
                                    autoHideDuration={6000}
                                    open={notificationState?.open}
                                    onClose={onCloseNotification}
                                >
                                    <Alert severity={notificationState?.severity}>
                                        {notificationState?.message}
                                    </Alert>
                                </Snackbar>
                            </>
                        </Stack>
                </Box>
            </Box>
        )
    );
}
