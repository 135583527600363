import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Button, CircularProgress, Grid, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    createCategories,
    editCategory,
    selectCategoryError,
    selectCreateCategoriesStatus,
    selectEditCategoryStatus,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { useTranslation } from 'react-i18next';
import { CategoriesList } from 'src/models/administration/resourceData.models';
import { Actions } from 'src/models/roles.enum';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { SelectedField, textBoxStyle } from '../applications-list-table/ApplicationsListModal';

interface CategoriesListModalProps {
    action: Actions;
    category?: CategoriesList;
    onSave: () => void;
    onCancel?: () => void;
}

export function CategoriesListModal({
    action,
    category,
    onSave,
    onCancel
}: Readonly<CategoriesListModalProps>): React.ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAdd = action === Actions.ADD;
    const [isLoading, setIsLoading] = useState(false);
    const [categoryInput, setCategoryInput] = useState<CategoriesList>();
    const [selectedName, setSelectedName] = useState<string[]>([]);
    const [selectedCategoryToAdd, setSelectedCategoryToAdd] = useState<CategoriesList[]>([]);
    const [isDuplicateCategory, setIsDuplicateCategory] = useState<boolean>(false);
    const categoryCreateStatus = useSelector(selectCreateCategoriesStatus);
    const categoryEditStatus = useSelector(selectEditCategoryStatus);
    const categoryError = useSelector(selectCategoryError);

    useEffect(() => {
        if (action === Actions.EDIT && category) {
            setCategoryInput(category);
        }
    }, [category])

    const handleAddClick = (): void => {
        const updatedCategories = [...selectedCategoryToAdd, categoryInput || {} as CategoriesList];
        setSelectedCategoryToAdd(updatedCategories);
        const updatedCategoryNames = updatedCategories
            .map(data => data?.category)
            .filter((name): name is string => name !== undefined);
        setSelectedName(updatedCategoryNames);
        setCategoryInput({});
    };
    const handleAddWithDelay = (): void => {
        setIsLoading(true);
        setTimeout(() => {
            handleAddClick();
            setIsLoading(false);
        }, 500);
    };

    const onChangeAddCategory = (value: string): void => {
        setCategoryInput(
            {
                ...categoryInput,
                category: value,
            }
        )
        const isDuplicate = selectedCategoryToAdd.some(
            (data) => data.category === value
        );
        setIsDuplicateCategory(isDuplicate);
    };

    const handleRemoveCategory = (categoryNames: string[]): void => {

        const updatedCategories = selectedCategoryToAdd.filter((data) =>
            categoryNames.includes(data?.category ?? "")
        );
        setSelectedCategoryToAdd(updatedCategories);
        setSelectedName(categoryNames);
    };

    const onSaveHandler = (): void => {
        if (isAdd && selectedCategoryToAdd.length) {
            dispatch(createCategories({ categories: selectedCategoryToAdd }));
        } else if (action === Actions.EDIT && categoryInput) {
            dispatch(editCategory(categoryInput));

        }
    };

    useEffect(() => {
        if (categoryCreateStatus === RequestStatus.Success ||
            categoryEditStatus === RequestStatus.Success) {
            onSave();
        }
    }, [categoryCreateStatus, categoryEditStatus])

    return (
        <Box>
            <Grid container sx={{ justifyContent: 'center' }} spacing={3} direction="column">
                <Grid item>
                    <Typography variant="subtitle1" fontWeight="bold">
                        {isAdd ? t('settings.resource.add-categories') : t('settings.resource.edit-category')}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <TextField
                                sx={textBoxStyle}
                                name="category"
                                value={categoryInput?.category ?? ''}
                                onChange={(event) => onChangeAddCategory(event.target.value as string)}
                                autoComplete="off"
                                error={handleError(categoryError, 'category')}
                                helperText={handleErrorText(categoryError, 'category')}
                                fullWidth
                            />
                        </Grid>
                        {isAdd && (
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddWithDelay}
                                    disabled={isLoading || !(categoryInput?.category) || isDuplicateCategory}
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : "ADD"}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {/* Category Selected */}
                {isAdd && (
                    <Grid item>
                        <Typography variant="subtitle1" fontWeight="bold">
                            {t('settings.resource.categories-selected')}
                        </Typography>
                        <SelectedField
                            id="category"
                            selectedConfig={selectedName}
                            onRemoveConfig={handleRemoveCategory}
                        />
                    </Grid>
                )}

                {/* Action Buttons */}
                <Grid item>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onCancel}
                            >
                                {t('common.actions.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={isAdd && selectedCategoryToAdd.length === 0}
                                variant="contained"
                                color="primary"
                                onClick={onSaveHandler}
                            >
                                {
                                    (
                                        categoryCreateStatus === RequestStatus.InProgress ||
                                        categoryEditStatus === RequestStatus.InProgress
                                    )
                                        ? <CircularProgress size={24} color="inherit" /> : t('common.actions.save')
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}