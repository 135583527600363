import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { User } from 'src/models/userModels';
import {
    clearUser,
    getUsers,
    selectUserFilters,
    selectUsers,
    selectUsersStatus,
    setUser,
    selectAdminFilters,
    selectUserUpdateStatus,
    selectUserDeleteStatus,
} from 'src/state/administration/administrationSlice';
import { generatePath, useNavigate, useLocation } from 'react-router-dom';
import { USER_MANAGEMENT_CREATE, USER_MANAGEMENT_EDIT } from 'src/utils/routes';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Role } from 'src/models/roles.enum';
import { noop } from 'src/utils/common';
import BuildIcon from '@mui/icons-material/Build';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Officer } from 'src/models/administration/officerData.model';
import { AdminSettingSearchFilter } from '../admin-setting-search-filter/AdminSettingSearchBar';
import { CreateButton } from 'src/components/top-bar/CreateButton';
import { TopBar } from 'src/components/top-bar/TopBar';

export interface UserTableProps {
    roles?: Role[]
    onEdit?: (user: User) => void
    onConfig?: (user: User) => void
    onAlert?: (user: Officer) => void
    enableCreate?: boolean
}

export function UsersTable({
    roles,
    onEdit = noop,
    onConfig = noop,
    onAlert = noop,
    enableCreate = false,
}: Readonly<UserTableProps>): React.ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector(selectUsers);
    const filters = useSelector(selectUserFilters);
    const status = useSelector(selectUsersStatus);
    const userUpdateStatus = useSelector(selectUserUpdateStatus);
    const userDeleteStatus = useSelector(selectUserDeleteStatus);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const adminFilter = useSelector(selectAdminFilters);
    const location = useLocation();
    const isRestricted = location.pathname === '/administration/settings';
    const userUpdateSuccess = userUpdateStatus === RequestStatus.Success;
    const userDeleteStatusSuccess = userDeleteStatus === RequestStatus.Success;

    useEffect(() => {
        setTimeout(() => dispatch(clearUser()), 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(
            getUsers({
                pageNumber: 1,
                entriesPerPage,
                ...{ ...filters, role: roles, ...adminFilter },
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,
        entriesPerPage,
        filters,
        adminFilter,
        userUpdateSuccess,
        userDeleteStatusSuccess]);

    function onPageChange(pageNumber: number): void {
        dispatch(
            getUsers({
                pageNumber,
                entriesPerPage,
                ...{ ...filters, role: roles, ...adminFilter },
            })
        );
    }

    const getStatusColumn = (data: User): React.ReactNode => {
        const textStatus: string = data.enabled
            ? t('common.status.active')
            : t('common.status.inactive');

        return (
            <Box
                sx={{
                    textTransform: 'capitalize',
                    color: (theme) =>
                        data.enabled
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                }}
            >
                {textStatus}
            </Box>
        );
    };
    const getActionColumn = (data: User): React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={() => {
                if (onEdit.length) {
                    onEdit(data);
                }
                else {
                    dispatch(setUser(data));
                    navigate(
                        generatePath(USER_MANAGEMENT_EDIT, {
                            id: data.id as string,
                        })
                    );
                }
            }}
        >
            <EditIcon />
        </IconButton>
    );
    const getConfigColumn = (user: User): React.ReactNode => (
        <Button onClick={() => onConfig(user)}>
            <BuildIcon />
        </Button>
    );
    const getAlertwordColumn = (user: Officer): React.ReactNode => (
        <Button onClick={() => onAlert(user)}>
            <ListAltIcon />
        </Button>
    );

    const columns: ColumnData<User>[] = [
        {
            label: t('user.firstname'),
            size: 3,
            value: (data: User) => data.firstName,
        },
        {
            label: t('user.lastname'),
            size: 3,
            value: (data: User) => data.lastName,
        },
        {
            label: t('user.email'),
            size: 3,
            value: (data: User) => data.email,
        },
        {
            label: t('user.role'),
            size: 2,
            value: (data: User) => data.role,
        },
        {
            label: t('user.status'),
            size: 2,
            value: (data: User) =>
                data.enabled
                    ? t('common.status.active')
                    : t('common.status.inactive'),
            format: (data: User) => getStatusColumn(data),
        },
        {
            label: t('common.actions.edit'),
            size: 1,
            align: 'center' as const,
            format: (data: User) => getActionColumn(data),
        },
        !isRestricted && {
            label: t('common.nouns.configuration'),
            size: 1,
            align: 'center',
            format: (user: User) => getConfigColumn(user),
        },
        !isRestricted && {
            label: t('data-type.details.alert-words'),
            size: 1,
            align: 'center',
            format: (user: Officer) => getAlertwordColumn(user),
        },
    ].filter(Boolean) as ColumnData<User>[];

    return (
        <>
            {enableCreate && (
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                >
                    <Box sx={{ mt: 2.5, mr: 3 }}>
                        <Grid
                            item
                            md={3}
                            sm={12}
                            xs={12}
                        >
                            <AdminSettingSearchFilter />
                        </Grid>
                    </Box>
                    <TopBar
                        buttons={[
                            {
                                text: (
                                    <CreateButton
                                        width="140px"
                                        label={t('user.create-new-user')}
                                    />
                                ),
                                icon: <AddCircleIcon />,
                                onClick: () => navigate(USER_MANAGEMENT_CREATE),
                            },
                        ]}
                    />
                </Stack>
            )}
            <DataTable<User>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...users}
            />
        </>

    );
}
