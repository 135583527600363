import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Header } from 'src/templates/header/Header';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import {
    LandingPageAlertsTable,
} from 'src/templates/landing-page-alerts-table/LandingPageAlertsTable';
import { useTranslation } from 'react-i18next';
import TableExporterProvider from 'src/components/table-exporter/TableExporterProvider';
import { CircularProgress, Grid } from '@mui/material';
import { DataTypeProfileSelect } from 'src/templates/data-type-profile/DataTypeProfileSelect';
import { DataTypeFlagFilter } from 'src/templates/data-type-flag-filter/DataTypeFlagFilter';
import { DataTypeSearchFilter } from 'src/templates/data-type-search-filter/DataTypeSearchFilter';
import PhonelinkEraseOutlinedIcon from '@mui/icons-material/PhonelinkEraseOutlined';
import {
    getActiveDevices,
    getInActiveDevices,
    resetactivedatasearchValue,
    selectActiveData,
    selectActiveDataStatus,
    selectAlerts,
    selectAlertsFilter,
    selectAlertsStatus,
    selectGridAlerts,
    selectInActiveData,
    selectInActiveDataStatus,
    selectRecordCount,
    selectRecordStatus,
    selectRedFlagCount,
    selectRedFlagStatus,
} from 'src/state/captures/capturesSlice';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { DataTypeSelectAlerts } from 'src/templates/data-type-select-alerts/DataTypeSelectAlerts';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    DataTypeAlertsFilters,
} from 'src/templates/data-type-alerts-filters/DataTypeAlertsFilters';
import { KEYWORDS } from 'src/models/alertsType.enum';
import TableDownloadBar from 'src/components/table-download-bar/TableDownloadBar';
import { Modal } from 'src/components/modal/Modal';
import { NonReportersListModal } from 'src/templates/non-reporters/NonReportersListModal';
import { ActiveReportersListModal } from 'src/templates/active-reporters/ActiveReportersListModel';

export function LandingPage(): JSX.Element
{
    const { t } = useTranslation();
    const alertsFilter = useSelector(selectAlertsFilter);
    const alertsData = useSelector(selectAlerts);
    const alertsGridData = useSelector(selectGridAlerts);
    const alertsStatus = useSelector(selectAlertsStatus);

    const activeData = useSelector(selectActiveData);
    const activeDatatatus = useSelector(selectActiveDataStatus);
    const inactiveData = useSelector(selectInActiveData);
    const inactiveDataStatus = useSelector(selectInActiveDataStatus);
    const redflagValue = useSelector(selectRedFlagCount);
    const redflagValueStatus = useSelector(selectRedFlagStatus);
    const recordValue = useSelector(selectRecordCount);
    const recordValueStatus = useSelector(selectRecordStatus);

    const dispatch = useDispatch();
    const [activeCount, setActiveCount] = useState(0);
    const [inActiveCount, setInActiveCount] = useState(0);
    const [redFlagCount, setRedFlagCount] = useState(0);
    const [recordCount, setRecordCount] = useState(0);
    const [alertCount, setAlertCount] = useState(0);

    const [inActiveClicked, setInActiveClicked] = useState(false);
    const [activeClicked, setActiveClicked] = useState(false);


    const handleCardClick = (cardType: string): void =>
    {

        if (cardType === 'active')
        {
            setActiveClicked(true);
        }
        else
        {
            setInActiveClicked(true);
            dispatch(getInActiveDevices({
                agencyId: alertsFilter.agency,
                officerId: alertsFilter.officer,
                clientId: alertsFilter.client,
                pageNumber: 1,
                entriesPerPage: 10,
                searchValue: '',
                details: true,
            }));
        }

    };
    const closeModal = (): void =>
    {
        setActiveClicked(false);
        setInActiveClicked(false);
        dispatch(getActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage: 10,
            searchValue: '',
            platformValue: undefined,
        }));

        dispatch(getInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage: 10,
            searchValue: '',
            details: false,
            platformValue: undefined,
        }));

        setTimeout(() =>
        {
            dispatch(resetactivedatasearchValue());
        }, 1000);
    };

    const alertGridView = (alertsFilter.isGridView === true &&
        (alertsFilter?.alertsdatatype === 'MMS' ||
            alertsFilter?.alertsdatatype === 'Photos' ||
            alertsFilter?.alertsdatatype === 'Screen Captures' ||
            alertsFilter?.alertsdatatype === 'Screenshots')
    );

    useEffect(() =>
    {
        setActiveCount(activeData?.numberOfEntries ? activeData.numberOfEntries : 0);
        setInActiveCount(inactiveData?.numberOfEntries ? inactiveData.numberOfEntries : 0);
        setRedFlagCount(redflagValue?.redflagCount ? redflagValue.redflagCount : 0);
        setRecordCount(recordValue?.recordCount ? recordValue.recordCount : 0);
        setAlertCount(alertGridView ?
            alertsGridData?.numberOfEntries :
            alertsData?.numberOfEntries ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeData,
        inactiveData,
        redflagValue,
        recordValue,
        setAlertCount,
        alertsData,
        alertsGridData,
        dispatch,
        alertsFilter]);

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const alertFilter = JSON.parse(sessionStorage.getItem('AlertsFilter') as string);

    const [selectedValue, setSelectedValue] = useState(
        alertFilter?.alertsdatatype ? alertFilter.alertsdatatype : KEYWORDS
    );
    const isLoading = (alertGridView ?
        alertsGridData?.contents.length === 0 :
        alertsData?.contents.length === 0) &&
        alertsStatus === RequestStatus.InProgress;

    return (
        <>
            <Header />
            <section
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                }}
            >
                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        mt: 2,
                        boxShadow: 5,
                        mb: 2,
                        position: 'relative',
                        color: '#9e9e9e',
                    }}
                >
                    <CardContent>
                        <Typography variant="h4" component="div">
                            {t('data-type.details.red-flag')}
                        </Typography>
                        <Typography component="div" style={{ fontSize: '0.9rem' }}>
                            {t('data-type.details.need-attention')}
                        </Typography>
                        <Typography variant="h2">
                            {redflagValueStatus === RequestStatus.InProgress ? (
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                </Box>
                            ) : (redFlagCount)
                            }
                        </Typography>
                        <Box >
                            <FlagOutlinedIcon
                                style={{
                                    position: 'absolute',
                                    top: 3,
                                    right: 4,
                                    fontSize: '3rem',
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>

                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        mt: 2,
                        boxShadow: 5,
                        mb: 2,
                        position: 'relative',
                        color: '#9e9e9e',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleCardClick('active')}
                >
                    <CardContent>
                        <Typography variant="h4" component="div">
                            {t('data-type.details.active-device')}
                        </Typography>
                        <Typography component="div" style={{ fontSize: '0.9rem' }}>
                            {t('data-type.details.active-device-today')}
                        </Typography>
                        <Typography variant="h2">
                            {activeDatatatus === RequestStatus.InProgress ? (
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                </Box>
                            ) : (activeCount)
                            }
                        </Typography>
                        <Box >
                            <PhoneIphoneIcon
                                style={{
                                    position: 'absolute', top: 3, right: 4, fontSize: '3rem',
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>

                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        mt: 2,
                        boxShadow: 5,
                        mb: 2,
                        position: 'relative',
                        color: '#9e9e9e',
                    }}
                >
                    <CardContent>
                        <Typography variant="h4" component="div">
                            {t('data-type.details.reports-title')}
                        </Typography>
                        <Typography component="div" style={{ fontSize: '0.9rem' }}>
                            {t('data-type.details.reports-saved')}
                        </Typography>
                        <Typography variant="h2">
                            {recordValueStatus === RequestStatus.InProgress ? (
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                </Box>
                            ) : (recordCount)
                            }
                        </Typography>
                        <Box >
                            <AssignmentOutlinedIcon
                                style={{
                                    position: 'absolute',
                                    top: 3,
                                    right: 4,
                                    fontSize: '3rem',
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        mt: 2,
                        boxShadow: 5,
                        mb: 2,
                        position: 'relative',
                        color: '#9e9e9e',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleCardClick('inActive')}
                >
                    <CardContent>
                        <Typography variant="h4" component="div">
                            {t('data-type.details.non-reporter')}
                        </Typography>
                        <Typography component="div" style={{ fontSize: '0.9rem' }}>
                            {t('data-type.details.non-reporter-days')}
                        </Typography>

                        <Typography variant="h2">
                            {inactiveDataStatus === RequestStatus.InProgress ? (
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                </Box>
                            ) : (inActiveCount)
                            }
                        </Typography>
                        <Box >
                            <PhonelinkEraseOutlinedIcon
                                style={{
                                    position: 'absolute',
                                    top: 3,
                                    right: 4,
                                    fontSize: '3rem',
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>

                <Card
                    sx={{
                        width: 250,
                        height: 120,
                        mt: 2,
                        boxShadow: 5,
                        mb: 2,
                        position: 'relative',
                        color: '#9e9e9e',
                    }}
                >
                    <CardContent>
                        <Typography variant="h4" component="div">
                            {t('data-type.details.notification-title')}
                        </Typography>
                        <Typography component="div" style={{ fontSize: '0.9rem' }}>
                            {t('data-type.details.notification-count')}
                        </Typography>
                        <Typography variant="h2">
                            {isLoading ? (
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                </Box>
                            ) : (
                                alertCount
                            )}
                        </Typography>
                        <Box sx={{ position: 'absolute', top: 3, right: 4 }}>
                            <NotificationsNoneIcon style={{ fontSize: '3rem' }} />
                        </Box>
                    </CardContent>
                </Card>

            </section>
            <DataTypeAlertsFilters selectedValue={selectedValue} />
            <br />
            <Box m={4} mt={-1} mb={-1}>
                <TableExporterProvider>
                    <Grid container sx={{ m: '10px 0' }}>
                        <Grid
                            item
                            md={8}
                            sm={12}
                            xs={12}
                        >
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                >
                                    <DataTypeSelectAlerts
                                        selectedValue={selectedValue}
                                        setSelectedValue={setSelectedValue}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                >
                                    <DataTypeProfileSelect />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                >
                                    <DataTypeFlagFilter />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                >
                                    <DataTypeSearchFilter />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            <TableDownloadBar 
                                isRefresh={true}
                                isAllToExport={!!alertsFilter?.device}
                            />
                        </Grid>
                    </Grid>
                    <Grid m={4}>
                        <LandingPageAlertsTable selectedValue={selectedValue} />
                    </Grid>
                </TableExporterProvider>
            </Box>
            <Modal
                title={activeClicked ? 'Active Reporters' : 'Non Reporters'}
                open={activeClicked || inActiveClicked}
                showCloseButton={true}
                maxWidth="lg"
                fullWidth
                onClose={closeModal}
            >
                <TableExporterProvider>
                    <TableDownloadBar />
                    {activeClicked ? <ActiveReportersListModal /> : <NonReportersListModal />}
                </TableExporterProvider>
            </Modal>

        </>
    );
}
