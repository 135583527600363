import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Alert, Button, Grid, IconButton, Snackbar, SnackbarProps, Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectUrlsListData,
    selectUrlsListStatus,
    selectCreateUrlsStatus,
    selectDeleteUrlStatus,
    selectEditUrlStatus,
    getUrlsList,
    resetUrlsStatuses,
    deleteUrl,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { useTranslation } from 'react-i18next';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { UrlsList } from 'src/models/administration/resourceData.models';
import { Actions } from 'src/models/roles.enum';
import { Modal } from 'src/components/modal/Modal';
import { ConfirmationSelection } from 'src/templates/confirmation-selection/ConfirmationSelection';
import { UrlsListModal } from './UrlsListModal';
import { addButtonStyle, SearchField } from '../applications-list-table/ApplicationsListTable';

export function UrlsListTable(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const urlsListData = useSelector(selectUrlsListData);
    const urlsListStatus = useSelector(selectUrlsListStatus);
    const [searchValue, setSearchValue] = useState<string>('');
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [isUrlToAdd, setIsUrlToAdd] = useState<boolean>(false);
    const [urlToEdit, setUrlToEdit] = useState<UrlsList>();
    const [urlToDelete, setUrlToDelete] = useState<UrlsList>();
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [loadUrlData, setLoadUrlData] = useState<boolean>(false);
    const urlsCreateStatus = useSelector(selectCreateUrlsStatus);
    const urlEditStatus = useSelector(selectEditUrlStatus);
    const urlDeleteStatus = useSelector(selectDeleteUrlStatus);

    function onPageChange(pageNumber: number): void {
        dispatch(getUrlsList({
            pageNumber,
            entriesPerPage,
            searchValue
        }));
    }

    const onAddUrl = (): void => {
        setIsUrlToAdd(true);
    }

    const dispatchUrlsList = (): void => {
        dispatch(getUrlsList({
            pageNumber: 1,
            entriesPerPage,
            searchValue,
        }));
    }

    useEffect(() => {
        dispatchUrlsList();
    }, [dispatch, entriesPerPage, searchValue]);

    useEffect(() => {
        if (loadUrlData) dispatchUrlsList();
    }, [loadUrlData]);

    useEffect(() => {
        let message = '';

        if (urlsCreateStatus === RequestStatus.Success) {
            message = t('settings.resource.url-created-successfully');
        }
        else if (urlEditStatus === RequestStatus.Success) {
            message = t('settings.resource.url-edited-successfully');
        }
        else if (urlDeleteStatus === RequestStatus.Success) {
            message = t('settings.resource.url-deleted-successfully');
            onSaveModal();
        }
        if (message) {
            setNotificationState({ open: true, message });
        }
    }, [urlsCreateStatus, urlEditStatus, urlDeleteStatus, setNotificationState, t, dispatch]);

    function onCloseNotification(): void {
        dispatch(resetUrlsStatuses());
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    function onChangeSearchValue(value: string): void {
        setSearchValue(value);
    }

    const onPerformAction = (data: UrlsList, action: Actions): void => {
        if (action === Actions.EDIT) {
            setUrlToEdit(data);
        } else {
            setUrlToDelete(data);
        }
    }

    const onSaveModal = (): void => {
        onCloseModal();
        setLoadUrlData(true);
        setTimeout(() => {
            setLoadUrlData(false);
        }, 1000);
    }

    const onCloseModal = (): void => {
        setUrlToEdit(undefined);
        setUrlToDelete(undefined);
        setIsUrlToAdd(false);
    }

    const getActionColumn = (data: UrlsList): React.ReactNode => (
        <>
            <IconButton
                aria-label="edit"
                onClick={() => onPerformAction(data, Actions.EDIT)}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                aria-label="delete"
                onClick={() => onPerformAction(data, Actions.DELETE)}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );

    const columns: ColumnData<UrlsList>[] = [
        {
            label: t('settings.resource.url-name'),
            size: 2,
            align: 'center' as const,
            value: (data: UrlsList) => data.url,
        },
        {
            label: t('extension-form.action'),
            size: 2,
            align: 'center' as const,
            format: (data: UrlsList) => getActionColumn(data),
        },
    ];

    const handleDeleteConfirmation = (value: boolean): void => {
        if (value === true && urlToDelete) {
            dispatch(deleteUrl(urlToDelete));
        }
        setUrlToDelete(undefined);
    };

    return (
        <>
            <Box>
                <Stack direction="column" sx={{ textAlign: 'left' }}>
                    <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        padding="1em 0"
                    >
                        {/* Search Field */}
                        <SearchField onChange={onChangeSearchValue} />
                        <Button
                            variant="contained"
                            sx={addButtonStyle}
                            onClick={onAddUrl}
                        >
                            {t('settings.resource.add-urls').toUpperCase()}
                        </Button>
                    </Stack>
                </Stack>
                <Grid>
                    <DataTable<UrlsList>
                        isLoading={urlsListStatus === RequestStatus.InProgress}
                        columns={columns}
                        alignHeader={'center'}
                        onPageChange={onPageChange}
                        entriesPerPage={entriesPerPage}
                        onChangeEntriesPerPage={setEntriesPerPage}
                        {...urlsListData}
                    />
                </Grid>
            </Box>

            <Modal
                title={t('settings.resource.url-management')}
                open={!!urlToEdit || isUrlToAdd}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <UrlsListModal
                        action={isUrlToAdd ? Actions.ADD : Actions.EDIT}
                        url={urlToEdit}
                        onSave={onSaveModal}
                        onCancel={onCloseModal}
                    />
                </Box>
            </Modal>

            <Modal
                title={t('settings.resource.delete-url')}
                open={!!urlToDelete}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <ConfirmationSelection
                        text={t("settings.resource.url-delete-confirmation", { url: urlToDelete?.url })} 
                        onSubmit={handleDeleteConfirmation}
                    />
                </Box>
            </Modal>

            <Snackbar
                autoHideDuration={3000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}


