import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getDevicesCacheDetails as getDevicesCacheDetailsRequest,
    deleteDeviceCacheData as deleteDeviceCacheDataRequest,
} from 'src/services/administration/deviceCacheDetails';
import {
    getDevicesDetails as getDevicesDetailsRequest,
    getDevicesCountDetails as getDevicesCountDetailsRequest,
} from 'src/services/administration/deviceDetails';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    DeviceDetails,
    DeviceDetailsPaginatedParams,
    DeviceDetailsParams,
    DevicesCountDetails,
} from 'src/models/administration/deviceDetailsData.model';
import {
    DeviceLogParams,
    DeviceLogs,
    DevicePermissions,
    DevicePermissionsParams,
} from 'src/models/administration/deviceData.model';
import {
    getLogs as getLogsRequest,
    getPermissions,
    getPermissionsIds,
} from 'src/services/administration/devices';
import {
    deleteCustomAWL as deleteCustomAWLRequest,
} from 'src/services/administration/alertWordList';
import { AWLDeleteParams } from 'src/models/administration/alertWordListData.model';
import {
    DeleteDeviceCacheParams, DeviceCacheDetails, DeviceCacheDetailsPaginatedParams,
} from 'src/models/administration/deviceCacheDetailsData.model';
import { DeviceStatus } from 'src/models/deviceSelectionModels';
import {
    getApplicationsList as getApplicationsListRequest,
    createApplications as createApplicationsRequest,
    editApplications as editApplicationsRequest,
    deleteApplications as deleteApplicationsRequest,
    getProgramsListOptions as getProgramsListOptionsRequest,
    getCategoriesList as getCategoriesListRequest,
    createCategories as createCategoriesRequest,
    editCategory as editCategoriesRequest,
    deleteCategory as deleteCategoriesRequest,
    getUrlsList as getUrlsListRequest,
    createUrls as createUrlsRequest,
    editUrl as editUrlRequest,
    deleteUrl as deleteUrlRequest,
} from 'src/services/administration/resource';
import {
    getConfigurationSchedule as getConfigurationScheduleRequest,
    getBlockedConfigurations as getBlockedConfigurationsRequest,
    manageBlockConfiguration as manageBlockConfigurationRequest,
} from 'src/services/administration/blockConfiguration';
import {
    ApplicationsList, ResourcePaginatedParams,
    CreateApplicationsParams,
    CategoriesList,
    CreateCategoriesParams,
    CreateUrlsParams,
    UrlsList,
} from 'src/models/administration/resourceData.models';
import axios from 'axios';
import { ErrorData } from 'src/models/errors/errorData.model';
import { Keys, read, write } from 'src/utils/storage';
import { AdminSettingsSideMenu } from 'src/models/adminSettings.enum';
import {
    BlockedConfiguration,
    BlockedConfigurationParams,
    BlockedListPaginatedParams,
    ConfigScheduleParams 
} from 'src/models/administration/blockConfigurationData.model';
interface AdministrationMetaState {
    deviceDetailsFilters: CaptureFilters;
    deviceStatus: DeviceStatus[];
    deviceDetails?: PaginatedContent<DeviceDetails>;
    deviceDetailsStatus: RequestStatus;
    deviceCountDetails?: DevicesCountDetails[];
    deviceCountDetailsStatus: RequestStatus;
    deviceCacheDetails?: PaginatedContent<DeviceCacheDetails>;
    deviceCacheDetailsStatus: RequestStatus;
    deleteDeviceCacheStatus?: RequestStatus;

    adminSettingsSideMenu: string,

    applicationsListOptions?: ApplicationsList[];
    applicationsListOptionsStatus?: RequestStatus;
    applicationsListData?: PaginatedContent<ApplicationsList>;
    applicationsListStatus?: RequestStatus;
    applicationError?: ErrorData,
    createApplicationsStatus?: RequestStatus;
    editApplicationsStatus?: RequestStatus;
    deleteApplicationsStatus?: RequestStatus;
    categoriesListData?: PaginatedContent<CategoriesList>;
    categoriesListStatus?: RequestStatus;
    categoriesError?: ErrorData,
    createCategoryStatus?: RequestStatus;
    editCategoryStatus?: RequestStatus;
    deleteCategoryStatus?: RequestStatus;
    urlsListData?: PaginatedContent<UrlsList>;
    urlsListStatus?: RequestStatus;
    urlsError?: ErrorData,
    createUrlsStatus?: RequestStatus;
    editUrlStatus?: RequestStatus;
    deleteUrlStatus?: RequestStatus;

    configSchedule?: BlockedConfiguration;
    configScheduleStatus?: RequestStatus;
    blockAppsList?: PaginatedContent<BlockedConfiguration>;
    blockAppsListStatus?: RequestStatus;
    createBlockConfigurationStatus?: RequestStatus;
    editBlockConfigurationStatus?: RequestStatus;
    deleteBlockConfigurationStatus?: RequestStatus;

    deviceLogs?: PaginatedContent<DeviceLogs>;
    deviceLogsStatus: RequestStatus;
    devicePermissions?: PaginatedContent<DevicePermissions>
    devicePermissionsIds?: string[]
    devicePermissionStatus?: RequestStatus,
    devicePermissionsIdsStatus?: RequestStatus,
    deleteCustomAWLStatus?: RequestStatus;
    deviceCacheSearchValue?: string;
}

const initialState: AdministrationMetaState = {
    deviceDetailsFilters: {
        sortBy: '{"status": 1}',
    },
    deviceStatus: Object.values(DeviceStatus).map((flag) => flag),
    deviceDetailsStatus: RequestStatus.NotStarted,
    deviceCountDetailsStatus: RequestStatus.NotStarted,
    deviceLogsStatus: RequestStatus.NotStarted,
    deviceCacheDetailsStatus: RequestStatus.NotStarted,
    deleteDeviceCacheStatus: RequestStatus.NotStarted,
    deleteCustomAWLStatus: RequestStatus.NotStarted,
    adminSettingsSideMenu: read(Keys.adminSettingsSideMenu)
        ?? AdminSettingsSideMenu.SubMenus.DeviceConfigurationManagement.ConfigurationServices,
};

export const getDevicesDetails = createAsyncThunk(
    'administration/devices',
    async (params: DeviceDetailsPaginatedParams) => getDevicesDetailsRequest(params)
);

export const getDevicesCountDetails = createAsyncThunk(
    'administration/devicesCount',
    async (params: DeviceDetailsParams) => getDevicesCountDetailsRequest(params)
);

export const getDeviceLogs = createAsyncThunk(
    'administration/deviceLogs',
    async (params: DeviceLogParams) => getLogsRequest(params)
);

export const getDevicePermissions = createAsyncThunk(
    'administration/devicePermissions',
    async (params: DevicePermissionsParams) => getPermissions(params)
);
export const getDevicePermissionsIds = createAsyncThunk(
    'administration/devicePermissionsIds',
    async (params: DevicePermissionsParams) => getPermissionsIds(params)
);
export const deleteCustomAWL = createAsyncThunk(
    'administration/deleteCustomAWL',
    async (params: AWLDeleteParams) => deleteCustomAWLRequest(params)
);

export const getDevicesCacheDetails = createAsyncThunk(
    'administration/getDevicesCacheDetails',
    async (params: DeviceCacheDetailsPaginatedParams) => getDevicesCacheDetailsRequest(params)
);

export const deleteDeviceCacheData = createAsyncThunk(
    'administration/deleteDeviceCacheData',
    async (params: DeleteDeviceCacheParams) => deleteDeviceCacheDataRequest(params)
);
//applications
export const getApplicationsList = createAsyncThunk(
    'administration/getApplicationsList',
    async (params: ResourcePaginatedParams, { rejectWithValue }) => {
        try {
            return await getApplicationsListRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const createApplications = createAsyncThunk(
    'administration/createApplications',
    async (params: CreateApplicationsParams, { rejectWithValue }) => {
        try {
            return await createApplicationsRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const editApplications = createAsyncThunk(
    'administration/editApplications',
    async (params: ApplicationsList, { rejectWithValue }) => {
        try {
            return await editApplicationsRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const deleteApplications = createAsyncThunk(
    'administration/deleteApplications',
    async (params: ApplicationsList, { rejectWithValue }) => {
        try {
            return await deleteApplicationsRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const getProgramsListOptions = createAsyncThunk(
    'captures/getProgramsListOptions',
    async () => getProgramsListOptionsRequest()
);

//Category
export const getCategoriesList = createAsyncThunk(
    'administration/getCategoriesList',
    async (params: ResourcePaginatedParams, { rejectWithValue }) => {
        try {
            return await getCategoriesListRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const createCategories = createAsyncThunk(
    'administration/createCategories',
    async (params: CreateCategoriesParams, { rejectWithValue }) => {
        try {
            return await createCategoriesRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const editCategory = createAsyncThunk(
    'administration/editCategory',
    async (params: CategoriesList, { rejectWithValue }) => {
        try {
            return await editCategoriesRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const deleteCategory = createAsyncThunk(
    'administration/deleteCategory',
    async (params: CategoriesList, { rejectWithValue }) => {
        try {
            return await deleteCategoriesRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
// Urls
export const getUrlsList = createAsyncThunk(
    'administration/getUrlsList',
    async (params: ResourcePaginatedParams, { rejectWithValue }) => {
        try {
            return await getUrlsListRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const createUrls = createAsyncThunk(
    'administration/createUrls',
    async (params: CreateUrlsParams, { rejectWithValue }) => {
        try {
            return await createUrlsRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const editUrl = createAsyncThunk(
    'administration/editUrl',
    async (params: UrlsList, { rejectWithValue }) => {
        try {
            return await editUrlRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);
export const deleteUrl = createAsyncThunk(
    'administration/deleteUrl',
    async (params: UrlsList, { rejectWithValue }) => {
        try {
            return await deleteUrlRequest(params)
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error.response?.data);
            }
            throw error;
        }
    }
);

//block Configurations

export const getDefaultConfigurationSchedule = createAsyncThunk(
    'administration/defaultConfigSchedule',
    async (params: ConfigScheduleParams) => getConfigurationScheduleRequest(params)
);

export const getBlockAppsList = createAsyncThunk(
    'administration/getBlockAppsList',
    async (params: BlockedListPaginatedParams) => getBlockedConfigurationsRequest(params)
);

export const createBlockConfiguration = createAsyncThunk(
    'administration/createBlockConfiguration',
    async (params: BlockedConfigurationParams) => manageBlockConfigurationRequest(params)
);

export const editBlockConfiguration = createAsyncThunk(
    'administration/editBlockConfiguration',
    async (params: BlockedConfigurationParams) => manageBlockConfigurationRequest(params)
);

export const deleteBlockConfiguration = createAsyncThunk(
    'administration/deleteBlockConfiguration',
    async (params: BlockedConfigurationParams) => manageBlockConfigurationRequest(params)
);

export const administrationMetaSlice = createSlice({
    name: 'administrationMeta',
    initialState,
    reducers: {
        changeDeviceDetailsFilters: (state, action: PayloadAction<CaptureFilters>) => {
            state.deviceDetailsFilters = { ...state.deviceDetailsFilters, ...action.payload };
        },
        changeAgencyDeviceFilter: (state, action: PayloadAction<string>) => {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                agency: action.payload,
                officer: undefined,
                client: undefined,
                device: undefined,
                user: undefined,
                platform: undefined,
                version: undefined,
            };
        },
        changeOfficerDeviceFilter: (state, action: PayloadAction<string>) => {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                officer: action.payload,
                client: undefined,
                device: undefined,
                platform: undefined,
                version: undefined,
            };
        },
        changeClientDeviceFilter: (state, action: PayloadAction<string>) => {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                client: action.payload,
                device: undefined,
                platform: undefined,
                version: undefined,
            };
        },
        changeDeviceFilter: (state, action: PayloadAction<string>) => {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                device: action.payload,
                platform: undefined,
                version: undefined,
            };
        },
        changeDeviceStatusFilter: (state, action: PayloadAction<DeviceStatus[]>) => {
            state.deviceStatus = action.payload
        },
        changeDevicePlatformFilter: (state, action: PayloadAction<string>) => {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                platform: action.payload,
                version: undefined,
            };
        },
        resetDeviceDetailsStatus: (state) => {
            state.deviceDetailsStatus = RequestStatus.NotStarted;
        },
        resetDevicePermissionsStatus: (state) => {
            state.devicePermissionsIds = [];
            state.devicePermissionsIdsStatus = RequestStatus.NotStarted;
        },
        resetDeleteCustomAWLStatus: (state) => {
            state.deleteCustomAWLStatus = RequestStatus.NotStarted;
        },
        clearDeviceInfoSearchValue: (state) => {
            state.deviceDetailsFilters.searchValue = '';
        },
        resetDeleteDeviceCacheStatus: (state) => {
            state.deleteDeviceCacheStatus = RequestStatus.NotStarted;
        },
        setDeviceCacheSearchValue: (state, action: PayloadAction<string>) => {
            state.deviceCacheSearchValue = action.payload;
        },
        setAdminSettingsSideMenu: (state, action: PayloadAction<string>) => {
            state.adminSettingsSideMenu = action.payload;
            write(Keys.adminSettingsSideMenu, action.payload);
        },
        resetApplicationStatuses: (state) => {
            state.applicationsListStatus = RequestStatus.NotStarted;
            state.createApplicationsStatus = RequestStatus.NotStarted;
            state.editApplicationsStatus = RequestStatus.NotStarted;
            state.deleteApplicationsStatus = RequestStatus.NotStarted;
            state.applicationError = undefined;
        },
        resetCategoryStatuses: (state) => {
            state.categoriesListStatus = RequestStatus.NotStarted;
            state.createCategoryStatus = RequestStatus.NotStarted;
            state.editCategoryStatus = RequestStatus.NotStarted;
            state.deleteCategoryStatus = RequestStatus.NotStarted;
            state.categoriesError = undefined;
        },
        resetUrlsStatuses: (state) => {
            state.urlsListStatus = RequestStatus.NotStarted;
            state.createUrlsStatus = RequestStatus.NotStarted;
            state.editUrlStatus = RequestStatus.NotStarted;
            state.deleteUrlStatus = RequestStatus.NotStarted;
            state.urlsError = undefined;
        },
        resetBlockConfigurationStatuses: (state) => {
            state.createBlockConfigurationStatus = RequestStatus.NotStarted;
            state.editBlockConfigurationStatus = RequestStatus.NotStarted;
            state.deleteBlockConfigurationStatus = RequestStatus.NotStarted;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDevicesDetails.pending, (state) => {
                state.deviceDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicesDetails.fulfilled, (state, action) => {
                state.deviceDetailsStatus = RequestStatus.Success;
                state.deviceDetails = action.payload;
            })
            .addCase(getDevicesDetails.rejected, (state) => {
                state.deviceDetailsStatus = RequestStatus.Failure;
                state.deviceDetails = undefined;
            })
            .addCase(getDevicesCountDetails.pending, (state) => {
                state.deviceCountDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicesCountDetails.fulfilled, (state, action) => {
                state.deviceCountDetailsStatus = RequestStatus.Success;
                state.deviceCountDetails = action.payload;
            })
            .addCase(getDevicesCountDetails.rejected, (state) => {
                state.deviceCountDetailsStatus = RequestStatus.Failure;
                state.deviceCountDetails = undefined;
            })
            .addCase(getDeviceLogs.pending, (state) => {
                state.deviceLogsStatus = RequestStatus.InProgress;
            })
            .addCase(getDeviceLogs.fulfilled, (state, action) => {
                state.deviceLogsStatus = RequestStatus.Success;
                state.deviceLogs = action.payload;
            })
            .addCase(getDeviceLogs.rejected, (state) => {
                state.deviceLogsStatus = RequestStatus.Failure;
                state.deviceLogs = undefined;
            })
            .addCase(getDevicePermissions.pending, (state) => {
                state.devicePermissionStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicePermissions.fulfilled, (state, action) => {
                state.devicePermissionStatus = RequestStatus.Success;
                state.devicePermissions = action.payload;
            })
            .addCase(getDevicePermissions.rejected, (state, action) => {
                state.devicePermissionStatus = RequestStatus.Failure;
            })
            .addCase(getDevicePermissionsIds.pending, (state) => {
                state.devicePermissionsIdsStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicePermissionsIds.fulfilled, (state, action) => {
                state.devicePermissionsIdsStatus = RequestStatus.Success;
                state.devicePermissionsIds = action.payload;
            })
            .addCase(getDevicePermissionsIds.rejected, (state, action) => {
                state.devicePermissionsIdsStatus = RequestStatus.Failure;
            })
            .addCase(deleteCustomAWL.pending, (state) => {
                state.deleteCustomAWLStatus = RequestStatus.InProgress;
            })
            .addCase(deleteCustomAWL.fulfilled, (state, action) => {
                state.deleteCustomAWLStatus = RequestStatus.Success;
            })
            .addCase(deleteCustomAWL.rejected, (state, action) => {
                state.deleteCustomAWLStatus = RequestStatus.Failure;
            })
            .addCase(getDevicesCacheDetails.pending, (state) => {
                state.deviceCacheDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicesCacheDetails.fulfilled, (state, action) => {
                state.deviceCacheDetailsStatus = RequestStatus.Success;
                state.deviceCacheDetails = action.payload;
            })
            .addCase(getDevicesCacheDetails.rejected, (state) => {
                state.deviceCacheDetailsStatus = RequestStatus.Failure;
                state.deviceCacheDetails = undefined;
            })
            .addCase(deleteDeviceCacheData.pending, (state) => {
                state.deleteDeviceCacheStatus = RequestStatus.InProgress;
            })
            .addCase(deleteDeviceCacheData.fulfilled, (state, action) => {
                state.deleteDeviceCacheStatus = RequestStatus.Success;
            })
            .addCase(deleteDeviceCacheData.rejected, (state, action) => {
                state.deleteDeviceCacheStatus = RequestStatus.Failure;
            })
            //application
            .addCase(getProgramsListOptions.pending, (state) => {
                state.applicationsListOptionsStatus = RequestStatus.InProgress;
            })
            .addCase(getProgramsListOptions.fulfilled, (state, action) => {
                state.applicationsListOptionsStatus = RequestStatus.Success;
                state.applicationsListOptions = action.payload;
            })
            .addCase(getProgramsListOptions.rejected, (state, action) => {
                state.applicationsListOptionsStatus = RequestStatus.Failure;
                state.applicationsListOptions = undefined;
            })
            .addCase(getApplicationsList.pending, (state) => {
                state.applicationsListStatus = RequestStatus.InProgress;
            })
            .addCase(getApplicationsList.fulfilled, (state, action) => {
                state.applicationsListStatus = RequestStatus.Success;
                state.applicationsListData = action.payload;
            })
            .addCase(getApplicationsList.rejected, (state, action) => {
                state.applicationsListStatus = RequestStatus.Failure;
                state.applicationsListData = undefined;
                if (Array.isArray(action.payload)) {
                    state.applicationError = { messages: action.payload as string[] };
                }
                else {
                    state.applicationError = action.payload as ErrorData;
                }
            })
            .addCase(createApplications.pending, (state) => {
                state.createApplicationsStatus = RequestStatus.InProgress;
            })
            .addCase(createApplications.fulfilled, (state, action) => {
                state.createApplicationsStatus = RequestStatus.Success;
            })
            .addCase(createApplications.rejected, (state, action) => {
                state.createApplicationsStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.applicationError = { messages: action.payload as string[] };
                }
                else {
                    state.applicationError = action.payload as ErrorData;
                }
            })
            .addCase(editApplications.pending, (state) => {
                state.editApplicationsStatus = RequestStatus.InProgress;
            })
            .addCase(editApplications.fulfilled, (state, action) => {
                state.editApplicationsStatus = RequestStatus.Success;
            })
            .addCase(editApplications.rejected, (state, action) => {
                state.editApplicationsStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.applicationError = { messages: action.payload as string[] };
                }
                else {
                    state.applicationError = action.payload as ErrorData;
                }
            })
            .addCase(deleteApplications.pending, (state) => {
                state.deleteApplicationsStatus = RequestStatus.InProgress;
            })
            .addCase(deleteApplications.fulfilled, (state, action) => {
                state.deleteApplicationsStatus = RequestStatus.Success;
            })
            .addCase(deleteApplications.rejected, (state, action) => {
                state.deleteApplicationsStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.applicationError = { messages: action.payload as string[] };
                }
                else {
                    state.applicationError = action.payload as ErrorData;
                }
            })
            // Category
            .addCase(getCategoriesList.pending, (state) => {
                state.categoriesListStatus = RequestStatus.InProgress;
            })
            .addCase(getCategoriesList.fulfilled, (state, action) => {
                state.categoriesListStatus = RequestStatus.Success;
                state.categoriesListData = action.payload;
            })
            .addCase(getCategoriesList.rejected, (state, action) => {
                state.categoriesListStatus = RequestStatus.Failure;
                state.categoriesListData = undefined;
                if (Array.isArray(action.payload)) {
                    state.categoriesError = { messages: action.payload as string[] };
                }
                else {
                    state.categoriesError = action.payload as ErrorData;
                }
            })
            .addCase(createCategories.pending, (state) => {
                state.createCategoryStatus = RequestStatus.InProgress;
            })
            .addCase(createCategories.fulfilled, (state, action) => {
                state.createCategoryStatus = RequestStatus.Success;
            })
            .addCase(createCategories.rejected, (state, action) => {
                state.createCategoryStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.categoriesError = { messages: action.payload as string[] };
                }
                else {
                    state.categoriesError = action.payload as ErrorData;
                }
            })
            .addCase(editCategory.pending, (state) => {
                state.editCategoryStatus = RequestStatus.InProgress;
            })
            .addCase(editCategory.fulfilled, (state, action) => {
                state.editCategoryStatus = RequestStatus.Success;
            })
            .addCase(editCategory.rejected, (state, action) => {
                state.editCategoryStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.categoriesError = { messages: action.payload as string[] };
                }
                else {
                    state.categoriesError = action.payload as ErrorData;
                }
            })
            .addCase(deleteCategory.pending, (state) => {
                state.deleteCategoryStatus = RequestStatus.InProgress;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.deleteCategoryStatus = RequestStatus.Success;
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.deleteCategoryStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.categoriesError = { messages: action.payload as string[] };
                }
                else {
                    state.categoriesError = action.payload as ErrorData;
                }
            })
            // Urls
            .addCase(getUrlsList.pending, (state) => {
                state.urlsListStatus = RequestStatus.InProgress;
            })
            .addCase(getUrlsList.fulfilled, (state, action) => {
                state.urlsListStatus = RequestStatus.Success;
                state.urlsListData = action.payload;
            })
            .addCase(getUrlsList.rejected, (state, action) => {
                state.urlsListStatus = RequestStatus.Failure;
                state.urlsListData = undefined;
                if (Array.isArray(action.payload)) {
                    state.urlsError = { messages: action.payload as string[] };
                }
                else {
                    state.urlsError = action.payload as ErrorData;
                }
            })
            .addCase(createUrls.pending, (state) => {
                state.createUrlsStatus = RequestStatus.InProgress;
            })
            .addCase(createUrls.fulfilled, (state) => {
                state.createUrlsStatus = RequestStatus.Success;
            })
            .addCase(createUrls.rejected, (state, action) => {
                state.createUrlsStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.urlsError = { messages: action.payload as string[] };
                }
                else {
                    state.urlsError = action.payload as ErrorData;
                }
            })
            .addCase(editUrl.pending, (state) => {
                state.editUrlStatus = RequestStatus.InProgress;
            })
            .addCase(editUrl.fulfilled, (state) => {
                state.editUrlStatus = RequestStatus.Success;
            })
            .addCase(editUrl.rejected, (state, action) => {
                state.editUrlStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.urlsError = { messages: action.payload as string[] };
                }
                else {
                    state.urlsError = action.payload as ErrorData;
                }
            })
            .addCase(deleteUrl.pending, (state) => {
                state.deleteUrlStatus = RequestStatus.InProgress;
            })
            .addCase(deleteUrl.fulfilled, (state) => {
                state.deleteUrlStatus = RequestStatus.Success;
            })
            .addCase(deleteUrl.rejected, (state, action) => {
                state.deleteUrlStatus = RequestStatus.Failure;
                if (Array.isArray(action.payload)) {
                    state.urlsError = { messages: action.payload as string[] };
                }
                else {
                    state.urlsError = action.payload as ErrorData;
                }
            })
            .addCase(getDefaultConfigurationSchedule.pending, (state) => {
                state.configScheduleStatus = RequestStatus.InProgress;
            })
            .addCase(getDefaultConfigurationSchedule.fulfilled, (state, action) => {
                state.configScheduleStatus = RequestStatus.Success;
                state.configSchedule = action.payload;
            })
            .addCase(getDefaultConfigurationSchedule.rejected, (state) => {
                state.configScheduleStatus = RequestStatus.Failure;
                state.configSchedule = undefined;
            })
            .addCase(getBlockAppsList.pending, (state) => {
                state.blockAppsListStatus = RequestStatus.InProgress;
            })
            .addCase(getBlockAppsList.fulfilled, (state, action) => {
                state.blockAppsListStatus = RequestStatus.Success;
                state.blockAppsList = action.payload;
            })
            .addCase(getBlockAppsList.rejected, (state) => {
                state.blockAppsListStatus = RequestStatus.Failure;
                state.blockAppsList = undefined;
            })
            .addCase(createBlockConfiguration.pending, (state) => {
                state.createBlockConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(createBlockConfiguration.fulfilled, (state, action) => {
                state.createBlockConfigurationStatus = RequestStatus.Success;
            })
            .addCase(createBlockConfiguration.rejected, (state) => {
                state.createBlockConfigurationStatus = RequestStatus.Failure;
            })
            .addCase(editBlockConfiguration.pending, (state) => {
                state.editBlockConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(editBlockConfiguration.fulfilled, (state, action) => {
                state.editBlockConfigurationStatus = RequestStatus.Success;
            })
            .addCase(editBlockConfiguration.rejected, (state) => {
                state.editBlockConfigurationStatus = RequestStatus.Failure;
            })
            .addCase(deleteBlockConfiguration.pending, (state) => {
                state.deleteBlockConfigurationStatus = RequestStatus.InProgress;
            })
            .addCase(deleteBlockConfiguration.fulfilled, (state, action) => {
                state.deleteBlockConfigurationStatus = RequestStatus.Success;
            })
            .addCase(deleteBlockConfiguration.rejected, (state) => {
                state.deleteBlockConfigurationStatus = RequestStatus.Failure;
            });        
    },
});

function selectState(state: RootState): AdministrationMetaState {
    return state.administrationMeta;
}
export const selectDeviceDetailsFilters = createSelector(
    selectState,
    (state) => state.deviceDetailsFilters
);
export const selectDeviceDetails = createSelector(selectState, (state) => state.deviceDetails);
export const selectDeviceDetailsStatus = createSelector(
    selectState,
    (state) => state.deviceDetailsStatus
);
export const selectDeleteDeviceCacheStatus = createSelector(
    selectState,
    (state) => state.deleteDeviceCacheStatus
);
export const selectDeviceCountDetails = createSelector(
    selectState,
    (state) => state.deviceCountDetails
); export const selectDeviceCountDetailsStatus = createSelector(
    selectState,
    (state) => state.deviceCountDetailsStatus
);
export const selectDeviceLogs = createSelector(
    selectState,
    (state) => state.deviceLogs
);
export const selectDeviceLogsStatus = createSelector(
    selectState,
    (state) => state.deviceLogsStatus
);
export const selectDevicePermissions = createSelector(
    selectState,
    state => state.devicePermissions
);
export const selectDevicePermissionsStatus = createSelector(
    selectState,
    state => state.devicePermissionStatus
);
export const selectDevicePermissionsIds = createSelector(
    selectState,
    state => state.devicePermissionsIds
);
export const selectDevicePermissionsStatusIds = createSelector(
    selectState,
    state => state.devicePermissionsIdsStatus
);
export const selectDeleteCustomAWLStatus = createSelector(
    selectState,
    state => state.deleteCustomAWLStatus
);
export const selectDeviceCacheDetails = createSelector(
    selectState,
    (state) => state.deviceCacheDetails
);
export const selectDeviceCacheDetailsStatus = createSelector(
    selectState,
    (state) => state.deviceCacheDetailsStatus
);
export const selectDeviceCacheSearchValue = createSelector(
    selectState,
    (state) => state.deviceCacheSearchValue
);
export const selectDeviceStatus = createSelector(
    selectState,
    (state) => state.deviceStatus
);
export const selectAdminSettingsSideMenu = createSelector(
    selectState,
    (state) => state.adminSettingsSideMenu
);
export const selectApplicationsListOptionsData = createSelector(
    selectState,
    (state) => state.applicationsListOptions
);
export const selectApplicationsListOptionsStatus = createSelector(
    selectState,
    (state) => state.applicationsListOptionsStatus
);
export const selectApplicationsListData = createSelector(
    selectState,
    (state) => state.applicationsListData
);
export const selectApplicationsListStatus = createSelector(
    selectState,
    (state) => state.applicationsListStatus
);
export const selectApplicationsError = createSelector(
    selectState,
    (state) => state.applicationError
);
export const selectCreateApplicationsStatus = createSelector(
    selectState,
    (state) => state.createApplicationsStatus
);
export const selectEditApplicationsStatus = createSelector(
    selectState,
    (state) => state.editApplicationsStatus
);
export const selectDeleteApplicationsStatus = createSelector(
    selectState,
    (state) => state.deleteApplicationsStatus
);
export const selectCategoriesListData = createSelector(
    selectState,
    (state) => state.categoriesListData
);
export const selectCategoriesListStatus = createSelector(
    selectState,
    (state) => state.categoriesListStatus
);
export const selectCategoryError = createSelector(
    selectState,
    (state) => state.categoriesError
);
export const selectCreateCategoriesStatus = createSelector(
    selectState,
    (state) => state.createCategoryStatus
);
export const selectEditCategoryStatus = createSelector(
    selectState,
    (state) => state.editCategoryStatus
);
export const selectDeleteCategoryStatus = createSelector(
    selectState,
    (state) => state.deleteCategoryStatus
);
export const selectUrlsListData = createSelector(
    selectState,
    (state) => state.urlsListData
);
export const selectUrlsListStatus = createSelector(
    selectState,
    (state) => state.urlsListStatus
);
export const selectUrlError = createSelector(
    selectState,
    (state) => state.urlsError
);
export const selectCreateUrlsStatus = createSelector(
    selectState,
    (state) => state.createUrlsStatus
);
export const selectEditUrlStatus = createSelector(
    selectState,
    (state) => state.editUrlStatus
);
export const selectDeleteUrlStatus = createSelector(
    selectState,
    (state) => state.deleteUrlStatus
);
export const selectConfigSchedule = createSelector(
    selectState,
    state => state.configSchedule
);
export const selectConfigScheduleStatus = createSelector(
    selectState,
    state => state.configScheduleStatus
);
export const selectBlockAppsListData = createSelector(
    selectState,
    state => state.blockAppsList
);
export const selectBlockAppsListStatus = createSelector(
    selectState,
    state => state.blockAppsListStatus
);
export const selectCreateBlockConfigurationStatus = createSelector(
    selectState,
    state => state.createBlockConfigurationStatus
);
export const selectEditBlockConfigurationStatus = createSelector(
    selectState,
    state => state.editBlockConfigurationStatus
);
export const selectDeleteBlockConfigurationStatus = createSelector(
    selectState,
    state => state.deleteBlockConfigurationStatus
);

export const {
    resetDeviceDetailsStatus,
    changeDeviceDetailsFilters,
    changeAgencyDeviceFilter,
    changeOfficerDeviceFilter,
    changeDeviceStatusFilter,
    changeClientDeviceFilter,
    changeDeviceFilter,
    changeDevicePlatformFilter,
    resetDevicePermissionsStatus,
    resetDeleteCustomAWLStatus,
    clearDeviceInfoSearchValue,
    resetDeleteDeviceCacheStatus,
    setDeviceCacheSearchValue,
    setAdminSettingsSideMenu,
    resetApplicationStatuses,
    resetCategoryStatuses,
    resetUrlsStatuses,
    resetBlockConfigurationStatuses,
} = administrationMetaSlice.actions;

export default administrationMetaSlice.reducer;
