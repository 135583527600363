import React from 'react';
import { SettingsPanelProps } from '../slider/ImageSlider';
import { Paper, Slider, Typography } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { useTranslation } from 'react-i18next';
export const SettingsPanel = ({
    panelRef,
    timeInterval,
    setTimeInterval,
    showSlider, setShowSlider,
}: SettingsPanelProps):JSX.Element =>
{
    const { t } = useTranslation();
    return (
        <Paper
            sx={{
                position: 'absolute',
                bottom: '70px',
                right: '10px',
                p: 2,
                width: '30%',
                textAlign: 'center',
                boxShadow: 3,
                backgroundColor: (theme)=>theme.palette.common.shadowBlack,
                color: 'white',
                borderRadius: 2,
                zIndex: 20,

            }}
            ref={panelRef}
        >
            {!showSlider ? (
                <Typography
                    variant="body2"
                    sx={{
                        cursor: 'pointer',
                        '&:hover': { color: (theme)=>theme.palette.common.lightGrey },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',

                    }}
                    onClick={() => setShowSlider(true)}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            cursor: 'pointer',
                            '&:hover': { color: (theme)=>theme.palette.common.lightGrey },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '0.3rem',

                        }}
                    >
                        <QueryBuilderOutlinedIcon fontSize="small" />
                        {t('slider.interval')}
                    </Typography>
                    {' '}
                    <Typography
                        variant="body2"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {t('slider.auto')}
                        <span style={{ color: '#BDBDBD' }}>
                            {' '}
                            {' '}
                            (
                            {timeInterval / 1000}
                            s)
                        </span>
                        <NavigateNextOutlinedIcon fontSize="small" />
                    </Typography>
                </Typography>
            ) : (
                <>
                    <Typography
                        variant="body1"
                        sx={{
                            cursor: 'pointer',
                            '&:hover': { color: (theme)=>theme.palette.common.lightGrey },
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        onClick={() => setShowSlider(false)}
                    >
                        <ArrowLeftIcon fontSize="small" />
                        {' '}
                        {t('slider.back')}
                    </Typography>
                    <Slider
                        value={timeInterval / 1000}
                        valueLabelDisplay="on"
                        onChange={(_, newValue) => setTimeInterval(newValue as number * 1000)}
                        min={0.5}
                        max={20}
                        step={0.5}
                        sx={{ color: 'white' }}
                    />
                </>
            )}
        </Paper>
    );
};
