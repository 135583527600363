import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ScreenshotData }
    from 'src/models/captures/screenshotData.model';
import {
    changeScreenshot,
    changeScreenshotDeviceId,
    selectScreenshot,
    selectScreenshotDeviceId,
    selectScreenshotsDeviceId,
    selectScreenshotsStatus,
    selectUpdatedFlag,
    screenshotFilterFlags,
    selectFilters,
    changeFilters,
    resetaiFlagFilterData,
    changescreenshotGridViewFilters,
    selectscreenshotModalData,
    changeScreenshotDetailsFilter,
} from 'src/state/captures/capturesSlice';
import GridImageModalView from 'src/components/grid-image-view/gridmodal';
import 'react-image-gallery/styles/css/image-gallery.css';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { DataType } from 'src/models/dataType.enum';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import {
    TableExportFormat,
    usePDFExport,
    useTableExporter,
    usePDFExportSetter,
} from 'src/components/table-exporter/TableExporterProvider';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { DataTypeFlagFilter } from 'src/templates/data-type-flag-filter/DataTypeFlagFilter';
import { Flag } from 'src/models/flag.enum';
import { Platform } from 'src/models/platforms.enum';
import {
    downloadImages,
    handleDragStart,
    processAndGeneratePDFs,
    toggleFullScreen,
    zoomImage,
} from 'src/utils/common';
import DataTypeUpdateFlagAll from '../data-type-update-flag-all/DatatypeUpdateFlagAll';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';

interface ImageItem {
    id: string,
    date: Date,
    fileId: string,
    fileUrl: string,
    count: number | undefined,
    totalCount: number | undefined,
    onClick: () => void,
    onCheck: (image: ImageItem, checked: boolean) => void,
    aiScanStatus: number,
    alertWords: string[],
    flag: Flag,
    device: string,
    username: string,
    platform: Platform,
    captureStartDate: string,
    captureEndDate: string,
    capturedDate: string,
    receivedDate: string
}
interface ScreenshotDetailsGridProps {
    images: Array<ImageItem>
}

export function ScreenshotDetailsGrid(
    data: ScreenshotData,
    { images = [] }: ScreenshotDetailsGridProps
): React.ReactElement
{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const pdfExportSetter = usePDFExportSetter();
    const screenshotsDataDeviceId = useSelector(selectScreenshotsDeviceId);
    const screenshotDataDeviceId = useSelector(selectScreenshotDeviceId);
    const filterFlagData = useSelector(screenshotFilterFlags);
    const [detailOpen, setDetailOpen] = React.useState(false);
    const [slideshow, setSlideshow] = React.useState(false);
    const [selectedImages, setSelectedImages] = React.useState<Array<ImageItem>>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [pdfType, setPdfType] = React.useState('');
    const { setExportFormat } = useTableExporter();
    const pdfExporter = usePDFExport();
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    const updatedFlag = useSelector(selectUpdatedFlag);
    const [clickedImage, setClickedImage] = useState<ImageItem | undefined>(undefined);
    const screenshotData = useSelector(selectScreenshot);
    const screenshotsStatus = useSelector(selectScreenshotsStatus);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [scale, setScale] = useState(1);
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const filters = useSelector(selectFilters);
    const screenshotModalData = useSelector(selectscreenshotModalData);

    // eslint-disable-next-line
    const handleScroll = (event: any): void => {
        zoomImage(setScale, setImagePosition, event);
    };

    useEffect(() =>
    {
        const updatedFilters = { ...filters, isGridView: true };
        dispatch(changeFilters(updatedFilters));
        dispatch(changescreenshotGridViewFilters(true));
        dispatch(resetaiFlagFilterData('viewData'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        window.addEventListener('wheel', handleScroll);
        return () =>
        {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [isFullScreen]);

    useEffect(() =>
    {
        setIsFullScreen(false);
        setScale(1);
    }, [detailOpen]);

    const dragFunction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void =>
    {
        handleDragStart(scale, imagePosition, setImagePosition, event);
    };

    useEffect(() =>
    {
        dispatch(changeScreenshotDeviceId(screenshotsDataDeviceId?.contents[0]));
        dispatch(changeScreenshot(screenshotsDataDeviceId?.contents[0]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenshotsDataDeviceId, updatedFlag]);

    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        if (tableExportFormat === TableExportFormat.PDF && pdfExporter)
        {
            await TableExporter.asPDF(pdfExporter);
        }
        else
        {
            setExportFormat(tableExportFormat);
        }
        setFormat(undefined);
    }

    const handleSetSelectedImages = (
        image: ImageItem,
        checked: boolean
    ): void =>
    {
        if (image)
        {
            if (checked)
            {
                setSelectedImages((prevSelectedImages: ImageItem[]) => [
                    ...prevSelectedImages,
                    image,
                ]);
                setSelectedFileIds((prevSelectedFileIds: string[]) => [
                    ...prevSelectedFileIds,
                    image.fileId,
                ]);
            }
            else
            {
                setSelectedImages((prevSelectedImages: ImageItem[]) =>
                    prevSelectedImages.filter(
                        (selectedImage) => selectedImage.fileId !== image.fileId
                    ));
                setSelectedFileIds((prevSelectedFileIds: string[]) =>
                    prevSelectedFileIds.filter(
                        (fileId) => fileId !== image.fileId
                    ));
            }
        }
    };



    const handleDetailOpen = (id: string): void =>
    {
        const newIndex = screenshotDataDeviceId?.images.findIndex((image) => image.id === id)
            ?? 0;
        dispatch(changeScreenshot({
            ...data,
            imageIndex: newIndex,
        }));
        setDetailOpen(true);
        const clickedImage = screenshotDataDeviceId?.images.find((image) => image.id === id);
        handleImageClick(clickedImage as unknown as ImageItem);
    };

    const handleImageClick = (image: ImageItem): void =>
    {
        setClickedImage(image);
    };
    const detailItems = [
        {
            label: t('data-type.table.captured-date'),
            text: (data: ScreenshotData) =>
                new Date(
                    data.images[data.imageIndex].clientCreatedAt.toLocaleString()
                ).toLocaleString(),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: ScreenshotData) => data.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: ScreenshotData) => data.username,
        },
        {
            label: t('data-type.table.platform'),
            text: (data: ScreenshotData) => data.platform,
        },
        {
            label: t('data-type.table.number-of-screenshots'),
            text: (data: ScreenshotData) => data.filesCount,
        },
        {
            label: t('data-type.table.alert-word'),
            text: (data: ScreenshotData) => data.images[data.imageIndex].alertWords?.join(', '),
        },
    ];

    const handleOnClick = (type: string): void =>
    {
        if (type === 'selected')
        {
            if (selectedImages.length > 0)
            {
                pdfExportSetter({
                    type: DataType.SCREENSHOTS,
                    recordIds: selectedImages.map((item) => item.id),
                });
                setPdfType('selected');
            }
        }
    };

    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const [load, setLoad] = useState(false);

    useEffect(() =>
    {
        if (pdfType)
        {
            handlerPDFExport(TableExportFormat.PDF);
        }
        setPdfType('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfType]);

    const [loading, setLoading] = useState(true);

    useEffect(() =>
    {
        setTimeout(() =>
        {
            setLoading(false);
        }, 2000);
    }, [screenshotData]);

    if (loading || screenshotsStatus !== 'Success')
    {
        return (
            <div >
                <CircularProgress />
            </div>
        );
    }
    const imageUrl = clickedImage?.fileUrl;

    const filteredImages = screenshotDataDeviceId?.images
        .filter(screen =>
        {
            const alertWordsFilter = screenshotModalData?.alertwordsOnly
                ? (screen?.alertWords && screen.alertWords.length > 0) : true;
            const flagFilter = filterFlagData?.length
                ? (
                    (filterFlagData.includes('aiScanStatus') && screen.aiScanStatus === 2) ||
                    (filterFlagData.includes('NONE') && screen.flag === undefined) ||
                    filterFlagData.includes(screen.flag)
                )
                : true;
            return alertWordsFilter && flagFilter;
        })
        .sort((a, b) =>
        {
            const dateA = new Date(a.clientCreatedAt).valueOf();
            const dateB = new Date(b.clientCreatedAt).valueOf();
            return dateA - dateB;
        });

    const handleAlltoPdf = (): void =>
    {
        if (filteredImages)
        {
            const recordIds = filteredImages?.map((item) => item.id);
            processAndGeneratePDFs(
                recordIds?recordIds:[],
                DataType.SCREENSHOTS,
                setDownloadPercentage,
                null,
                setLoad,
                null
            );
        }
    };

    const getStartIcon = (loading: boolean): JSX.Element =>
    {
        return loading ? <CircularProgress size="1rem" /> : <DownloadIcon />;
    };

    return (
        <>
            <DataDetailsModal<ScreenshotData>
                title={t('data-type.details.screenshots-title')}
                open={detailOpen}
                data={screenshotData}
                detailItems={detailItems}
                onClose={() => setDetailOpen(false)}
                comments={{
                    contentId: clickedImage?.id,
                    type: CommentType.SCREENSHOTS,
                }}
            >

                {screenshotDataDeviceId && (
                    <>
                        <Button
                            variant="text"
                            startIcon={getStartIcon(isLoading)}
                            onClick={() =>
                            {
                                if (imageUrl)
                                {
                                    downloadImages(
                                        imageUrl,
                                        setIsLoading,
                                        null,
                                        clickedImage?.fileId
                                    );
                                }
                            }}
                        >
                            JPEG
                        </Button>
                        {isFullScreen ? (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 999,
                                    cursor: 'pointer',
                                }}
                                onMouseDown={dragFunction}
                            >
                                <button
                                    onClick={() =>
                                    {
                                        toggleFullScreen(
                                            isFullScreen,
                                            setIsFullScreen,
                                            setScale,
                                            setImagePosition
                                        );
                                    }}
                                    style={{
                                        position: 'fixed',
                                        top: '5%',
                                        right: '5%',
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontSize: 'xx-large',
                                        color: 'white',
                                        zIndex: 999,
                                    }}
                                >
                                    X
                                </button>
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        transform: `scale(${scale}) 
                                        translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                                        transition: 'transform 0.3s',
                                        cursor: imagePosition.x > 0 || imagePosition.y > 0 ?
                                            'grabbing' : 'pointer',
                                    }}
                                    src={clickedImage?.fileUrl}
                                    alt={''}
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                {
                                    toggleFullScreen(
                                        isFullScreen,
                                        setIsFullScreen,
                                        setScale,
                                        setImagePosition
                                    );
                                }}
                                onKeyDown={(e) => e.preventDefault()}
                            >
                                <img
                                    style={{ width: '100%', height: 'auto' }}
                                    src={clickedImage?.fileUrl}
                                    alt={''}
                                />
                            </div>
                        )}
                    </>
                )}
            </DataDetailsModal>
            <div style={{ width: '100%', height: 'auto' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: '40%',
                        }}
                    >
                        <Button
                            variant="text"
                            startIcon={getStartIcon(load)}
                            onClick={() =>
                            {
                                setLoad(true);
                                handleAlltoPdf();
                            }}
                        >
                            {t('common.button.all-to-pdf')}
                            {' '}
                            {load === true && Math.round(downloadPercentage) + '%'}
                        </Button>
                        <Button
                            variant="text"
                            startIcon={getStartIcon(isLoading)}
                            onClick={() =>
                            {
                                downloadImages(
                                    filteredImages,
                                    setIsLoading
                                );
                            }}
                        >
                            {t('common.button.all-to-jpeg')}
                        </Button>
                        {selectedImages.length > 0 ? (
                            <>
                                <Button
                                    variant="text"
                                    startIcon={TableExporter.loading
                                    && format === TableExportFormat.PDF
                                        ? <CircularProgress size="1rem" />
                                        : <DownloadIcon />
                                    }
                                    onClick={(e) =>
                                    {
                                        handleOnClick('selected');
                                    }}
                                >
                                    {t('common.button.selected-to-pdf')}
                                </Button>
                                <Button
                                    variant="text"
                                    startIcon={getStartIcon(selectLoading)}
                                    onClick={() =>
                                    {
                                        downloadImages(
                                            selectedImages,
                                            null,
                                            setSelectLoading
                                        );
                                    }}
                                >
                                    {t('common.button.selected-to-jpeg')}
                                </Button>
                            </>
                        )
                            : null}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            justifyContent: 'right',
                            alignItems: 'center',
                            gap: '1rem',
                            marginRight: '1rem',
                            width: '60%',
                        }}
                    >
                        {(screenshotDataDeviceId?.images
                            .some(image => image.alertWords?.length > 0)) ?
                            (
                                <Button
                                    variant="contained"
                                    sx={{ float: 'right' }}
                                    onClick={(e) =>
                                    {
                                        dispatch(changeScreenshotDetailsFilter({
                                            alertwordsOnly: !screenshotModalData?.alertwordsOnly,
                                        }));
                                    }}
                                >
                                    {!screenshotModalData?.alertwordsOnly
                                        ? t('form.View-alert-words')
                                        : t('form.No-alert-words')}
                                </Button>
                            ) : null
                        }

                        <div
                            style={{
                                width: '30%',
                                float: 'right',
                                margin: 5,
                            }}
                        >
                            <DataTypeFlagFilter />
                        </div>
                        <div
                            style={{
                                width: '30%',
                                float: 'right',
                                margin: 5,
                            }}
                        >
                            <DataTypeUpdateFlagAll
                                dataType={DataType.SCREENSHOTS}
                                count={filteredImages?.length}
                            />
                        </div>
                        <div
                            style={{
                                width: '20%',
                                float: 'right',
                                margin: 5,
                            }}
                        >
                            <FormControlLabel
                                control={(
                                    <ToggleSwitch
                                        sx={{ m: 2 }}
                                        checked={slideshow}
                                        onChange={(_, value) =>
                                        {
                                            setTimeout(()=>
                                            {
                                                setSlideshow(!slideshow);
                                            }, 100);
                                            changeFilters({ slideshow: value });
                                        }}
                                    />
                                )}
                                name="Slideshow"
                                label={t('form.slideshow')}
                            />
                        </div>
                    </Box>
                </Box>
                {filteredImages && screenshotDataDeviceId && filteredImages.length > 0 ? (
                    <GridImageModalView
                        images={
                            filteredImages
                                .map((screen, index) => ({
                                    id: screen.id,
                                    date: screen.clientCreatedAt,
                                    fileId: screen.fileId,
                                    fileUrl: screen.fileUrl,
                                    count: index + 1,
                                    totalCount: filteredImages.length,
                                    onClick: () => handleDetailOpen(screen.id),
                                    onCheck: handleSetSelectedImages,
                                    aiScanStatus: screen.aiScanStatus,
                                    alertWords: screen.alertWords || [],
                                    flag: screen.flag,
                                    device: screenshotDataDeviceId.device,
                                    deviceId: screenshotDataDeviceId.deviceId,
                                    username: screenshotDataDeviceId.username,
                                    platform: screenshotDataDeviceId.platform,
                                    captureStartDate: screenshotDataDeviceId.captureStartDate,
                                    captureEndDate: screenshotDataDeviceId.captureEndDate,
                                    capturedDate: screenshotDataDeviceId.capturedDate,
                                    receivedDate: screenshotDataDeviceId.receivedDate,
                                    comments: screen.comments,
                                })) ?? []}
                        alertWords={!screenshotModalData?.alertwordsOnly}
                        selectedFileIds={selectedFileIds}
                        slideshow={slideshow}
                    />
                ) : (
                    <Typography
                        variant="h3"
                        sx={{
                            color: (theme) => theme.palette.common.lightGrey,
                            margin: '4rem auto',
                            textAlign: 'center',
                        }}
                    >
                        {t('data-type.table.no-content')}
                    </Typography>
                )}
            </div>
        </>
    );
}
