import {
    AlertsDetailsParams,
    ClientFilterOptionsParams,
    DeviceFilterOptionsParams,
    FilterOption,
    DeviceRecordListParams,
    OfficerFilterOptionsParams,
    UpdateSeenListStatusParams,
    UpdateSeenStatusParams,
    UpdateSeenListData,
} from 'src/models/common/filterOptionData.model';
import { Role } from 'src/models/roles.enum';
import { transformPaginationParams } from '../pagination';
import { Platform } from 'src/models/platforms.enum';

export interface APIAgencyFilterEntry {
    id: string;
    agencyName: string;
}
export interface APIOfficerOrClientFilterEntry {
    id: string;
    fullName: string;
}

export interface APIDeviceFilterEntry {
    id: string;
    deviceAlias: string;
}
export interface APIDeviceVersionFilterEntry {
    version: string;
}
export interface APIOfficerFilterOptionsParams {
    role?: Role;
    agencyId?: string | null;
}
export interface APIClientFilterOptionsParams {
    agencyId?: string | null;
    officerId?: string | null;
    isDisableRecords?: boolean;
}

export interface APIDeviceFilterOptionsParams {
    agencyId?: string | null;
    officerId?: string | null;
    clientId?: string | null;
    isDisableRecords?: boolean;
    searchValue?: string
    details?: boolean;
    platformValue?:Platform;
}


export interface APIAlertsDetailsParams{
    alertsId :string;
    dataType : string;
}
export interface APIUpdateSeenStatusParams{
    alertsId :string;
    dataType : string;
}
export interface APIUpdateSeenListStatusParams{
    detailsId? :string;
    dataType? : string;
}
export interface UserSeenListResponse{
    userIds : string[]
    detailsId : string
}
export function transformOfficerFilterOptionsParams(
    { role, agencyId }: OfficerFilterOptionsParams
): APIOfficerFilterOptionsParams
{
    return { role, agencyId };
}

export function transformClientFilterOptionsParams(
    { agencyId, officerId,isDisableRecords }: ClientFilterOptionsParams
): APIClientFilterOptionsParams
{
    return { agencyId, officerId, isDisableRecords};
}

export function transformDeviceFilterOptionsParams(
    { agencyId, officerId, clientId, isDisableRecords }: DeviceFilterOptionsParams
): APIDeviceFilterOptionsParams
{
    return { agencyId, officerId, clientId, isDisableRecords };
}

export function transformDeviceRecordListParams(
    {
        agencyId,
        officerId,
        clientId,
        searchValue,
        details,
        platformValue,
        ...paginatedParams
    }: DeviceRecordListParams
): APIDeviceFilterOptionsParams
{
    return {
        agencyId,
        officerId,
        clientId,
        searchValue,
        platformValue,
        details,
        ...transformPaginationParams(paginatedParams),
    };
}

export function transformAlertsDetailsParams(
    { alertsId, dataType } : AlertsDetailsParams
): APIAlertsDetailsParams
{
    return { alertsId, dataType };
}

export function transformUpdateSeenStatusParams(
    { alertsId, dataType } : UpdateSeenStatusParams
): APIUpdateSeenStatusParams
{
    return { alertsId, dataType };
}
export function transformUpdateSeenListStatusParams(
    { detailsId, dataType } : UpdateSeenListStatusParams
): APIUpdateSeenListStatusParams
{
    return { detailsId, dataType };
}

export function readAPIAgencyFilterResponse(response: APIAgencyFilterEntry[]): FilterOption[]
{
    return response.map(({ id, agencyName }) => ({
        value: id,
        name: agencyName,
    }));
}

export function readAPIOfficerOrClientFilterResponse(response: APIOfficerOrClientFilterEntry[])
    : FilterOption[]
{
    return response.map(({ id, fullName }) => ({
        value: id,
        name: fullName,
    }));
}

export function readAPIDeviceFilterResponse(response: APIDeviceFilterEntry[]): FilterOption[]
{
    return response.map(({ id, deviceAlias }) => ({
        value: id,
        name: deviceAlias,
    }));
}
export function readAPIDeviceVersionFilterResponse
(response: APIDeviceVersionFilterEntry[]): FilterOption[]
{
    return response.map(({ version }) => ({
        value: version,
        name: version,
    }));
}

export function readUserSeenListResponse(data: UserSeenListResponse ) : UpdateSeenListData
{
    const { userIds, detailsId } = data;
    return {
        userIds,
        detailsId,
    };
}

