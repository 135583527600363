import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Theme, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuItems from './MenuItems';
import { TopBar } from '../top-bar/TopBar';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

export interface MenuItemData {
  title: string;
  mainTitle?: boolean;
  submenu?: MenuItemData[];
  keyPath: string;
  element?: React.ReactElement;
}

export interface SideBarMenuOptionProps {
  defaultMenuItem: string;
  menuItems: MenuItemData[],
  initialBreadcrumbs: string[];
  topbar?: boolean;
  search?: boolean;
  sideBarTitle?: string;
  onMenuChange?: (keyPath: string) => void;
}

const containerBoxStyle = {
  height: '100%',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
};

export function SideBarMenuOption({
  defaultMenuItem = '',
  menuItems,
  initialBreadcrumbs,
  topbar = true,
  search = true,
  sideBarTitle,
  onMenuChange,
}: Readonly<SideBarMenuOptionProps>): React.ReactElement {
  const [searchValue, setSearchValue] = useState('');
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(defaultMenuItem);
  const [selectedElement, setSelectedElement] = useState<React.ReactElement | null>();

  const findElement = (items: MenuItemData[], keyPath: string): React.ReactElement | null => {
    for (const item of items) {
        if (item.keyPath === keyPath && item.element) {
            return item.element;
        }
        if (item.submenu) {
            const matched = findElement(item.submenu, keyPath);
            if (matched) return matched;
        }
    }
    return null;
};

  useEffect(() => {
    const defaultElement = findElement(menuItems, defaultMenuItem);
    if (defaultElement) {
        setSelectedElement(defaultElement);
    }
  }, [defaultMenuItem])

  const handleSearch = (value: string): void => setSearchValue(value);
  const handleMenuItemClick = (keyPath: string, element?: React.ReactElement): void => {
    if (keyPath && element) setSelectedMenuItem(keyPath);
    if (element) {
      setSelectedElement(element);
      onMenuChange?.(keyPath);
    }
  };

  const filterMenuItems = (menuItems: MenuItemData[], searchValue: string): MenuItemData[] => {
    return menuItems
      .map((menu) => {
        const matchesCurrentMenu = menu.title.toLowerCase().includes(searchValue.toLowerCase());
        const filteredSubmenu = menu.submenu ? filterMenuItems(menu.submenu, searchValue) : [];

        if (matchesCurrentMenu || filteredSubmenu.length > 0) {
          return {
            ...menu,
            submenu: filteredSubmenu,
          };
        }
        return null;
      })
      .filter((menu) => menu !== null) as MenuItemData[];
  };

  const filteredMenuItems = filterMenuItems(menuItems, searchValue);

  return (
    <>
      {topbar && (
        <TopBar
          customStyle={{
            fontSize: '1.25rem',
            fontWeight: 700,
            color: (theme: Theme) => theme.palette.common.lightGrey,
            width: '80%',
            lineHeight: 1,
            alignItems: 'center',
            '@media (max-width: 768px)':
            {
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              lineHeight: 1,
            },
          }}
          labels={[
            ...initialBreadcrumbs,
            ...selectedMenuItem.split(',').map((item) => item
              .split('_')
              .map((word) => word[0]?.toUpperCase() + word?.slice(1))
              .join(' ')
            )]}
          icon={<SettingsRoundedIcon fontSize="large" />}
        />
      )}

      <Box display="flex">
        {/* Left Sidebar */}
        <Box width="19%"
          sx={{
            overflow: 'hidden',
            height: '100vh',
          }}
        >
          <Box sx={containerBoxStyle}>

            {sideBarTitle && (
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{
                    display: 'inline-block',
                    fontWeight: '700',
                    borderBottom: '3px solid goldenrod',
                    letterSpacing: '0.00938em',
                    textTransform: 'uppercase'
                  }}
                >
                  {sideBarTitle}
                </Typography>
              </Box>
            )}

            {search && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  margin: '8px 0',
                }}
              >
                <Box sx={{ width: '85%' }}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box component="nav">
              {filteredMenuItems?.map((menu) => (
                <MenuItems
                  key={menu.title}
                  items={menu}
                  handleClick={handleMenuItemClick}
                  selectedKey={selectedMenuItem}
                />
              ))}
            </Box>
          </Box>
        </Box>

        {/* Right Content */}
        <Box
          width="81%"
          sx={{
            padding: 2,
            overflow: 'hidden',
            height: '100vh',
          }}
        >
          <Box sx={containerBoxStyle}>
            <Box>
              {selectedElement || <> </>}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
