export enum DefaultTimings {
  start = "00:00",
  end = "23:59"
}

export enum BlockScheduleType {
  blocked24hrs = "Blocked 24 hrs",
  disabled = "Disabled"
}

export enum WeekDays {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export const daysOfWeek = [
  { label: "S", value: WeekDays.SUNDAY },
  { label: "M", value: WeekDays.MONDAY },
  { label: "T", value: WeekDays.TUESDAY },
  { label: "W", value: WeekDays.WEDNESDAY },
  { label: "T", value: WeekDays.THURSDAY },
  { label: "F", value: WeekDays.FRIDAY },
  { label: "S", value: WeekDays.SATURDAY },
];

