export enum AdminSettings {
    SuggestedAlertWords,
    ProfileManagement,
    Users,
    AgentManagement,
    VersionManagement,
    Extensions,
    DeviceConfigurationManagement,
    Instruction,
    DeviceInformation,
    DeviceCacheManagement,
}

export const AdminSettingsSideMenu = {
    DeviceConfigurationManagement: 'device_configuration_management',
    ResourceManagement: 'resource_management',
    DeviceCacheManagement: 'device_cache_management',
    DeviceInformation: 'device_information',
    ExtensionManagement: 'extension_management',
    InstructionManagement: 'instruction_management',
    ProfileManagement: 'profile_management',
    SuggestedAlertWords: 'suggested_alert_words',
    UserAgentManagement: 'user_agent_management',
    UsersManagement: 'users_management',
    VersionManagement: 'version_management',
    
    SubMenus: {
        DeviceConfigurationManagement: {
            ConfigurationServices: 'device_configuration_management,configuration_services',
            BlockApps: 'device_configuration_management,block_apps',
            BlockUrls: 'device_configuration_management,block_urls',
            DeviceAccessSettings: 'device_configuration_management,device_access_settings',
        },
        ResourceManagement: {
            Categories: 'resource_management,categories',
            Applications: 'resource_management,applications',
            Urls: 'resource_management,urls',
        },
    }
};
