import React, { useEffect } from 'react';
import { Header } from 'src/templates/header/Header';
import { TopBar } from 'src/components/top-bar/TopBar';
import {
    Box,
    Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AWLContext } from 'src/templates/awl-creation-tabs/AWLCreationTabs';
import { Role } from 'src/models/roles.enum';
import { selectRole } from 'src/state/authorization/authorizationSlice';
import { AWLTableContainerProps } from 'src/templates/awl-table-container/AWLTableContainer';
import {
    AgencyAWLConfigurationContextProvider,
    useGetConfiguration,
} from 'src/templates/awl-index-tabs/AgencyAWLConfigurationContext';
import { ProfilesTable } from 'src/templates/profiles-table/ProfilesTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AWL_SUGGESTED_NEW } from 'src/utils/routes';
import { SuggestedAWLTable } from 'src/templates/suggested-awl-table/SuggestedAWLTable';
import { UsersTable } from 'src/templates/users-table/UsersTable';
import { UserAgents } from 'src/templates/user-agents/UserAgents';
import { VersionManagement } from 'src/templates/version-management/VersionManagement';
import { Extensions } from 'src/templates/extension-page/Extension';
import Grid from '@mui/material/Grid';
import {
    AdminSettingSearchFilter,
} from 'src/templates/admin-setting-search-filter/AdminSettingSearchBar';
import {
    DeviceConfigurationManagement,
} from 'src/templates/device-configuration-management/DeviceConfigurationManagement';
import { User } from 'src/models/userModels';
import { Officer } from 'src/models/administration/officerData.model';
import { InstructionTable } from 'src/templates/instruction/InstructionTable';
import { DeviceInformationPage } from 'src/templates/device-information/DeviceInformationPage';
import { DeviceCacheManagement } from 'src/templates/device-cache-management/DeviceCacheManagement';
import { CreateButton } from 'src/components/top-bar/CreateButton';
import { MenuItemData, SideBarMenuOption } from 'src/components/side-bar-menu-option/SideBarMenuOption';
import { DeviceConfigurationSettings } from 'src/models/deviceConfigurationSettings.enum';
import { ApplicationsListTable } from 'src/templates/resource-management/applications-list-table/ApplicationsListTable';
import { CategoriesListTable } from 'src/templates/resource-management/categories-list-table/CategoriesListTable';
import { UrlsListTable } from 'src/templates/resource-management/urls-list-table/UrlsListTable';
import { clearSearchValue } from 'src/state/administration/administrationSlice';
import { clearDeviceInfoSearchValue, selectAdminSettingsSideMenu, setAdminSettingsSideMenu } from 'src/state/administrationMeta/administrationMetaSlice';
import { AdminSettingsSideMenu } from 'src/models/adminSettings.enum';
import TableExporterProvider from 'src/components/table-exporter/TableExporterProvider';

export interface UserTableProps {
    roles?: Role[]
    onEdit?: (user: User) => void
    onConfig?: (user: User) => void
    onAlert?: (user: Officer) => void
}
type AgencyAlertWordsParams = {
    id: string;
}

export function AdminSettingsManagement(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams<AgencyAlertWordsParams>();
    const role = useSelector(selectRole);
    const getConfiguration = useGetConfiguration();
    const context = AWLContext.Suggested;
    const adminSettingsSideMenu = useSelector(selectAdminSettingsSideMenu);

    const tableContainerProps: AWLTableContainerProps<typeof context> = {
        type: context,
    };
    useEffect(() => {
        if (id) {
            dispatch(getConfiguration(id));
        }
    }, [dispatch, getConfiguration, id]);

    if (role) {
        tableContainerProps.canAdd = [
            Role.Administrator,
            Role.Employee,
        ].includes(role);
        tableContainerProps.tableProps = {
            editable: role === Role.Administrator,
            toggleable: role === Role.OfficerSupervisor,
        };
    }


    const getSuggestedAlertWordsElement = (): React.ReactElement => (
        <AgencyAWLConfigurationContextProvider>
            <Stack
                direction="row"
                justifyContent="flex-end"
            >
                <Box sx={{ mt: 2.5, mr: 3 }} >
                    <Grid
                        item
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <AdminSettingSearchFilter />
                    </Grid>
                </Box>
                <TopBar
                    buttons={[
                        {
                            text: (
                                <CreateButton
                                    width="140px"
                                    label={t('alert-word.Add-new-SAW')}
                                />
                            ),
                            icon: <AddCircleIcon />,
                            onClick: () => navigate(AWL_SUGGESTED_NEW),
                        },
                    ]}
                />
            </Stack>
            <SuggestedAWLTable {...tableContainerProps.tableProps} />
        </AgencyAWLConfigurationContextProvider>
    );

    const getDeviceCacheElement = (): React.ReactElement => (
        <TableExporterProvider>
           <DeviceCacheManagement />
        </TableExporterProvider>
    );


    const AdminSettingsMenuItems: MenuItemData[] = [
        {
            title: t('settings.device-configuration-management'),
            keyPath: AdminSettingsSideMenu.DeviceConfigurationManagement,
            mainTitle: true,
            submenu: [
                {
                    title: t('settings.configuration-services'),
                    keyPath: AdminSettingsSideMenu.SubMenus.DeviceConfigurationManagement.ConfigurationServices,
                    element: <DeviceConfigurationManagement type={DeviceConfigurationSettings.ConfigurationServices} />,
                },
                {
                    title: t('settings.block-apps'),
                    keyPath: AdminSettingsSideMenu.SubMenus.DeviceConfigurationManagement.BlockApps,
                    element: <DeviceConfigurationManagement type={DeviceConfigurationSettings.BlockApps} />
                },
                {
                    title: t('settings.block-urls'),
                    keyPath: AdminSettingsSideMenu.SubMenus.DeviceConfigurationManagement.BlockUrls,
                    element: <DeviceConfigurationManagement type={DeviceConfigurationSettings.BlockUrls} />
                },
                {
                    title: t('settings.device-access-settings'),
                    keyPath: AdminSettingsSideMenu.SubMenus.DeviceConfigurationManagement.DeviceAccessSettings,
                    element: <DeviceConfigurationManagement type={DeviceConfigurationSettings.DeviceAccessSettings} />
                },
            ],
        },
        {
            title: t('settings.resource-management'),
            keyPath: AdminSettingsSideMenu.ResourceManagement,
            mainTitle: true,
            submenu: [
                {
                    title: t('settings.categories'),
                    keyPath: AdminSettingsSideMenu.SubMenus.ResourceManagement.Categories,
                    element: <CategoriesListTable />,
                },
                {
                    title: t('settings.applications'),
                    keyPath: AdminSettingsSideMenu.SubMenus.ResourceManagement.Applications,
                    element: <ApplicationsListTable />
                },
                {
                    title: t('settings.urls'),
                    keyPath: AdminSettingsSideMenu.SubMenus.ResourceManagement.Urls,
                    element: <UrlsListTable />
                },
            ],
        },
        {
            title: t('settings.device-cache-management'),
            mainTitle: true,
            keyPath: AdminSettingsSideMenu.DeviceCacheManagement,
            element: getDeviceCacheElement(),
        },
        {
            title: t('settings.device-information'),
            mainTitle: true,
            keyPath: AdminSettingsSideMenu.DeviceInformation,
            element: <DeviceInformationPage />,
        },
        {
            title: t('settings.extension-management'),
            keyPath: AdminSettingsSideMenu.ExtensionManagement,
            element: <Extensions />,
            mainTitle: true,
        },
        {
            title: t('menu.instruction'),
            mainTitle: true,
            keyPath: AdminSettingsSideMenu.InstructionManagement,
            element: <InstructionTable />,
        },
        {
            title: t('menu.profile-management'),
            mainTitle: true,
            keyPath: AdminSettingsSideMenu.ProfileManagement,
            element: <ProfilesTable />,
        },
        {
            title: t('settings.suggested-alert-words'),
            mainTitle: true,
            keyPath: AdminSettingsSideMenu.SuggestedAlertWords,
            element: getSuggestedAlertWordsElement(),
        },
        {
            title: t('settings.agent-management'),
            mainTitle: true,
            keyPath: AdminSettingsSideMenu.UserAgentManagement,
            element: <UserAgents />,
        },
        {
            title: t('settings.users-management'),
            mainTitle: true,
            keyPath: AdminSettingsSideMenu.UsersManagement,
            element: <UsersTable enableCreate={true} />,
        }, {
            title: t('settings.version-management'),
            mainTitle: true,
            keyPath: AdminSettingsSideMenu.VersionManagement,
            element: <VersionManagement />,
        },
    ];

    const onChangeMenuItem = (keyPath: string): void => {
        if(keyPath) dispatch(setAdminSettingsSideMenu(keyPath));
        dispatch(clearSearchValue());
        dispatch(clearDeviceInfoSearchValue());
    }

    return (
        <>
            <Header />
            <Box sx={{ margin: '8px 0 0 8px' }}>
                <Box>
                    <SideBarMenuOption
                        defaultMenuItem={adminSettingsSideMenu}
                        menuItems={AdminSettingsMenuItems}
                        initialBreadcrumbs={[t('menu.settings'), t('menu.admin-settings')]}
                        sideBarTitle={t('menu.admin-settings')}
                        onMenuChange={onChangeMenuItem}
                    />
                </Box>
            </Box>
        </>
    );
}
