import { createTheme } from '@mui/material';

const customTheme = {
    palette: {
        primary: {
            main: '#525252',
            // light: `#66B2FF`,
            // dark: `#0059B2`,
        },
        secondary: {
            main: '#FFFFFF',
            // light: `#f3e5f5`,
            // dark: `#ab47bc`,
        },
        error: {
            main: '#EB5757',
            // light: `#F7CDCD`,
            // dark: `#ED4848`,
        },
        success: {
            main: '#56C568',
            contrastText: '#FFFFFF',
            // light: `#CAF7D1`,
            // dark: `#42C157`,
        },
        warning: {
            main: '#FFC400',
            // light: `#FDEEBE`,
            // dark: `#FFB800`,
        },
        info: {
            main: '#3FA2F7',
            // light: `#C4E4FF`,
            // dark: `#0077DC`,
        },
        background: {
            default: '#FFFFFF',
        },

        common: {
            black: '#000000',
            shadowBlack: 'rgb(24, 24, 24)',
            white: '#FFFFFF',
            lightGrey: '#BDBDBD',
            darkGrey: '#444444',
            goldYellow: '#D4AF37',
            softGrey: '#E8E8E8',
            lightGreen: '#65C466',
            darkGreen: '#2ECA45',
            lightSwitch: '#E9E9EA',
            darkSwitch: '#39393D',
        },
        text: {
            primary: '#444444',
        },
    },
    typography: {
        // allVariants: {
        //     color: `#444`,
        // },
        h1: {
            fontWeight: 900,
            fontSize: '4.0625rem', // 65px
        },
        h2: {
            fontWeight: 700,
            fontSize: '2.5rem', // 40px
        },
        h3: {
            fontWeight: 700,
            fontSize: '1.5rem', // 24px
        },
        h4: {
            fontWeight: 500,
            fontSize: '1.5rem', // 24px
        },
        h5: {
            fontWeight: 500,
            fontSize: '0.875rem', // 14px
            textTransform: 'uppercase' as const,
        },
        h6: {
            fontWeight: 500,
            fontSize: '0.8125rem', // 13px
            textTransform: 'uppercase' as const,
        },
        // h7: {
        //     fontWeight: 400,
        //     fontSize: `0.875rem`, // 14px
        //     textTransform: `uppercase` as const,
        // },
        body1: {
            fontWeight: 400,
            fontSize: '0.8125rem', // 14px
        },
        button: {
            fontWeight: 400,
            fontSize: '0.9375rem', // 15px
            textTransform: 'none' as const,
        },
        link: {
            fontWeight: 400,
            fontSize: '0.8125rem', // 14px
            color: '#D4AF37',
            lineHeight: 1.44,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#444444',
                    color: '#FFFFFF',
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                variant: 'scrollable' as const,
                scrollButtons: 'auto' as const,
            },
            styleOverrides: {
                indicator: {
                    height: '3px',
                    backgroundColor: '#D4AF37',
                },
                root: {
                    '& .MuiTab-root:not(.Mui-selected)': {
                        color: '#BDBDBD',
                        borderBottom: '1px solid #BDBDBD',
                    },
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                size: 'small' as const,
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: 'small' as const,
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    zIndex: 1500,
                },
            },
        },
    },
    zIndex: {
        appBar: 1301, // just enough to be above modals
        modal: 1500,
    },
};

export default createTheme(customTheme);
