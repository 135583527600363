import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/data-table/DataTable';
import { BlockedUserAgent } from 'src/models/administration/blockedUserAgentData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getUserAgents,
    selectUserAgents,
    selectUserAgentsStatus,
    updateUserAgents,
    selectUserAgentUpdateStatus,
    resetUserAgentstatus,
    selectAdminFilters,
} from 'src/state/administration/administrationSlice';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { SnackbarProps, Snackbar, Stack, Box, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import { AdminSettingSearchFilter } from '../admin-setting-search-filter/AdminSettingSearchBar';

export function UserAgents(): React.ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectUserAgentsStatus);
    const userAgents = useSelector(selectUserAgents);
    const updateStatus = useSelector(selectUserAgentUpdateStatus);
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const filters = useSelector(selectAdminFilters);

    function onPageChange(pageNumber: number): void {
        dispatch(getUserAgents({ pageNumber, entriesPerPage, ...filters }));
        setCurrentPage(pageNumber);
    }

    function onToggleChange(isBlocked: boolean, id: string): void {
        dispatch(updateUserAgents({ id, isBlocked }));
    }

    useEffect(() => {
        dispatch(getUserAgents({ pageNumber: 1, entriesPerPage, ...filters }));
    }, [dispatch, entriesPerPage, filters]);

    useEffect(() => {
        dispatch(resetUserAgentstatus());
        let message = '';

        if (updateStatus === RequestStatus.Success) {
            message = t('common.messages.user-agent-update');
        }
        else if (updateStatus === RequestStatus.Failure) {
            message = t('common.messages.something-wrong');
        }
        if (message) {
            setNotificationState({ open: true, message });

            if (updateStatus === RequestStatus.Success) {
                setTimeout(() => {
                    dispatch(getUserAgents({
                        pageNumber: currentPage,
                        entriesPerPage,
                        ...filters,
                    }));
                }, 500);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, updateStatus, currentPage, entriesPerPage, filters]);

    function onCloseNotification(): void {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    const getToggleColumn = (data: BlockedUserAgent): React.ReactNode => (
        <ToggleSwitch
            sx={{ m: 1 }}
            checked={data.isBlocked}
            disabled={false}
            onChange={(_, value) => onToggleChange(value, data.id)}
        />
    );

    const columns = [
        {
            label: t('common.nouns.agent'),
            size: 1,
            value: (data: BlockedUserAgent) => data.userAgent,
        },
        {
            label: t('common.actions.toggle'),
            size: 1,
            format: (data: BlockedUserAgent) => getToggleColumn(data),
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-end"
                padding={'1em 0'}
            >
                <Box>
                    <Grid
                        item
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <AdminSettingSearchFilter />
                    </Grid>
                </Box>
            </Stack>
            <DataTable<BlockedUserAgent>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...userAgents}
            />
            <Snackbar
                autoHideDuration={2000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={updateStatus === RequestStatus.Failure ? 'error' : 'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
