import { IconButton } from '@mui/material';
import { ControlButtonProps } from '../slider/ImageSlider';
import React from 'react';

export const ControlButton = ({ children, onClick, sx }: ControlButtonProps):JSX.Element =>
    (
        <IconButton
            onClick={onClick}
            sx={{
                position: 'absolute',
                zIndex: 10,
                opacity: 1,
                transition: 'opacity 0.3s ease-in-out',
                color: (theme) => theme.palette.common.white,
                ...sx,
            }}
        >
            {children}
        </IconButton>
    );
