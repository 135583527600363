import React, { useState, useEffect } from 'react';
import { Button, Box, Grid, Stack } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/data-table/DataTable';
import { Profile } from 'src/models/administration/profileData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getProfiles,
    selectProfileCreationStatus,
    selectProfileUpdateStatus,
    selectProfiles,
    selectProfilesStatus,
    selectProfiledeleteStatus,
    resetProfileErrorValue,
    selectAdminFilters,
    resetProfileStatus,
} from 'src/state/administration/administrationSlice';
import { EditProfile } from 'src/pages/administration/profiles/edit-profile/EditProfile';
import DeleteProfile from './DeleteProfile';
import { Modal } from 'src/components/modal/Modal';
import { AdminSettingSearchFilter } from '../admin-setting-search-filter/AdminSettingSearchBar';
import { NewProfile } from 'src/pages/administration/profiles/new-profile/NewProfile';

export function ProfilesTable(): React.ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectProfilesStatus);
    const profiles = useSelector(selectProfiles);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const filters = useSelector(selectAdminFilters);
    const creationStatus = useSelector(selectProfileCreationStatus);
    const updateStatus = useSelector(selectProfileUpdateStatus);
    const deleteStatus = useSelector(selectProfiledeleteStatus);
    const [open, setOpen] = React.useState(false);
    const [openCreateModal, setOpenCreateModal] = React.useState(false);
    
    const [id, setId] = useState<string>();
    const [type, setType] = useState<string>();

    function closeModal(): void {
        setOpen(false);
    }
    function closeCreateModal(): void {
        setOpenCreateModal(false);
    }
    function onPageChange(pageNumber: number): void {
        dispatch(getProfiles({ pageNumber, entriesPerPage, ...filters }));
    }

    useEffect(() => {
        dispatch(getProfiles({ pageNumber: 1, entriesPerPage, ...filters }));
    }, [dispatch,
        entriesPerPage,
        filters,
        // eslint-disable-next-line
        creationStatus === RequestStatus.Success, updateStatus === RequestStatus.Success, deleteStatus === RequestStatus.Success]);

    const getEditColumn = (id: string): React.ReactNode => (
        <Button
            onClick={() => {
                setId(id);
                setOpen(true);
                setType('edit');
                dispatch(resetProfileErrorValue());
                dispatch(resetProfileStatus());
            }}
        >
            <ModeEditIcon />
        </Button>
    );
    const getDeleteColumn = (id: string): React.ReactNode => (
        <Button
            onClick={() => {
                setId(id);
                setOpen(true);
                setType('delete');
            }}
        >
            <DeleteIcon />
        </Button>
    );

    const columns = [
        {
            label: t('common.nouns.id'),
            size: 1,
            value: (data: Profile) => data.id,
        },
        {
            label: t('common.nouns.name'),
            size: 2,
            value: (data: Profile) => data.name,
        },
        {
            label: t('common.nouns.description'),
            size: 3,
            value: (data: Profile) => data.description,
        },
        {
            label: t('common.actions.edit'),
            size: 1,
            format: ({ id }: Profile) => getEditColumn(id),
        },
        {
            label: t('common.actions.delete'),
            size: 1,
            format: ({ id }: Profile) => getDeleteColumn(id),
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-end"
                padding={'1em 0'}
            >

                <Box sx={{ mr: 3 }}>
                    <Grid
                        item
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <AdminSettingSearchFilter />
                    </Grid>
                </Box>

                <Button
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                        setOpenCreateModal(true);
                        dispatch(resetProfileErrorValue());
                        dispatch(resetProfileStatus());
                    }
                    }
                >
                    {t('profiles.new-profile')}
                </Button>
            </Stack>
            <DataTable<Profile>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...profiles}
            />
            <Modal
                title={type === 'edit' ? t('profiles.edit-profile') : t('profiles.delete-profile')}
                open={open}
                onClose={closeModal}
                showCloseButton={true}
                maxWidth="md"
                fullWidth
            >
                <Box>
                    {type === 'edit' ?
                        <EditProfile onCloseModal={closeModal} id={id} /> :
                        <DeleteProfile DeleteModal={closeModal} open={open} id={id} />
                    }
                </Box>
            </Modal>
            <Modal
                title={t('profiles.new-profile')}
                open={openCreateModal}
                onClose={closeCreateModal}
                showCloseButton={true}
                maxWidth="md"
                fullWidth
            >
                <Box>
                    <NewProfile onCloseModal={closeCreateModal} />
                </Box>
            </Modal>
        </>
    );
}
