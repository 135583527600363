import React, { useState } from 'react';
import { List, ListItemButton, ListItemText, Collapse, Box, Theme } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItemData } from './SideBarMenuOption';

interface MenuItemsProps {
  items: MenuItemData;
  handleClick: (keyPath: string, element?: React.ReactElement) => void;
  selectedKey: string;
  paddingLeft?: string;
}

export function MenuItems({
  items,
  handleClick,
  selectedKey,
  paddingLeft = '20px'
}: Readonly<MenuItemsProps>): React.ReactElement {

  const { mainTitle, title, keyPath, submenu } = items;
  const isSelected = selectedKey === keyPath;
  const hasSubmenu = submenu && submenu.length > 0;
  const isSubmenuSelected = hasSubmenu 
    ? submenu?.some((subItem) => selectedKey.startsWith(subItem.keyPath))
    : false;
  const [open, setOpen] = useState(isSubmenuSelected);
  const toggleDropdown = (): void => setOpen((prev) => !prev);
  const submenuIcon = open ? <ArrowDropDownIcon /> : <ArrowRightIcon />;
  const getIconToRender = (hasSubmenu = false, mainTitle = false): React.ReactElement | null => {
    if (hasSubmenu) return submenuIcon;
    if (mainTitle) return <ArrowRightIcon />;
    return null;
  };
  const iconToRender = getIconToRender(hasSubmenu, mainTitle);

    return (
    <List component="div" disablePadding>
      <ListItemButton
        sx={{ paddingLeft }}
        onClick={() => {
          toggleDropdown();
          handleClick(items.keyPath ?? '', items.element);
        }}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          {iconToRender}
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              sx: {
                fontWeight: (mainTitle || isSelected) ? 500 : 300,
                fontSize: isSelected ? '0.8425rem' : '0.8125rem',
                '&:hover, &:active': {
                  fontSize: '0.8425rem',
                  color: (theme: Theme) =>
                    theme.palette.common.goldYellow,
                },
                color: (theme: Theme) =>
                  isSelected
                    ? theme.palette.common.goldYellow
                    : theme.palette.common.black,
              },
            }}
          />
        </Box>
      </ListItemButton>

      {hasSubmenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {submenu?.map((submenu) => (
            <MenuItems
              key={submenu.title}
              items={submenu}
              handleClick={handleClick}
              selectedKey={selectedKey}
              paddingLeft={`calc(${paddingLeft} + 20px)`}
            />
          ))}
        </Collapse>
      )}
    </List>
  );
}

export default MenuItems;
