import {
    BlockedConfiguration,
    BlockedListPaginatedParams,
    BlockedListParams,
    BlockType,
    DaySchedule
} from "src/models/administration/blockConfigurationData.model";
import { APIPaginationParams, transformPaginationParams } from "../pagination";

export interface APIBlockedConfiguration {
    id?: string;
    applicationId?: string;
    siteRegistryId?: string;
    categoryId?: string;
    programApps?: string;
    url?: string;
    category?: string;
    blockType?: BlockType;
    configuredId?: string;
    lastUpdatedAt?: Date;
    isDeleted?: boolean;
    disabled?: boolean;
    alwaysBlocked?: boolean;
    schedule?: DaySchedule[];
}


export function readAPIConfigScheduleResponse(
    configurationSchedule: APIBlockedConfiguration
): BlockedConfiguration
{
    return configurationSchedule;
}

export interface APIBlockedListPaginatedParams
    extends BlockedListParams, APIPaginationParams { }

export function transformBlockedListPaginatedParams
    (params: BlockedListPaginatedParams): APIBlockedListPaginatedParams {
    const { blockType, configuredId, configurationLevel, searchValue, ...paginationParams } = params;

    return {
        blockType,
        configuredId,
        configurationLevel,
        searchValue,
        ...transformPaginationParams(paginationParams),
    };
}
export function readAPIBlockedConfigurationResponse(data: APIBlockedConfiguration): BlockedConfiguration {
    return data;
}
