import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Alert, Button, Grid, IconButton, Snackbar, SnackbarProps, Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCategoriesList,
    selectCategoriesListData,
    selectCategoriesListStatus,
    selectCreateCategoriesStatus,
    selectEditCategoryStatus,
    selectDeleteCategoryStatus,
    deleteCategory,
    resetCategoryStatuses,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { useTranslation } from 'react-i18next';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { CategoriesList } from 'src/models/administration/resourceData.models';
import { Actions } from 'src/models/roles.enum';
import { Modal } from 'src/components/modal/Modal';
import { ConfirmationSelection } from 'src/templates/confirmation-selection/ConfirmationSelection';
import { CategoriesListModal } from './CategoriesListModal';
import { addButtonStyle, SearchField } from '../applications-list-table/ApplicationsListTable';

export function CategoriesListTable(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const categoriesListData = useSelector(selectCategoriesListData);
    const categoriesListStatus = useSelector(selectCategoriesListStatus);
    const [searchValue, setSearchValue] = useState<string>('');
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [isCategoryToAdd, setIsCategoryToAdd] = useState<boolean>(false);
    const [categoryToEdit, setCategoryToEdit] = useState<CategoriesList>();
    const [categoryToDelete, setCategoryToDelete] = useState<CategoriesList>();
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [loadCategoryData, setLoadCategoryData] = useState<boolean>(false);
    const categoryCreateStatus = useSelector(selectCreateCategoriesStatus);
    const categoryEditStatus = useSelector(selectEditCategoryStatus);
    const categoryDeleteStatus = useSelector(selectDeleteCategoryStatus);

    function onPageChange(pageNumber: number): void {
        dispatch(getCategoriesList({
            pageNumber,
            entriesPerPage,
            searchValue
        }));
    }

    const onAddCategory = (): void => {
        setIsCategoryToAdd(true);
    }

    const dispatchCategoriesList = (): void => {
        dispatch(getCategoriesList({
            pageNumber: 1,
            entriesPerPage,
            searchValue,
        }));
    }

    useEffect(() => {
        dispatchCategoriesList();
    }, [dispatch, entriesPerPage, searchValue]);

    useEffect(() => {
        if(loadCategoryData) dispatchCategoriesList();
    }, [loadCategoryData]);

    useEffect(() => {
        let message = '';

        if (categoryCreateStatus === RequestStatus.Success) {
            message = t('settings.resource.category-created-successfully');
        }
        else if (categoryEditStatus === RequestStatus.Success) {
            message = t('settings.resource.category-edited-successfully');
        }
        else if (categoryDeleteStatus === RequestStatus.Success) {
            message = t('settings.resource.category-deleted-successfully');
            onSaveModal();
        }
        if (message) {
            setNotificationState({ open: true, message });
        }
    }, [categoryCreateStatus, categoryEditStatus, categoryDeleteStatus, setNotificationState, t, dispatch]);

    function onCloseNotification(): void {
        dispatch(resetCategoryStatuses());
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    function onChangeSearchValue(value: string): void {
        setSearchValue(value);
    }

    const onPerformAction = (data: CategoriesList, action: Actions): void => {
        if (action === Actions.EDIT) {
            setCategoryToEdit(data);
        } else {
            setCategoryToDelete(data);
        }
    }

    const onSaveModal = (): void => {
        onCloseModal();
        setLoadCategoryData(true);
        setTimeout(() => {
            setLoadCategoryData(false);
        }, 1000);
    }

    const onCloseModal = (): void => {
        setCategoryToEdit(undefined);
        setCategoryToDelete(undefined);
        setIsCategoryToAdd(false);
    }

    const getActionColumn = (data: CategoriesList): React.ReactNode => (
        <>
            <IconButton
                aria-label="edit"
                onClick={() => onPerformAction(data, Actions.EDIT)}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                aria-label="delete"
                onClick={() => onPerformAction(data, Actions.DELETE)}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );

    const columns: ColumnData<CategoriesList>[] = [
        {
            label: t('settings.resource.category-name'),
            size: 2,
            align: 'center' as const,
            value: (data: CategoriesList) => data.category,
        },
        {
            label: t('extension-form.action'),
            size: 2,
            align: 'center' as const,
            format: (data: CategoriesList) => getActionColumn(data),
        },
    ];

    const handleDeleteConfirmation = (value: boolean): void => {
        if (value === true && categoryToDelete) {
            dispatch(deleteCategory(categoryToDelete));
        }
        setCategoryToDelete(undefined);
    };

    return (
        <>
            <Box>
                <Stack direction="column" sx={{ textAlign: 'left' }}>
                    <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        padding="1em 0"
                    >
                        {/* Search Field */}
                        <SearchField onChange={onChangeSearchValue} />
                        <Button
                            variant="contained"
                            sx={addButtonStyle}
                            onClick={onAddCategory}
                        >
                            {t('settings.resource.add-categories').toUpperCase()}
                        </Button>
                    </Stack>
                </Stack>
                <Grid>
                    <DataTable<CategoriesList>
                        isLoading={categoriesListStatus === RequestStatus.InProgress}
                        columns={columns}
                        alignHeader={'center'}
                        onPageChange={onPageChange}
                        entriesPerPage={entriesPerPage}
                        onChangeEntriesPerPage={setEntriesPerPage}
                        {...categoriesListData}
                    />
                </Grid>
            </Box>

            <Modal
                title={t('settings.resource.category-management')}
                open={!!categoryToEdit || isCategoryToAdd}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <CategoriesListModal
                        action={isCategoryToAdd ? Actions.ADD : Actions.EDIT}
                        category={categoryToEdit}
                        onSave={onSaveModal}
                        onCancel={onCloseModal}
                    />
                </Box>
            </Modal>

            <Modal
                title={t('settings.resource.delete-category')}
                open={!!categoryToDelete}
                maxWidth="md"
                fullWidth
                showCloseButton={false}
            >
                <Box>
                    <ConfirmationSelection
                        text={t("settings.resource.category-delete-confirmation", {
                            category: categoryToDelete?.category?.toUpperCase()
                        })} 
                        onSubmit={handleDeleteConfirmation}
                    />
                </Box>
            </Modal>

            <Snackbar
                autoHideDuration={3000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}


