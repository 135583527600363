import { APIPaginationParams, transformPaginationParams } from '../pagination';
import { Platform } from 'src/models/platforms.enum';
import {
    ApplicationsList,
    ResourcePaginatedParams,
    ResourceParams,
    CategoriesList,
    UrlsList
} from 'src/models/administration/resourceData.models';

export interface APIResourcePaginatedParams
    extends ResourceParams, APIPaginationParams { }

export function transformResourcePaginatedParams
    (params: ResourcePaginatedParams): APIResourcePaginatedParams {
    const { searchValue, ...paginationParams } = params;

    return {
        searchValue,
        ...transformPaginationParams(paginationParams),
    };
}

// Application
export interface APIApplicationsListResponse {
    id: string;
    programName: string;
    platform: Platform[];
    packageName?: string;
    version?: string;
    createdAt: Date;
    updatedAt: Date;
}

export function readAPIApplicationsResponse(data: APIApplicationsListResponse): ApplicationsList {
    return data;
}

export function transformCreateApplicationsParams(
    params: ApplicationsList
): ApplicationsList {
    const { programName, platform } = params;

    return {
        programName,
        platform,
    };
}

// Resource
export interface APICategoriesListResponse {
    id?: string;
    category?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export function readAPICategoriesResponse(data: APICategoriesListResponse): CategoriesList {
    return data;
}
export function transformCreateCategoriesParams(
    params: CategoriesList
): CategoriesList {
    return { category: params?.category };
}

// Urls

export interface APIUrlsListResponse {
    id?: string;
    url?: string;
    userAgent?: string;
    title?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export function readAPIUrlsResponse(data: APIUrlsListResponse): UrlsList {
    return data;
}
export function transformCreateUrlsParams(
    params: UrlsList
): UrlsList {
    return { url: params?.url };
}
