import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Button, CircularProgress, Grid, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    createUrls,
    editUrl,
    selectCreateUrlsStatus,
    selectEditUrlStatus,
    selectUrlError,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { useTranslation } from 'react-i18next';
import { UrlsList } from 'src/models/administration/resourceData.models';
import { Actions } from 'src/models/roles.enum';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { SelectedField, textBoxStyle } from '../applications-list-table/ApplicationsListModal';

interface UrlsListModalProps {
    action: Actions;
    url?: UrlsList;
    onSave: () => void;
    onCancel?: () => void;
}

export function UrlsListModal({
    action,
    url,
    onSave,
    onCancel
}: Readonly<UrlsListModalProps>): React.ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAdd = action === Actions.ADD;
    const [isLoading, setIsLoading] = useState(false);
    const [urlInput, setUrlInput] = useState<UrlsList>();
    const [selectedName, setSelectedName] = useState<string[]>([]);
    const [selectedUrlToAdd, setSelectedUrlToAdd] = useState<UrlsList[]>([]);
    const [isDuplicateUrl, setIsDuplicateUrl] = useState<boolean>(false);
    const urlCreateStatus = useSelector(selectCreateUrlsStatus);
    const urlEditStatus = useSelector(selectEditUrlStatus);
    const urlError = useSelector(selectUrlError);

    useEffect(() => {
        if (action === Actions.EDIT && url) {
            setUrlInput(url);
        }
    }, [url])

    const handleAddClick = (): void => {
        const updatedUrls = [...selectedUrlToAdd, urlInput || {} as UrlsList];
        setSelectedUrlToAdd(updatedUrls);
        const updatedUrlsNames = updatedUrls
            .map(data => data?.url)
            .filter((name): name is string => name !== undefined);
        setSelectedName(updatedUrlsNames);
        setUrlInput({});
    };
    const handleAddWithDelay = (): void => {
        setIsLoading(true);
        setTimeout(() => {
            handleAddClick();
            setIsLoading(false);
        }, 500);
    };


    const onChangeAddUrl = (value: string): void => {
        setUrlInput(
            {
                ...urlInput,
                url: value,
            }
        )
        const isDuplicate = selectedUrlToAdd.some(
            (data) => data.url === value
        );
        setIsDuplicateUrl(isDuplicate);
    };

    const handleRemoveUrl = (UrlNames: string[]): void => {

        const updatedUrls = selectedUrlToAdd.filter((data) =>
            UrlNames.includes(data?.url ?? "")
        );
        setSelectedUrlToAdd(updatedUrls);
        setSelectedName(UrlNames);
    };

    const onSaveHandler = (): void => {
        if (isAdd && selectedUrlToAdd.length) {
            dispatch(createUrls({ sites: selectedUrlToAdd }));
        } else if (action === Actions.EDIT && urlInput) {
            dispatch(editUrl(urlInput));

        }
    };

    useEffect(() => {
        if (urlCreateStatus === RequestStatus.Success ||
            urlEditStatus === RequestStatus.Success) {
            onSave();
        }
    }, [urlCreateStatus, urlEditStatus])

    return (
        <Box>
            <Grid container sx={{ justifyContent: 'center' }} spacing={3} direction="column">
                <Grid item>
                    <Typography variant="subtitle1" fontWeight="bold">
                        {isAdd ? t('settings.resource.add-urls') : t('settings.resource.edit-url')}

                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <TextField
                                sx={textBoxStyle}
                                name="url"
                                value={urlInput?.url ?? ''}
                                onChange={(event) => onChangeAddUrl(event.target.value as string)}
                                autoComplete="off"
                                error={handleError(urlError, 'url')}
                                helperText={handleErrorText(urlError, 'url')}
                                fullWidth
                            />
                        </Grid>
                        {isAdd && (
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddWithDelay}
                                    disabled={isLoading || !(urlInput?.url) || isDuplicateUrl}
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : "ADD"}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {/* Urls Selected */}
                {isAdd && (
                    <Grid item>
                        <Typography variant="subtitle1" fontWeight="bold">
                            {t('settings.resource.urls-selected')}
                        </Typography>
                        <SelectedField
                            id="category"
                            selectedConfig={selectedName}
                            onRemoveConfig={handleRemoveUrl}
                        />
                    </Grid>
                )}

                {/* Action Buttons */}
                <Grid item>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onCancel}
                            >
                                {t('common.actions.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={isAdd && selectedUrlToAdd.length === 0}
                                variant="contained"
                                color="primary"
                                onClick={onSaveHandler}
                            >
                                {
                                    (
                                        urlCreateStatus === RequestStatus.InProgress ||
                                        urlEditStatus === RequestStatus.InProgress
                                    )
                                        ? <CircularProgress size={24} color="inherit" /> : t('common.actions.save')
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}