import { PaginatedContent } from 'src/models/paginatedResult.model';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import {
    ApplicationsList,
    ResourcePaginatedParams,
    CreateApplicationsParams,
    CategoriesList,
    CreateCategoriesParams,
    UrlsList,
    CreateUrlsParams
} from 'src/models/administration/resourceData.models';
import {
    readAPIApplicationsResponse,
    transformResourcePaginatedParams,
    transformCreateApplicationsParams,
    readAPICategoriesResponse,
    transformCreateCategoriesParams,
    readAPIUrlsResponse,
    transformCreateUrlsParams,
    APIApplicationsListResponse
} from '../data-transfer-objects/administration/resource';

// Applications 
export async function getApplicationsList(
    params: ResourcePaginatedParams
): Promise<PaginatedContent<ApplicationsList>> {
    const response = await instance.get('application', {
        params: transformResourcePaginatedParams(params),
    });
    return readAPIPaginatedResponse(response.data, readAPIApplicationsResponse);
}

export async function createApplications(
    params: CreateApplicationsParams
): Promise<ApplicationsList[]> {
    const response = await instance.post('application', {
        applications: params.applications.map((application) =>
            transformCreateApplicationsParams(application)
        ),
    });

    return response.data;
}

export async function editApplications(
    params: ApplicationsList
): Promise<void> {
    const response = await instance.patch(
        `application/${params.id}`,
        transformCreateApplicationsParams(params)
    );
    return response.data;
}

export async function deleteApplications(
    params: ApplicationsList
): Promise<void> {
    const response = await instance.delete(
        `application/${params.id}`);
    return response.data;
}

export async function getProgramsListOptions(): Promise<ApplicationsList[]> {
    const response = await instance.get('/application/no-pageable');
    return response.data.map((data: APIApplicationsListResponse) =>
        readAPIApplicationsResponse(data));
}

// Categories
export async function getCategoriesList(
    params: ResourcePaginatedParams
): Promise<PaginatedContent<CategoriesList>> {
    const response = await instance.get('category', {
        params: transformResourcePaginatedParams(params),
    });
    return readAPIPaginatedResponse(response.data, readAPICategoriesResponse);
}
export async function createCategories(
    params: CreateCategoriesParams
): Promise<CategoriesList[]> {
    const response = await instance.post('category', {
        categories: params.categories.map((category) =>
            transformCreateCategoriesParams(category)
        ),
    });
    return response.data;
}

export async function editCategory(
    params: CategoriesList
): Promise<void> {
    const response = await instance.patch(
        `category/${params.id}`,
        transformCreateCategoriesParams(params)
    );
    return response.data;
}

export async function deleteCategory(
    params: CategoriesList
): Promise<void> {
    const response = await instance.delete(
        `category/${params.id}`);
    return response.data;
}

// Urls
export async function getUrlsList(
    params: ResourcePaginatedParams
): Promise<PaginatedContent<UrlsList>> {
    const response = await instance.get('site', {
        params: transformResourcePaginatedParams(params),
    });
    return readAPIPaginatedResponse(response.data, readAPIUrlsResponse);
}
export async function createUrls(
    params: CreateUrlsParams
): Promise<UrlsList[]> {
    const response = await instance.post('site', {
        sites: params.sites.map((site) =>
            transformCreateUrlsParams(site)
        ),
    });
    return response.data;
}

export async function editUrl(
    params: UrlsList
): Promise<void> {
    const response = await instance.patch(
        `site/${params.id}`,
        transformCreateUrlsParams(params)
    );
    return response.data;
}

export async function deleteUrl(
    params: UrlsList
): Promise<void> {
    const response = await instance.delete(
        `site/${params.id}`);
    return response.data;
}