import { WeekDays } from "../blockManagement.enum";
import { PaginationParams } from "../paginatedResult.model";

export enum BlockType {
    APPS = 'application',
    CATEGORY = 'category',
    URL = 'url',
}

export enum ConfigurationLevel {
    DEFAULT = 'Default',
    AGENCY = 'Agency',
    CLIENT = 'Client',
    DEVICE = 'Device',
    OFFICER = 'Officer',
}

export interface ConfigScheduleParams {
    type: string;
}

export interface BlockedListParams {
    blockType: BlockType;
    configuredId: string;
    configurationLevel: ConfigurationLevel
    searchValue: string;
}

export interface BlockedListPaginatedParams extends PaginationParams, BlockedListParams { }

export interface Interval {
    start: string;
    end: string;
}

export interface DaySchedule {
    day: WeekDays;
    interval: Interval[];
}

export interface BlockedConfiguration {
    id?: string;
    applicationId?: string;
    siteRegistryId?: string;
    categoryId?: string;
    programApps?: string;
    url?: string;
    category?: string;
    blockType?: BlockType;
    configuredId?: string;
    lastUpdatedAt?: Date;
    isDeleted?: boolean;
    schedule?: DaySchedule[];
}

export interface BlockedConfigurationParams {
    actionId?: string;
    configurations: BlockedConfiguration[];
}

export function transformBlockedConfigurationParams(
    params: BlockedConfiguration
): BlockedConfiguration {
    return {
        ...params,
    };
}
