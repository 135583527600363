import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    selectDeviceConfigSettingsMenu,
    setDeviceConfigSettingsMenu,
} from 'src/state/administration/administrationSlice';
import { DeviceConfigurationSettings } from 'src/models/deviceConfigurationSettings.enum';
import { Stack, Typography } from '@mui/material';
import { MenuOption } from 'src/components/menu-option/MenuOption';

export function DeviceConfigSettingsSelect(): React.ReactElement {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const deviceConfigSettingMenu = useSelector(selectDeviceConfigSettingsMenu);

    function handleMenuChange(setting: DeviceConfigurationSettings): void {
        dispatch(setDeviceConfigSettingsMenu(setting));
    }
    function isMenuPathActive(setting: DeviceConfigurationSettings): boolean {
        return deviceConfigSettingMenu === setting;
    }

    const deviceConfigMenuItems = [
        {
            label: t('settings.configuration-services'),
            onClick: () => handleMenuChange(DeviceConfigurationSettings.ConfigurationServices),
            path: isMenuPathActive(DeviceConfigurationSettings.ConfigurationServices),
        },
        {
            label: t('settings.block-apps'),
            onClick: () => handleMenuChange(DeviceConfigurationSettings.BlockApps),
            path: isMenuPathActive(DeviceConfigurationSettings.BlockApps),
        },
        {
            label: t('settings.block-urls'),
            onClick: () => handleMenuChange(DeviceConfigurationSettings.BlockUrls),
            path: isMenuPathActive(DeviceConfigurationSettings.BlockUrls),
        },
        {
            label: t('settings.device-access-settings'),
            onClick: () => handleMenuChange(DeviceConfigurationSettings.DeviceAccessSettings),
            path: isMenuPathActive(DeviceConfigurationSettings.DeviceAccessSettings),
        },
    ];

    return (
        <Stack
            direction="column"
            justifyContent={'flex-start'}
            flexGrow={1}
        >
            <Typography
                variant="h5"
                sx={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    mb: 2,
                    textTransform: 'none',
                    borderColor: theme => theme.palette.common.goldYellow,
                    borderStyle: 'solid',
                    borderWidth: '0 0 0.20rem',
                    width: '180px'
                }}
            >
                Configuration Management
            </Typography>
            {deviceConfigMenuItems.map(({ label, onClick, path }) => (
                <MenuOption
                    key={label}
                    label={label}
                    onClick={onClick}
                    path={path}
                    isSettings={true}
                />
            ))}
        </Stack>
    );
}
